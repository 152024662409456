import {Component, HostListener, OnInit, ViewChild} from '@angular/core';
import {NavigationEnd, Router} from "@angular/router";
import {Attribute, Attributes} from "../../../shared/decoration/interface/decoration";
import {DecorationRestService} from "../../../shared/decoration/services/decoration-rest.service";

@Component({
  selector: 'app-shell',
  templateUrl: './shell.component.html',
  styleUrls: ['./shell.component.scss']
})
export class ShellComponent implements OnInit{

  isFixed = false;
  hideBanner = false;
  menuStyle?: Attribute

@ViewChild('menu') menu: any

  constructor(private router: Router, public decorationRestService: DecorationRestService) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.hideBanner = this.router.url === '/404' || this.router.url.includes('/info');
      }
    });
  }
  ngOnInit(): void {
    this.getDecoration()
  }
  getDecoration(){
    this.decorationRestService.attributes.subscribe(attributes => {
      this.menuStyle = attributes.find(({ attribute }) => attribute === Attributes.menu)
    });
  }
  @HostListener("document: scroll")
  scrollFunction() {
    this.isFixed = document.getElementById('menu')!.getBoundingClientRect().top === 0;
  }
}
