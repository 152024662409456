import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProductItemComponent } from './components/product-item.component';
import {
  NgbDropdownModule,
  NgbTooltipModule,
  NgbToastModule, NgbCarousel, NgbSlide,
} from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { ShareButtonsModule } from 'ngx-sharebuttons/buttons';
import { DimensionsModule } from '../directives/dimensions.module';
import { PipesModule } from "../pipes/pipes.module";
import { CountdownCounterModule } from '../countdown-counter/module/countdown-counter.module';

@NgModule({
    declarations: [ProductItemComponent],
    exports: [ProductItemComponent],
  imports: [
    CommonModule,
    NgbDropdownModule,
    NgbTooltipModule,
    ShareButtonsModule.withConfig({
      debug: true,
    }),
    TranslateModule,
    NgbToastModule,
    DimensionsModule,
    PipesModule,
    CountdownCounterModule,
    NgbCarousel,
    NgbSlide
  ]
})
export class ProductItemModule {}
