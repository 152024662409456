import { CanActivateChildFn, CanActivateFn, RedirectCommand, Router } from '@angular/router';
import { StorageLogicService } from '../services/storage-logic.service';
import { inject } from '@angular/core';
import { cityNames } from '../../const/city-names.const';
import { env, environment } from 'src/environments/environment.prod';

export const selectCityRouteGuard: CanActivateFn = (route, state) => {
  const storageService = inject(StorageLogicService);
  const router = inject(Router);
  const cityId = storageService.getData('city');

  const cityTranslit = cityNames[cityId] || cityNames[0];

  const isOfflineMode = window.matchMedia('(display-mode: standalone)').matches;

  const newUrl = router.createUrlTree([cityTranslit, isOfflineMode ? 'menu-pickup' : 'home'], { queryParamsHandling: '' });
  
  return new RedirectCommand(newUrl);
};


export const childGuard: CanActivateChildFn = (childRoute, state) => {
  const router = inject(Router);

  if (window.matchMedia('(display-mode: standalone)').matches) {
    return true;
  }
  
  const devPass = inject(StorageLogicService).getData('devPass');
  const isDevEnv = environment.url === env.developer;

  if (isDevEnv && !devPass) {
    return router.createUrlTree(['/', 'login'])
  }

  return true;
}