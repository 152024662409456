import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import {Prices, ProductData} from "../../pages/home/interfaces/home.interface";

@Injectable()
export class ProductDataAdapter {
  constructor(private fb: FormBuilder) {}

  adaptProductData(product: ProductData): FormGroup {
    const form = this.fb.group({
      ...product,
      marks: this.fb.array(product.marks),
      ingredients: this.fb.array(product.ingredients),
      weight: product.prices[0].id,
      weightText: product.prices[0].weight,
      price: product.prices[0].price,
      img: product.prices[0].img,
      regular_price: product.prices[0].regular_price,
      sale_percent: product.prices[0].sale_percent,
      discount_counter: this.findSaleDeadline(product.prices)
    });
    form.get('prices')?.patchValue(product?.prices as any);
    return form;
  }

    findSaleDeadline(prices: Prices[]): number {
      return prices.find(p => p.sale_left)?.sale_left || 0;
  }
}
