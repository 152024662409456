import { Directive, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[appDefaultImg]',
})
export class DefaultImgDirective {
  @Input ('appDefaultImg') fallbackImage: string|undefined;

  @HostListener ('error', ['$event'])
  handleImageError(event: Event): void {
    const image = event.target as HTMLImageElement;
    const fallbackImgAttr = image.getAttribute('data-fallbackimg');
    image.src = this.fallbackImage||fallbackImgAttr||'';
  }
}
