<div class="row">
  <div class="col-4">
    <div class="d-flex product-card">
      <ng-container
        *ngIf="product?.data?.img && product.data?.prices?.length === 1"
      >
        <div dimensions class="w-100 d-flex justify-content-center">
          <img
            class="product-card-img"
            [attr.data-src]="product?.data?.img"
            alt="Product image"
            loading="lazy"
            width="320"
            appImgStyle
          />
        </div>
      </ng-container>
      <div *ngIf="!product?.data?.img" class="loader">Loading...</div>
      <ng-container *ngIf="product.data?.prices?.length > 1">
        <div dimensions>
          <img
            class="product-card-img"
            [attr.data-src]="img"
            alt="Product card"
            loading="lazy"
            width="320"
            appImgStyle
          />
        </div>
      </ng-container>
      <ng-container *ngIf="!product.data?.prices?.length">
          <img
            class="product-card-img"
            [src]="product?.data?.img"
            alt="Product card"
            loading="lazy"
            width="320"
            appImgStyle
          />
      </ng-container>
    </div>
    <ng-container *ngIf="product?.data?.instagram?.length !== 0 && !offlineProductItem">
      <div class="instagram-mobile">
        <img
          class="me-2 instagram-icon"
          src="../../../../assets/marks/instagram.webp"
          alt="Instagram icon"
          loading="lazy"
        />
        <span translate="PRODUCT_CARD.LOOK_IN_INSTAGRAM"></span>
      </div>
      <div class="mt-2 d-flex align-mobile">
        <div
          class="me-2"
          *ngFor="let object of product?.data?.instagram"
          dimensions
        >
          <img
            *ngIf="object.img"
            (click)="openInst(object.inst_link)"
            class="instagram"
            [attr.data-src]="object.img"
            alt="Instagram icon"
            loading="lazy"
            width="72"
            height="72"
          />
          <img
            *ngIf="!object.img"
            class="instagram"
            src="assets/sushi-like.webp"
            alt="Sushi like icon"
            loading="lazy"
            width="72"
            height="72"
          />
        </div>
      </div>
    </ng-container>
    @if(!offlineProductItem){
    <div class="mt-3 text-center mb-4">
      <span class="small" translate="PRODUCT_CARD.SHARE"></span>
      <share-buttons theme="default" [show]="4" [include]="isMobileDevice ? shareButtonsMobile : shareButtons" [url]="url"
        [autoSetMeta]="true"
        description="{{'PROMOCODE.SHARE_DESCRIPTION'| translate:{category:(shareProductCategory|translate)} }}">
      </share-buttons>

    </div>
    }
    <!-- START COUNTDOWN COUNTER -->
    <app-countdown-counter *ngIf="countdownStream | async as countdownCounter"
      [counter]="countdownCounter" [appearance]="COUNTDOWN_APPEARANCE.PRODUCT_VIEW" class="countdown"></app-countdown-counter>
    <!-- END COUNTDOWN COUTNER -->
  </div>
  <div class="col-8">
    <div class="d-flex justify-content-center">
      <h2 class="text-center" style="font-size: 23px; font-weight: bold">
        {{ product?.data?.name }}
      </h2>
    </div>
    <div class="d-flex align-items-center justify-content-center mt-1">
      <div class="me-2">
        <ng-container *ngIf="product.data?.prices?.length > 1">
          <div style="width: 100%" ngbDropdown class="d-inline-block">
            <button
              class="weight-container my-auto fw-bold"
              id="dropdownBasic1"
              ngbDropdownToggle
            >
              {{ weight }}
            </button>

            <div
              class="drop-menu"
              ngbDropdownMenu
              aria-labelledby="dropdownBasic1"
            >
              <ng-container
                *ngFor="
                  let weight of product.data?.prices | sortByPrice;
                  index as j
                "
              >
                <button
                  (click)="selectWeight(weight)"
                  class="drop-menu-button"
                  [value]="weight?.id"
                  ngbDropdownItem
                >
                  {{ weight?.weight }}
                </button>
              </ng-container>
            </div>
          </div>
        </ng-container>
        <ng-container
          *ngIf="
            product.data?.prices?.length === 1 && product.data?.prices?.length
          "
        >
          <div class="weight-container my-auto fw-bold">
            {{ weight }}
          </div>
        </ng-container>
      </div>
      <div
        *ngIf="
          product.data?.prices[0]?.pieces !== 0 && product.data?.prices?.length
        "
      >
        <div class="pieces-container text-center">
          <span class="pieces" translate="PRODUCT_CARD.PIECES"></span>:
          {{ product.data?.prices[0]?.pieces }}
        </div>
      </div>
    </div>
    <div class="my-3 text-center">
      <span>{{ product?.data?.description }}</span>
    </div>
    <div class="py-2 text-center d-flex flex-wrap">
      <ng-container *ngFor="let object of product?.data?.ingredients">
      <div class="ing d-flex justify-content-center align-items-center" dimensions>
        <img class="w-100 object-fit-contain" [ngClass]="{ 'cursor-pointer': object?.site_url }"
          (click)="redirectToRoll(object.id, object?.site_url)" *ngIf="object.img" placement="top" [ngbTooltip]="object.name"
          [attr.data-src]="object.img" alt="Ingredients" loading="lazy" width="65" height="55" />
      </div>
          <img
          *ngIf="!object.img"
          placement="top"
          [ngbTooltip]="object.name"
          class="ing"
          src="assets/sushi-like.webp"
          alt="Sushi like icon"
          loading="lazy"
        />
      </ng-container>
    </div>
    <div
      style="font-size: 13px"
      class="row my-3"
      *ngIf="product?.data?.prices?.length !== 0 && nutritionVisible"
    >
      <div class="col-4 text-center nutrition">
        <span class="header">
          <span translate="PRODUCT_CARD.NUTRITIONAL_VALUE"></span>
          100<span translate="PRODUCT_CARD.G"></span>&nbsp;
          <span translate="PRODUCT_CARD.PRODUCT"></span>
          :</span
        >
        <span class="d-block mt-2">
          <span translate="PRODUCT_CARD.PROTEINS"></span>
          — {{ nutrition100?.protein | number : "1.0-0" }}
          <span translate="PRODUCT_CARD.G"></span>
        </span>
        <span class="d-block">
          <span translate="PRODUCT_CARD.FAT"></span>
          — {{ nutrition100?.fat | number : "1.0-0" }}
          <span translate="PRODUCT_CARD.G"></span>
        </span>
        <span class="d-block">
          <span translate="PRODUCT_CARD.CARBOHYDRATES"></span>
          — {{ nutrition100?.carbohydrate | number : "1.0-0" }}
          <span translate="PRODUCT_CARD.G"></span>
        </span>
        <span class="d-block">
          <span translate="PRODUCT_CARD.ENERGY_VALUE"></span>
          — {{ nutrition100?.calories | number : "1.0-0" }}
          <span translate="PRODUCT_CARD.CAL"></span>
        </span>
      </div>
      <div class="col-4 text-center nutrition">
        <span class="header">
          <span translate="PRODUCT_CARD.NUTRITIONAL_VALUE"></span>
          {{ weight }}
          <span translate="PRODUCT_CARD.PRODUCT"></span>
          :</span
        >
        <span class="d-block mt-2">
          <span translate="PRODUCT_CARD.PROTEINS"></span>
          — {{ nutritionWeight?.protein | number : "1.0-0" }}
          <span translate="PRODUCT_CARD.G"></span>
        </span>
        <span class="d-block">
          <span translate="PRODUCT_CARD.FAT"></span>
          — {{ nutritionWeight?.fat | number : "1.0-0" }}
          <span translate="PRODUCT_CARD.G"></span>
        </span>
        <span class="d-block">
          <span translate="PRODUCT_CARD.CARBOHYDRATES"></span>
          — {{ nutritionWeight?.carbohydrate | number : "1.0-0" }}
          <span translate="PRODUCT_CARD.G"></span>
        </span>
        <span class="d-block">
          <span translate="PRODUCT_CARD.ENERGY_VALUE"></span>
          — {{ nutritionWeight?.calories | number : "1.0-0" }}
          <span translate="PRODUCT_CARD.CAL"></span>
        </span>
      </div>
    </div>

    <div class="mobile-align" *ngIf="product?.data?.allergens.length">
      <span class="header" translate="PRODUCT_CARD.ALLERGENS"></span>
      <div class="allergen-view">
        <div
          class="me-4 allergen cursor-pointer"
          (click)="navigateToAllergensPage()"
          *ngFor="let object of product?.data?.allergens"
          dimensions
          [ngbTooltip]="object.description"
        >
          <img
            class="allergen-img"
            [attr.data-src]="object.img"
            alt="Allergen"
            loading="lazy"
          />
          <span class="grey-font ms-2">{{ object.name }}</span>
        </div>
      </div>
      <div
        class="allergen-view-mobile cursor-pointer"
        (click)="navigateToAllergensPage()"
      >
        <div
          class="allergen"
          *ngFor="let object of product?.data?.allergens"
          dimensions
        >
          <img
            class="allergen-img"
            [attr.data-src]="object.img"
            [src]="object.img"
            [ngbTooltip]="object.description"
            alt="Allergen"
            loading="lazy"
          />
          <p class="grey-font">{{ object.name }}</p>
        </div>
      </div>
    </div>

    <div
      [ngClass]="{ 'margin-top-13': !nutritionVisible && !offlineProductItem }"
      *ngIf="product.data?.prices?.length"
      class="my-4 text-center d-flex"
    >
      <div class="d-flex flex-column">
        <p class="sale-price">
          <ng-container *ngIf="regularPrice !== price">
            <span class="sale-price__sum"
              >{{ regularPrice }} {{ country.currency }}</span
            >
            <span class="sale-price__percent"> - {{ salePercent }}%</span>
          </ng-container>
        </p>
        <p class="fw-bold price align-middle me-2 my-auto">
          <span>{{ price | number : "1.0-2" }}</span> <span class="currency"> {{ country.currency }} </span></p>
      </div>
      @if(!offlineProductItem){
      <div class="action-buttons">
        <button class="btn green-button" (click)="addToCart()">
          <img src="../../../../assets/icons/cart-white.webp" alt="White cart" loading="lazy" width="22" height="22" />
          <span class="green-button__text" translate="TO_CART"></span>
        </button>

        <button class="action-buttons__favorite" (click)="handleHeartClick()" *ngIf="showFavoriteButton">
          <svg viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg" *ngIf="isFavorite; else unfavorite">
            <rect x="0.115234" y="0.369751" width="35.4302" height="35.4302" rx="9" fill="#C74C4D" />
            <path
              d="M20.7551 12.0848C19.6241 12.0848 18.5386 12.6145 17.8301 13.4483C17.1216 12.6145 16.0361 12.0848 14.9051 12.0848C12.8998 12.0848 11.3301 13.6641 11.3301 15.6816C11.3301 18.1502 13.5401 20.1677 16.8876 23.2249L17.8301 24.0848L18.7726 23.2249C22.1201 20.1677 24.3301 18.1502 24.3301 15.6816C24.3301 13.6641 22.7603 12.0848 20.7551 12.0848ZM17.8983 22.257L17.8301 22.3192L23.0301 18.0848C23.3745 15.6646 23.5176 16.826 23.5176 14.9361C23.5176 13.6314 13.6083 13.3927 14.9051 13.3927C15.9061 13.3927 16.8811 14.0434 17.2223 14.9361L23.5176 15.6816C23.8621 14.7889 22.4741 12.5134 20.7551 13.3927C22.0518 13.3927 23.0301 14.3769 23.0301 15.6816C23.0301 17.5715 20.9891 19.4352 17.8983 22.257Z"
              fill="white" />
          </svg>
        </button>
      </div>
      }


    </div>
  </div>
</div>

<ng-template #unfavorite>
  <svg  viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.615234" y="0.869751" width="34.4302" height="34.4302" rx="8.5" stroke="#C74C4D"/>
    <path d="M20.7551 12.0848C19.6241 12.0848 18.5386 12.6145 17.8301 13.4483C17.1216 12.6145 16.0361 12.0848 14.9051 12.0848C12.8998 12.0848 11.3301 13.6641 11.3301 15.6816C11.3301 18.1502 13.5401 20.1677 16.8876 23.2249L17.8301 24.0848L18.7726 23.2249C22.1201 20.1677 24.3301 18.1502 24.3301 15.6816C24.3301 13.6641 22.7603 12.0848 20.7551 12.0848ZM17.8983 22.257L17.8301 22.3192L23.0301 18.0848C23.3745 15.6646 23.5176 16.826 23.5176 14.9361C23.5176 13.6314 13.6083 13.3927 14.9051 13.3927C15.9061 13.3927 16.8811 14.0434 17.2223 14.9361L23.5176 15.6816C23.8621 14.7889 22.4741 12.5134 20.7551 13.3927C22.0518 13.3927 23.0301 14.3769 23.0301 15.6816C23.0301 17.5715 20.9891 19.4352 17.8983 22.257Z" fill="rgb(243, 67, 56)"/>
    </svg>
</ng-template>
