import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from "./environments/environment.prod";

import * as Sentry from "@sentry/angular";
import {sentryConfig} from "./environments/sentry-config";


if (environment.production) {
  enableProdMode();
}

export const sentryReplay = Sentry.replayIntegration();

Sentry.init({
  //Only report production exceptions
  dsn: sentryConfig.dsn,
  integrations: function (integrations) {
    // filter out Dedupe to submit all events
    const res = integrations.filter(function (integration) {
      return integration.name !== "Dedupe";
    });
    return [...res, sentryReplay];
  },
  sendClientReports:true,
  debug:sentryConfig.debug,
  attachStacktrace: true,
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: sentryConfig.propagationTarget,
  // Session Replay
  // replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysSessionSampleRate: 0.0,
  replaysOnErrorSampleRate: 0.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
