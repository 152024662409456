import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { Unsubscribe } from '../../../unsubscribe';
import { Country } from '../../../shell/components/header/interfaces/city-info.interface';
import { Prices } from '../../../pages/home/interfaces/home.interface';
import { CountryCityNotificationService } from '../../utils/services/country-city-notification.service';
import { CountdownService } from '../../utils/services/countdown.service';
import { Observable, takeUntil } from 'rxjs';
import { CountdownCounter } from '../../countdown-counter/interfaces/countdown-counter.interface';
import { CountdownAppearance } from '../../countdown-counter/enums/countdown-appearance.enum';
import {ChunkedIngredientsArray} from "../interface/product-item.interface";

@Component({
  selector: 'app-product-item',
  templateUrl: './product-item.component.html',
  styleUrls: ['./product-item.component.scss'],
  providers:[CountdownService]
})
export class ProductItemComponent extends Unsubscribe implements OnChanges {
  country: Country;
  countdownStream!: Observable<CountdownCounter>;
  chunkedIngredients: ChunkedIngredientsArray = [];
  @Input() productForm!: any;
  @Input() isProfileFavoriteTab = false;
  @Input() isPromoPage = false;
  @Input() isProfileBonusesTab = false;
  @Input() hideCartButton = false;
  @Input() isOfflineMenu = false;

  @Output() selectWeight = new EventEmitter();
  @Output() selectDrink = new EventEmitter();
  @Output() openProductModal = new EventEmitter();
  @Output() addToCart = new EventEmitter();
  @Output() unfavorite = new EventEmitter();

  protected readonly COUNTDOWN_APPEARANCE = CountdownAppearance;

  constructor(public countryCityService: CountryCityNotificationService, private countdownService:CountdownService) {
    super();
    this.country = this.countryCityService.selectedCountry;
  }


  ngOnChanges(changes: SimpleChanges): void {
    if (changes['productForm'].currentValue) {
      const value = this.productForm?.valid_until ?? (this.productForm?.discount_counter !== 0 ? this.productForm?.discount_counter : null);
      this.initCountdownStream(value);
      this.chunkedIngredients = this.chunkArray(this.productForm.ingredients, 3);
    }
  }

  onWeightSelected(price: Prices) {
    this.selectWeight.emit(price);
  }
  onDrinkSelected(price: Prices) {
    this.selectDrink.emit(price);
  }
  openModalEvent(productId: number): void {
    this.openProductModal.emit(productId);
  }
  addToCartEvent(productId: number): void {
    this.addToCart.emit(productId);
  }
  handleHeartClick(): void {
    this.unfavorite.emit(this.productForm);
  }

  private initCountdownStream(timeLeft: number) {
    this.countdownStream = this.countdownService
      .startCountdown(timeLeft)
      .pipe(takeUntil(this.$destroy));
  }

  chunkArray(arr: any[], chunkSize: number) {
    return arr.reduce((acc, _, i) =>
      i % chunkSize === 0 ? [...acc, arr.slice(i, i + chunkSize)] : acc, []
    );
  }
}
