<div class="card product-card text-center mt-4">
  <button type="button" class="favorite-button" *ngIf="isProfileFavoriteTab" (click)="handleHeartClick()">
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#E12829" viewBox="0 0 16 16" class="bi-x-lg">
      <path _ngcontent-pmf-c38="" fill-rule="evenodd"
        d="M13.854 2.146a.5.5 0 0 1 0 .708l-11 11a.5.5 0 0 1-.708-.708l11-11a.5.5 0 0 1 .708 0Z"></path>
      <path _ngcontent-pmf-c38="" fill-rule="evenodd"
        d="M2.146 2.146a.5.5 0 0 0 0 .708l11 11a.5.5 0 0 0 .708-.708l-11-11a.5.5 0 0 0-.708 0Z"></path>
    </svg>

  </button>
  <div class="white-container">
    <div class="d-flex marking-wrapper">
      <ng-container *ngFor="let mark of productForm?.marks">
        <div class="marking" dimensions>
          <img *ngIf="mark?.img" class="w-100 object-fit-contain" [attr.data-src]="mark?.img" alt="Marking image"
           [loading]="isOfflineMenu?'eager':'lazy'" />
        </div>
        <img *ngIf="!mark?.img" class="marking" src="assets/sushi-like.webp" alt="Sushi like image" [loading]="isOfflineMenu?'eager':'lazy'"/>
      </ng-container>
      <div *ngIf="!productForm?.bonus_allowed" class="marking justify-content-center align-items-center cursor-pointer"
        [ngbTooltip]="'BONUSES.WITHOUT_BONUSES' | translate">
        <img width="20" src="assets/marks/info.webp" alt="">
      </div>
      <ng-container *ngIf="productForm?.instagram">
        <img class="instagram ms-auto" src="../../../assets/marks/instagram.webp" alt="Instagram icon" loading="lazy" />
      </ng-container>
    </div>
    <ng-container *ngIf="productForm?.prices?.length === 1">
      <div class="weight-wrapper">
        <div class="weight">
          <div>
            {{ productForm?.prices[0]?.weight }}
          </div>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="productForm?.prices?.length >= 2 && productForm?.prices?.length < 3">
      <div class="weight-wrapper">
        <div class="weight">
          <label>
            <input name="weight" checked (change)="onWeightSelected(productForm?.prices[0])" type="radio"
              [value]="productForm?.prices[0]?.id" id="weight" class="my-auto" />
            <div class="checkmark">
              <span>{{ productForm?.prices[0]?.weight }}</span>
            </div>
          </label>
        </div>
        <div class="weight-second">
          <label>
            <input name="weight" (change)="onWeightSelected(productForm?.prices[1])" type="radio"
              [value]="productForm?.prices[1]?.id" id="weight-second" style="top: 40%" />
            <span class="checkmark">{{ productForm?.prices[1]?.weight }}</span>
          </label>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="productForm?.prices?.length > 2">
      <div ngbDropdown class="weight-wrapper">
        <button class="weight none-border my-auto" id="dropdownBasic1" ngbDropdownToggle>
          {{ productForm.weightText }}
        </button>
        <div class="drop-menu" ngbDropdownMenu aria-labelledby="dropdownBasic1">
          <ng-container *ngFor="let weight of productForm?.prices | sortByPrice; index as j">
            <button (click)="onDrinkSelected(weight)" class="drop-menu-button" [value]="weight?.id" ngbDropdownItem>
              {{ weight?.weight }}
            </button>
          </ng-container>
        </div>
      </div>
    </ng-container>
    <div class="mx-auto" dimensions *ngIf="productForm?.img">
      <img (click)="openModalEvent(productForm?.id)" [attr.data-src]="productForm?.img"
        class="card-img-top d-block mx-auto product-img" alt="Product" [loading]="isOfflineMenu?'eager':'lazy'" appImgStyle />
    </div>
    <img *ngIf="!productForm?.img" style="cursor: pointer; width: 10rem; height: 10rem; object-fit: contain"
      (click)="openModalEvent(productForm?.id)" src="assets/sushi-like.webp" class="card-img-top d-block mx-auto"
      alt="Sushi like image" loading="lazy" appImgStyle />

    <!-- START COUNTDOWN COUNTER -->
    <app-countdown-counter *ngIf="countdownStream | async as countdownCounter" [ngClass]="{'countdown':true, 'countdown__gallery-sm':isProfileBonusesTab||isProfileFavoriteTab}"
      [counter]="countdownCounter" [appearance]="isProfileBonusesTab||isProfileFavoriteTab?COUNTDOWN_APPEARANCE.GALLERY_SM_VIEW:COUNTDOWN_APPEARANCE.GALLERY_VIEW"></app-countdown-counter>
    <!-- END COUNTDOWN COUNTER -->
  </div>
  <div class="card-body">
    <div class="name">
      <h2 class="product-name">{{ productForm?.name }}</h2>
    </div>
    <div class="carousel-height my-2">
      <ngb-carousel *ngIf="productForm?.ingredients?.length" [interval]="0" [wrap]="true" class="my-2">
        <ng-template ngbSlide *ngFor="let chunk of chunkedIngredients">
          <div class="py-2 d-flex justify-content-center align-items-center">
            <ng-container *ngFor="let ing of chunk">
              <div class="ing d-flex align-items-center mx-2">
                <img *ngIf="ing?.img" [ngbTooltip]="ing.name" container="body" class="w-100 object-fit-contain"
                     [src]="ing.img" alt="i" loading="lazy" />
                <img *ngIf="!ing?.img" [ngbTooltip]="ing.name" container="body" class="ing" src="assets/sushi-like.webp"
                     alt="s" loading="lazy" />
              </div>
            </ng-container>
          </div>
        </ng-template>
      </ngb-carousel>
    </div>


    <ng-container *ngIf="!isPromoPage">
      <p class="sale-price">
        <ng-container *ngIf="!isProfileBonusesTab; else fullSalePriceForBonusProduct">
          <ng-container *ngIf="productForm.regular_price !== productForm.price">
            <span class="sale-price__sum">{{ productForm.regular_price | number : "1.0-2" }}
              {{ country.currency }}</span>
            <span class="sale-price__percent">
              - {{ productForm.sale_percent }}%</span>
          </ng-container>
        </ng-container>
      </p>
      <p class="mt-1 price d-flex justify-content-center align-items-baseline">
        <ng-container *ngIf="!isProfileBonusesTab; else freePriceForBonusProduct">
          <span>{{ productForm.price | number : "1.0-2" }}</span> <span class="currency ms-1">{{ country.currency }}</span>
        </ng-container>
      </p>
      <div class="row button-group justify-content-center" *ngIf="!isProfileBonusesTab">
        <button class="btn green-button product-button col-auto" (click)="addToCartEvent(productForm.weight)"
          translate="TO_CART">
          <img src="../../../assets/icons/cart-white.webp" alt="Cart white icon" loading="lazy" width="22"
            height="22" />
        </button>
      </div>
    </ng-container>
  </div>
</div>

<ng-template #freePriceForBonusProduct>
  <span translate="ORDERING.FREE"></span>
</ng-template>

<ng-template #fullSalePriceForBonusProduct>
  <span class="sale-price__sum">{{ productForm.price | number : "1.0-2" }}
    {{ country.currency }}</span>
  <span class="sale-price__percent">- 100%</span>
</ng-template>
