<div class="container my-4 display-none-mobile header-block">
  <img class="women" [src]="headerBackgroundImage?.value.img ? headerBackgroundImage?.value.img : 'assets/woman.png'" alt="Woman image" loading="lazy">
  <div class="row">
    <div class="col-3 my-auto">
      <img class="logo" [src]="logoStyle?.value ? logoStyle?.value : themeLogicService.isDarkTheme ? 'assets/darklogo.webp' : 'assets/logo.webp'"  alt="Logo image" [routerLink]="['/']" loading="lazy">
    </div>
    <div class="col-8 my-auto">
      <div class="row">
        <div *ngIf="!countryCityService.cityInfo">
          <div class="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
        </div>
        <ng-container *ngIf="countryCityService.cityInfo">
        <div class="col-12 mb-3">
          <span class="fw-bold" [ngStyle]="deliveryInfoStyle?.value?.title" translate="HEADER.FREE"></span>
          <span>&nbsp;</span>
          <span [ngStyle]="deliveryInfoStyle?.value?.subTitle" translate="HEADER.DELIVERY"></span>
          <span [ngStyle]="deliveryInfoStyle?.value?.subTitle"> {{countryCityService.cityInfo.min_price}}{{countryCityService.cityInfo.country.currency}}</span>
        </div>
        <div class="col-1 my-auto">
          <img class="mt-1" [src]="deliveryInfoStyle?.value.icon ? deliveryInfoStyle?.value.icon : 'assets/icons/delivery-time.webp'" alt="Delivery time image" loading="lazy" width="32" height="32">
        </div>
        <div class="col-3 my-auto">
          <p style="font-size: 12px" [ngStyle]="deliveryInfoStyle?.value?.deliveryHours" class="m-0 text-color" translate="WORK_TIME"><span [ngStyle]="deliveryInfoStyle?.value?.deliveryHours">:</span></p>
          <p style="font-size: 12px" [ngStyle]="deliveryInfoStyle?.value?.deliveryHours" class="m-0 text-color"><span translate="FROM"></span> {{countryCityService.cityInfo.work_start}} <span [ngStyle]="deliveryInfoStyle?.value?.deliveryHours" translate="TO"></span>  {{countryCityService.cityInfo.work_end}}</p>
          <p style="font-size: 12px; width: 10rem; word-break: break-word" class="m-0 text-color">{{countryCityService.cityInfo.optional_message}}</p>
        </div>
        <div class="col-1 my-auto">
          <img class="mt-1" [src]="contactsStyle?.value.icon ? contactsStyle?.value.icon : 'assets/icons/phones.webp'" alt="Phones image" loading="lazy" width="32" height="32">
        </div>
        <div class="col-7 my-auto d-flex flex-md-column flex-lg-row flex-wrap gap-md-1 gap-lg-3">
          <div *ngFor="let object of countryCityService.cityInfo.operators" class="d-flex gap-2 align-items-center">
            <img [src]="object?.img" alt="Phone" width="22" height="22" loading="lazy">
            <a [href]="'tel:' + object?.phone" style="font-size: 12px" [ngStyle]="contactsStyle?.value?.phoneNumber"
              class="grey-font text-decoration-none grey-font-hover">{{object?.phone}}</a>
          </div>
          </div>
        <div class="col-12 mt-3">
          <span [ngStyle]="deliveryInfoStyle?.value?.slogan" class="fw-bold accent-text" translate="HEADER.MORE_THAN_JUST_SUSHI"></span>
        </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>

