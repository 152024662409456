<footer class="footer mt-5">
  <div class="container">
    <div class="row footer-mobile">
      <ng-container *ngIf="countryCityService.cityInfo !== undefined">
        <div class="col-3">
          <span class="header"  translate="INFORMATION.INFO_MENU"></span>
          <span *ngFor="let object of countryCityService.infoPages" class="cursor-pointer"
                routerLink="../info" [queryParams]="{'page': object.site_url}">{{object.name}}</span>
        </div>
        <div class="col-3">
          <span class="header" translate="WORK_TIME"></span>
          <div class="d-flex gap-1 footer-mobile__work-time">
            <span translate="FROM"></span>
            <span> {{countryCityService.cityInfo.work_start}}</span>
            <span translate="TO"></span>
            <span> {{countryCityService.cityInfo.work_end}}</span>
          </div>
          <span *ngIf="countryCityService.cityInfo.optional_message === ''" translate="FOOTER.NOT_PAUSE_AND_HOLIDAYS"></span>
          <span *ngIf="countryCityService.cityInfo.optional_message !== ''">{{countryCityService.cityInfo.optional_message}}</span>
        </div>
        <div class="col-3">
          <span class="header" translate="FOOTER.ADDRESS"></span>
          <ng-container *ngFor="let object of places?.city">
            <span>{{object.address}}</span>
          </ng-container>
        </div>
        <div class="col-3">
          <span class="header" translate="FOOTER.CONTACTS"></span>
          <ng-container *ngFor="let phone of countryCityService.cityInfo?.operators">
            <a [href]="'tel:' + phone.phone" style="font-size: 12px" class="me-3 text-decoration-none"><span class="mt-0">{{phone.phone}}</span></a>
          </ng-container>
          <span class="border-for-director-write  mt-4 px-2 cursor-pointer float-start" (click)="openModal()" translate="FOOTER.WRITE_TO_THE_DIRECTOR"></span>
        </div>
        <div class="my-2 col-2">
          <img class="payment-icons" src="../../../assets/mastercard.svg" alt="mastercard" loading="lazy" width="50" height="50">
          <img class="ml-2 payment-icons" src="../../../assets/visa.svg" alt="visa" loading="lazy" width="50" height="50">
        </div>
        <div class="margin-mobile col-7">
          <span translate="FOOTER.LEGAL_INFO"></span>
        </div>
          <div class="social-media col-2">
            <ng-container *ngFor="let social of socialMedia">
              <a href="{{ social.link }}" target="_blank" dimensions>
                <img class="social-media__img"
                     [attr.data-src]="social.img" [alt]="social.social + 'icon'"
                     loading="lazy">
              </a>
            </ng-container>
          </div>
      </ng-container>
    </div>
  </div>
</footer>

<div *ngIf="showModal" class="modal-background d-flex flex-column">
  <div class="modal-content">
    <form [formGroup]="form">
      <h3 class="font-size-20 text-light" translate="FOOTER.WRITE_TO_THE_DIRECTOR"></h3>
      <span class="close" (click)="closeModal()">&times;</span>
      <hr>
      <div class="flex-direction d-flex align-items-center justify-content-between">
        <div>
          <p class="padding-top  text-light" translate="FOOTER.HOW_TO_CONTACT_YOU"></p>
          <input type="text" class="input-phone mb-0" formControlName="name">
        </div>
        <div>
          <p class="padding-top text-light" translate="FOOTER.CONTACT_PHONE_NUMBER"></p>
          <input type="text" class="input-phone mb-0" placeholder="{{countryCityService.placeholder}}"  mask="{{countryCityService.mask}}" formControlName="phone" (keypress)="keyPressNumbers($event)" (focus)="onInputFocus()">
        </div>
      </div>
      <div class="mt-2 d-flex flex-column justify-content-center">
        <p class="padding-top text-light" translate="FOOTER.MESSAGE"></p>
        <textarea class="form-control" formControlName="message"></textarea>
      </div>
      <button class="btn btn-success mt-2 float-end border-btn" (click)="postCeoMessage()" [disabled]="form?.invalid ?? form?.get('phone')?.value?.length !== (countryCityService.selectedCountry.phone_code.length + phoneLength)" translate="FOOTER.SEND"></button>
    </form>
  </div>
</div>

<div *ngIf="showThankYouModal" class="modal-background d-flex flex-column">
  <div class="modal-content-access modal-content">
    <span class="close" (click)="closeThankYouModal()">&times;</span>
    <div class="main-content d-flex align-items-center justify-content-center">
      <p class="m-0 color-span text-light fw-bold" translate="FOOTER.THANK_YOU_YOUR_REQUEST_HAS_BEEN_SENT"></p>
    </div>
  </div>
</div>
