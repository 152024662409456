import { Directive, ElementRef, Renderer2 } from '@angular/core';

@Directive({
  selector: '[appImgStyle]'
})
export class ImgStyleDirective {
  constructor(private el: ElementRef, private renderer: Renderer2) {
    this.setStyle();
  }

  private setStyle() {
    this.renderer.setStyle(this.el.nativeElement, 'background-color', 'white');
    this.renderer.setStyle(this.el.nativeElement, 'border-radius', '5px');
  }
}
