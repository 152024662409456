import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterIng'
})
export class IngPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if(!value)return null;
    if(!args)return value;
    if(args === '')return value;

    return value.filter(function (item: any) {
      return item.value.ingredients.find((v:any) => v.id == Number(args))
    })
  }
}
