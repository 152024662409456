import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {AppApiUrls} from "../../../../app.api.urls";
import {BannersInterface} from "../interfaces/banners.interface";
import {StorageLogicService} from "../../../../shared/utils/services/storage-logic.service";

@Injectable()
export class BannersRestService {

  constructor(private http: HttpClient, public storageService: StorageLogicService) {
  }
  get(): Observable<BannersInterface> {
    return this.http.get<BannersInterface>(AppApiUrls.banners(), {
      params: {
        city_id: this.storageService.getData('city') || '',
        device: 'web'
      }
    })
  }

}
