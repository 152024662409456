import {Instagram, ProductData} from "../../home/interfaces/home.interface";

export const SET_ID = 73726678;

export interface SetConstructor {
  success: boolean;
  data: {
    cart_id: number;
    set: any[];
    available: ProductData[]
    img: string;
    old_price: number;
    price: number;
    weight: string;
    pieces: number;
    instagram: Instagram[];
    nutrition: Nutrition;
    nutrition100: Nutrition;
  }
}
export interface Set {
    cart_id: number;
    id: number;
    img: string;
    name: string;
    nutrition: Nutrition;
    nutrition100: Nutrition;
    old_price: number;
    part_of_set: number;
    pieces: number;
    price: number;
    quantity: number;
    raw_weight: number;
    thumbnail: string
    weight: string;

}
 export interface Nutrition {
   fat: number;
   protein: number;
   carbohydrate: number;
   calories: number;
 }
