import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostListener,
  inject,
  OnInit,
  TemplateRef,
  ViewChild
} from '@angular/core';
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {Unsubscribe} from "../../../../unsubscribe";
import {map, takeUntil, tap} from "rxjs";
import {TranslateService} from "@ngx-translate/core";
import {ActivatedRoute, Router} from "@angular/router";
import {Bonuses} from "../cart/interfaces/cart.interface";
import {CitiesData, CountriesData} from "../interfaces/navbar.interface";
import {NavbarRestService} from "../services/navbar-rest.service";
import {StorageLogicService} from "../../../../shared/utils/services/storage-logic.service";
import {CartLogicService} from "../cart/services/cart-logic.service";
import { CountryCityNotificationService } from 'src/app/shared/utils/services/country-city-notification.service';
import {DecorationRestService} from "../../../../shared/decoration/services/decoration-rest.service";
import {Attribute, Attributes} from "../../../../shared/decoration/interface/decoration";
import {ThemeLogicService} from "../../../../shared/theme/services/theme-logic.service";
import { ProfileDataservice } from 'src/app/pages/profile/services/profile-data.service';
import { DefaultImgDirective } from 'src/app/shared/directives/default-img.directive';
import { FormControl, Validators } from '@angular/forms';
import { ReportModalService } from 'src/app/shared/utils/services/report-modal.service';
import { cityNames } from 'src/app/shared/const/city-names.const';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
  providers: [DefaultImgDirective],
  changeDetection:ChangeDetectionStrategy.OnPush
})
export class NavbarComponent extends Unsubscribe implements OnInit {
  scroll = 0;
  logoUrl = ''
  logoStyle?: Attribute
  citySelectorStyles?: Attribute
  navigationStyle?: Attribute
  bonusStyle?: Attribute
  callbackStyle?: Attribute
  bonusWindowAnimation: string = 'show-bottom';
  bonusMarkerAnimation: string = 'hide-right';
  Authorization = this.storageService.getData('Authorization');
  showModal: boolean = false;
  phoneNumber: string = '';
  countryCode: string = '';
  phoneLength: number = 8;
  modalStatus: {
    shouldOpenCountries: boolean,
    shouldOpenCities: boolean,
    preservedCountry: CountriesData | null,
    preservedCities: CitiesData[] | null} =
  {
    shouldOpenCountries: this.countryCityService.countriesList.length > 1,
    shouldOpenCities: !!this.countryCityService.selectedCountry,
    preservedCountry: null,
    preservedCities: null
  };

  isAuth = this.profileData.isAuthorizedStream;

  phoneControl = new FormControl('', { validators: Validators.required, nonNullable: true });
  
  private activatedRoute = inject(ActivatedRoute);


  @HostListener("document: scroll")
  scrollFunction() {
    this.scroll = document.body.scrollTop;
    this.scroll = document.documentElement.scrollTop;
  }

  @ViewChild('cities', {static: true}) citiesModal!: TemplateRef<any>;

  defaultProfileImg = 'assets/avatar-default.png';

  profileImg = this.isAuth.pipe(
    map((isAuth) =>
      isAuth
        ? { url: this.profileData.Authorization?.url_img, isRounded: true }
        : { url: 'assets/icons/profile.webp', isRounded: false }
    )
  );

  cityName = this.countryCityService.currentCityName;

  constructor(
    private modalService: NgbModal,
    public cartLogicService: CartLogicService,
    public translate: TranslateService,
    public router: Router,
    private navbarService: NavbarRestService,
    public storageService: StorageLogicService,
    public decorationRestService: DecorationRestService,
    protected countryCityService: CountryCityNotificationService,
    public themeLogicService: ThemeLogicService,
    public profileData: ProfileDataservice,
    private cdr: ChangeDetectorRef,
    public readonly reportModalService: ReportModalService

  ) {
    super();
    this.countryCode = this.countryCityService.selectedCountry.phone_code;
  }

  ngOnInit(): void {
    this.themeLogicService.themeChanged.pipe(takeUntil(this.$destroy)).subscribe(() => {
      this.logoUrl = this.themeLogicService.isDarkTheme ? 'assets/darklogo.webp' :'assets/logo.webp'
    });
    this.showCitySelectionModalIfCityNotChosen();
    this.getLogoUrl()
    this.getDecoration();
  }

  selectProfileNavigationPath() {
    const promo = this.storageService.getSessionData('promocode');
    if (promo) {
      this.router.navigate(['../p'], { queryParams: {c:promo}, relativeTo:this.activatedRoute },)
    } else {
      console.log('trying to navigate to profile');
      this.router.navigate(['../profile'], {relativeTo:this.activatedRoute})
    }
  }

  getLogoUrl() {
    this.decorationRestService.attributes.subscribe(attributes => {
      const logo =  attributes.find(({attribute})=> attribute === Attributes.logo)
      const defaultLogoUrl = this.themeLogicService.isDarkTheme ? 'assets/darklogo.webp' :'assets/logo.webp'
      this.logoUrl = logo?.value || defaultLogoUrl
    });
  }

  getDecoration() {
    this.decorationRestService.attributes.subscribe(attributes => {
      this.logoStyle =  attributes.find(({attribute})=> attribute === Attributes.logo)
      this.citySelectorStyles =  attributes.find(({attribute})=> attribute === Attributes.citySelector)
      this.navigationStyle =  attributes.find(({attribute})=> attribute === Attributes.navigation)
      this.bonusStyle =  attributes.find(({attribute})=> attribute === Attributes.bonus)
      this.callbackStyle =  attributes.find(({attribute})=> attribute === Attributes.callback)
    });
  }

  showCitySelectionModalIfCityNotChosen() {
    this.openChooseCityModal(this.citiesModal, false);
  }

   openChooseCityModal(content: TemplateRef<any>, forceOpen: boolean = true) {
    this.countryCityService.performCityCountryFetchRequest()
    .subscribe({
      complete: () => {
        if (forceOpen || this.storageService.getData('askToSelectCountry') === '1') {
          this.storageService.removeData('askToSelectCountry');
          this.modalService.open(content, {size: 'lg', backdrop: 'static'});
        }
      },
    });
  }

  returnBonuses(): Bonuses {
    return this.cartLogicService.cartProducts.bonus;
  }

  scrollTop(): void {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }

  cartToggle() {
    this.cartLogicService.viewCart = !this.cartLogicService.viewCart;
  }

  closeModal(closedFromHtmlTemplate: boolean): void {
    this.modalService.dismissAll();
    // closed by user (not by ts code), cities open (country was chosen), data preserved for country & cities =>
    // user chosen a country, but didn't chose city => revert country change (memento)
    setTimeout(() => {
      if (closedFromHtmlTemplate && this.modalStatus.shouldOpenCities &&
        this.modalStatus.preservedCountry !== null && this.modalStatus.preservedCities !== null) {
          this.countryCityService.selectedCountry = this.modalStatus.preservedCountry;
          this.countryCityService.citiesList = this.modalStatus.preservedCities;
          this.modalStatus.preservedCountry = null;
          this.modalStatus.preservedCities = [];
        }
    this.modalStatus.shouldOpenCities = true;
    }, 150);
  }

  applyCountry(country: CountriesData) {
    this.modalStatus.preservedCountry = this.countryCityService.selectedCountry;
    this.modalStatus.preservedCities = this.countryCityService.citiesList;
    this.countryCityService.selectedCountry = country;
    this.countryCityService.modifyLanguageBeforeRequests()
    this.countryCityService.sendCitiesListUpdateRequest()
    .subscribe({complete: () => this.modalStatus.shouldOpenCities = true});
  }

  applyCity(city: CitiesData) {
    this.modalStatus.preservedCountry = null;
    this.modalStatus.preservedCities = [];
    this.countryCityService.modifyLanguageBeforeRequests();
    this.storageService.setData('city', city.id.toString());
    this.storageService.setData('city_name', city.name);  //temporary!!!, refactor usage in other components
    this.storageService.setData('pixel_key', city.pixel_key);
    this.countryCityService.cityChanged$.next();
    this.countryCityService.applyCity().pipe(
      takeUntil(this.$destroy),
      tap(() => this.cartLogicService.reInitCart())
    ).subscribe({
      complete: () => {
        this.closeModal(false);
        this.countryCityService.changeLanguage(this.storageService.getData('lang'));
        this.handleNavigationAfterCitySelect(city.id);
      }
    });
  }

  private handleNavigationAfterCitySelect(cityId:number) {
    const newCity = cityNames[cityId] || cityNames[0];
        // ACCESING URL TREE segments & SKIPPING THE FIRST ONE SINCE IT REPRESENTS THE CURRENT ROOT ROUTE
    const tree = this.router.parseUrl(this.router.url);
    const relevantSegments = tree.root.children['primary'].segments.slice(1);
    const segmentPaths = relevantSegments.map(s => s.path);
    this.router.navigate(["/", newCity, ...segmentPaths], { queryParamsHandling:'merge' }).then(()=>window.location.reload());
  }

  redirectToCountries() {
    this.modalStatus.shouldOpenCities = false;
  }

  changeLang(language: string) {
    if (language !== this.countryCityService.lang$) {
      this.countryCityService.langChanedRefetchRequired = true;
      this.countryCityService.changeLanguage(language); //main storage
    }
  }

  openBonusWindow(): void {
    this.bonusMarkerAnimation = 'hide-right';
    this.bonusWindowAnimation = 'show-bottom';
  }

  closeBonusWindow(): void {
    this.bonusMarkerAnimation = 'show-right';
    this.bonusWindowAnimation = 'hide-bottom';
  }

  postCallBackRequest(): void {
    this.navbarService.postCallBackRequest(this.phoneNumber)
      .pipe(takeUntil(this.$destroy))
      .subscribe({
        complete: () => {
          this.closeCallBackModal();
        }
      });
  }

  openModal(): void {
    this.showModal = true;
    this.cdr.detectChanges();
  }

  closeCallBackModal(): void {
    this.phoneNumber = '';
    this.showModal = false;
    this.cdr.detectChanges();
  }

  onInputFocus(): void {
    if (this.phoneNumber.trim() === '') {
      if (!this.phoneNumber.startsWith('+38')) {
        this.phoneNumber = '+38' + this.phoneNumber;
      }
    }
  }


  navigateToFavoriteProducts(){
    this.router.navigate(['/profile'], {
      queryParams: {
        tab: "favoriteProducts"
      }
    })
  }
}
