import {ChangeDetectionStrategy, Component} from '@angular/core';
import {Router} from '@angular/router';

@Component({
  selector: 'app-error-handler',
  templateUrl: './error-handler.component.html',
  styleUrls: ['./error-handler.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class ErrorHandlerComponent {

  constructor(private router: Router) {
    const error = this.router.getCurrentNavigation()?.extras.state as { status: number; message: string; }
    if (!error) {
      this.router.navigate(['/home'])
    }
  }

  redirectToMainPage(): void {
    this.router.navigate(['/home']).then();
  }
}
