import { GoogleTagManagerService } from "angular-google-tag-manager";
import { NgCircleProgressModule } from "ng-circle-progress";

import { APP_INITIALIZER, ErrorHandler, isDevMode, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { NgbModule, NgbToastModule } from '@ng-bootstrap/ng-bootstrap';
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HTTP_INTERCEPTORS, HttpClient, provideHttpClient, withInterceptorsFromDi } from "@angular/common/http";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";

import { NavbarRestService } from './shell/components/navbar/services/navbar-rest.service';
import { CheckoutCartComponent } from "./pages/checkout-cart/components/checkout-cart.component";
import { ErrorHandlerComponent } from "./pages/error-handler/components/error-handler.component";
import { HomeModule } from "./pages/home/module/home.module";
import { InformationRestService } from './pages/information/services/information-rest.service';
import { OrderingRestService } from "./pages/ordering/services/ordering-rest.service";
import { ProfileDataservice } from './pages/profile/services/profile-data.service';
import { ReviewPageComponent } from "./pages/review-page/components/review-page.component";
import { GoogleAnalyticsLogicService } from "./shared/analytics/service/GoogleAnalytics-logic.service";
import { initApp } from './shared/utils/services/app-initialization.function';
import { CountryCityNotificationService } from './shared/utils/services/country-city-notification.service';
import { StorageLogicService } from './shared/utils/services/storage-logic.service';
import { detectLang } from "./shared/utils/detect-lang";
import { detectGTM } from "./shared/utils/GTM-detect";
import { ProductDataAdapter } from "./shared/utils/poduct-data-adapter";
import { HttpRequestInterceptor } from "./shell/interceptors/http-request.interceptor";
import { ShellModule } from "./shell/shell.module";
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FreeProductModalComponent } from './pages/profile/components/free-product-modal/free-product-modal.component';
import { PipesModule } from "./shared/pipes/pipes.module";
import { NgTemplateOutlet } from "@angular/common";
import { ToastsContainer } from "./toastes";
import {ReviewsRestService} from "./pages/review-page/services/reviews-rest.service";
import { CountdownCounterModule } from "./shared/countdown-counter/module/countdown-counter.module";
import { Router } from "@angular/router";
import * as Sentry from "@sentry/angular";
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from "ng-recaptcha-2";
import { recaptchaConfig } from "src/environments/recaptcha-config";
import { ServiceWorkerModule } from '@angular/service-worker';
import { MenuComponent } from "./shell/components/menu/components/menu.component";
import { defer, Observable } from "rxjs";

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http, '/assets/i18n/', '.json');
}

@NgModule({ declarations: [
        AppComponent,
        ReviewPageComponent,
        CheckoutCartComponent,
        ErrorHandlerComponent,
        FreeProductModalComponent,
    ],
    exports: [],
    bootstrap: [AppComponent],
    imports: [
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        NgbModule,
        ReactiveFormsModule,
        ShellModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient],
            },
            defaultLanguage: detectLang()
        }),
        NgCircleProgressModule.forRoot({
            backgroundPadding: 5,
            radius: 66,
            space: -12,
            outerStrokeGradient: true,
            outerStrokeWidth: 12,
            outerStrokeColor: "#12A95D",
            outerStrokeGradientStopColor: "#12A95D",
            outerStrokeLinecap: "inherit",
            innerStrokeColor: "#e7e8ea",
            innerStrokeWidth: 12,
            animationDuration: 1000,
            titleFontWeight: '700',
            showUnits: false,
            showBackground: false,
            responsive: true,
            startFromZero: false
        }),
        FormsModule,
        HomeModule,
        PipesModule,
        NgbToastModule,
        NgTemplateOutlet,
        ToastsContainer,
        CountdownCounterModule,
        RecaptchaV3Module,
        ServiceWorkerModule.register('ngsw-worker.js', {
            enabled: !isDevMode(),
            // Register on navigation to menu-pickup to avoid caching redundant reqs
            registrationStrategy:  () => defer(() => {
            const isStandalone = window.matchMedia('(display-mode: standalone)').matches

            if (isStandalone) {
            return new Observable(observer => {
                observer.next();
                observer.complete();
            });
            }
        // Return an empty Observable if not standalone
            return new Observable();
        })
        })
    ], providers: [
        GoogleAnalyticsLogicService,
        OrderingRestService,
        ReviewsRestService,
        {
            provide: APP_INITIALIZER,
            useFactory: initApp,
            deps: [StorageLogicService, CountryCityNotificationService, NavbarRestService, InformationRestService, ProfileDataservice],
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpRequestInterceptor,
            multi: true
        },
        { provide: "googleTagManagerId", useValue: detectGTM() },
        GoogleTagManagerService,
        ProductDataAdapter,
        {
            provide: ErrorHandler,
            useValue: Sentry.createErrorHandler({
                showDialog: false,
            }),
        }, {
            provide: Sentry.TraceService,
            deps: [Router],
        },
        {
            provide: APP_INITIALIZER,
            useFactory: () => () => { },
            deps: [Sentry.TraceService],
            multi: true,
        },
        {
            provide: RECAPTCHA_V3_SITE_KEY,
            useValue: recaptchaConfig.site_key,
        },
        provideHttpClient(withInterceptorsFromDi()),
    ]
})
    
export class AppModule { }
