import {
  AfterViewInit,
  Component,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { map, switchMap, takeUntil } from 'rxjs';
import { ActivatedRoute, Data, NavigationEnd, Router, RouterEvent } from '@angular/router';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import {
  Countries,
  FilterType,
  Prices,
  ProductData,
} from '../interfaces/home.interface';
import {
  FilterByIngInterface,
  FilterByMarksInterface,
} from '../interfaces/filterByIng.interface';
import { Meta, Title } from '@angular/platform-browser';
import { CookieService } from 'ngx-cookie-service';
import { Country } from '../../../shell/components/header/interfaces/city-info.interface';
import { Langs } from '../../../shell/components/navbar/interfaces/navbar.interface';
import { Unsubscribe } from '../../../unsubscribe';
import { StorageLogicService } from '../../../shared/utils/services/storage-logic.service';
import { ProductDataAdapter } from '../../../shared/utils/poduct-data-adapter';
import {
  common,
  drinks,
  home,
  noodles, philadelphia,
  rice,
  rollChef,
  rolls,
  sets,
  snacks,
  sushi,
} from '../../../shared/utils/meta-tags';
import { GoogleAnalyticsLogicService } from '../../../shared/analytics/service/GoogleAnalytics-logic.service';
import { CartLogicService } from '../../../shell/components/navbar/cart/services/cart-logic.service';
import { HomeRestService } from '../services/home-rest.service';
import { CountryCityNotificationService } from 'src/app/shared/utils/services/country-city-notification.service';
import { filter, tap } from 'rxjs/operators';
import { MenuRestService } from "../../../shell/components/menu/services/menu-rest.service";
import { NavigationPath } from 'src/app/shared/product-card/components/product-card.component';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent
  extends Unsubscribe
  implements OnInit, AfterViewInit
{
  products: ProductData[] = [];
  initialProducts: ProductData[] = [];
  sortDirection = ['', FilterType.cheap, FilterType.expensive, FilterType.novelty ]
  filterVisible = false;
  tost = false;
  productId = 0;
  productSearch = '';
  selectedFilter = '';
  filter = '';
  filterIng = '';
  valueFilterIng = '';
  valueFilterMark = '';
  country: Country;
  filterByIng: FilterByIngInterface = {};
  filterByMark: FilterByMarksInterface = {};

  shouldSkipHomeNavigation = false;

  hideFilterIng = false;
  hideFilterMark = false;

  appModalLangGoogle = Langs.en;
  appModalLangApple = Langs.en;
  appModalLangScreen = Langs.ua;

  // @ts-ignore
  form: FormGroup;

  @ViewChild('cardProduct') cardProduct: any;
  @ViewChild('alcoholWarning') alcoholWarning: any;
  @ViewChild('downloadApp', { static: true }) downloadApp:
    | TemplateRef<any>
    | undefined;

  constructor(
    private GAService: GoogleAnalyticsLogicService,
    private modalService: NgbModal,
    private cartLogicService: CartLogicService,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private router: Router,
    private meta: Meta,
    private title: Title,
    private cookieService: CookieService,
    public storageService: StorageLogicService,
    private productDataAdapter: ProductDataAdapter,
    private homeRestService: HomeRestService,
    private countryCityService: CountryCityNotificationService,
    private menuRestService: MenuRestService,
  ) {
    super();
    this.country = this.storageService.getData('country');
    this.setMetaTags();
  }

  ngOnInit(): void {
    if (this.storageService.getData('city_name')) {
      this.detectAppModalLang();
      this.openModalWithApp();
    }
    this.firstDataLoad();
    this.reactToLanguageChange();
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd && !this.shouldSkipHomeNavigation),
        tap(e => {
          const event = e as RouterEvent;
          const patternHideIngFilter = /(drinks|additionally)/; 
          this.hideFilterIng = patternHideIngFilter.test(event.url);
          this.hideFilterMark = event.url.includes('additionally');
        }),
        takeUntil(this.$destroy)
      )
      .subscribe(() => {
        this.showFilters();
      });
    this.onSortDirection();
  }

  onSortDirection(){
    this.menuRestService.sort$.subscribe((res)=>{
      this.selectedFilter = this.sortDirection[res]
      this.sortProductsByPrice(this.selectedFilter)
    })
  }

  showFilters() {
    if (this.router.url !== '/home') {
      this.filterVisible = true;
    } else {
      this.filterVisible = false;
    }
  }

  sortProductsByPrice(selectedFilter: string): void {
    this.selectedFilter = selectedFilter;
    const products = [...this.products];
    switch (selectedFilter) {
      case FilterType.cheap:
        this.products = products.sort(
          (a, b) => a.prices[0].price - b.prices[0].price
        );
        break;
      case FilterType.expensive:
        this.products = products.sort(
          (a, b) => b.prices[0].price - a.prices[0].price
        );
        break;
      case FilterType.novelty:
        this.products = products.sort(
          (a, b) => b.prices[0].id - a.prices[0].id
        );
        break;
      case FilterType.random:
        this.selectedFilter = '';
        this.products = this.initialProducts;
        break;
    }
    this.form.setControl(
      'weight',
      this.fb.array(
        this.products.map((item: ProductData) =>
          this.generateFormGroupElement(item)
        )
      )
    );
  }

  detectAppModalLang(): void {
    const lang = this.storageService.getData('lang');
    const country = this.storageService.getData('country').name;

    if (country === Countries.ua) {
      this.appModalLangScreen = Langs.ua;
    } else {
      this.appModalLangScreen = Langs.es;
    }

    switch (lang) {
      case Langs.es:
        this.appModalLangGoogle = Langs.es;
        this.appModalLangApple = Langs.es;
        break;
      case Langs.en:
        this.appModalLangGoogle = Langs.en;
        this.appModalLangApple = Langs.en;
        break;
      case Langs.ua:
        this.appModalLangGoogle = Langs.ua;
        this.appModalLangApple = Langs.en;
        break;
      case Langs.ru:
        this.appModalLangGoogle = Langs.ru;
        this.appModalLangApple = Langs.ru;
        break;
    }
  }

  warning(): void {
    this.modalService.open(this.alcoholWarning);
  }

  warningSubmit(answer: string): void {
    if (answer === 'yes') {
      this.modalService.dismissAll();
      this.router.navigate(['/drinks']).then();
    } else {
      this.modalService.dismissAll();
      this.router.navigate(['/home']).then();
    }
  }

  private firstDataLoad(): void {
    this.route.data
      .pipe(map((data: Data) => data['products'].data))
      .subscribe((prodData: ProductData[] | undefined) => {
        this.filterReset();
        this.productsRerender(prodData);
      });
  }

  private reactToLanguageChange() {
    this.countryCityService.languageChanged$
      .pipe(
        takeUntil(this.$destroy),
        switchMap(() => {
          if (this.route?.firstChild?.params) {
            return this.route.firstChild.params.pipe(
              switchMap((params) => {
                return this.homeRestService.getProducts(params['id']);
              })
            );
          } else {
            return this.homeRestService.getProducts();
          }
        }),
        map((prodResponse) => prodResponse?.data)
      )
      .subscribe((prodData) => this.productsRerender(prodData));
  }

  private productsRerender(prodData: ProductData[] | undefined) {
    if (!prodData || !prodData.length) {
      this.router.navigate(['/home']);
      return;
    }
    this.initialProducts = [...prodData];
    this.products = prodData;
    this.setMetaTags();
    this.form = this.fb.group({
      weight: this.fb.array(
        this.products.map((item: ProductData) =>
          this.generateFormGroupElement(item)
        )
      ),
    });
    this.sortProductsByPrice(this.selectedFilter)
  }

  generateFormGroupElement(item: ProductData): FormGroup {
    return this.productDataAdapter.adaptProductData(item);
  }

  get formArrayElement(): FormArray | null {
    return this.form ? this.form.get('weight') as FormArray : null;
  }

  selectDrink(prices: Prices, i: number) {
    const { id, weight, price, img, regular_price, sale_percent } = prices;
    const weightItem = this.form.value.weight[i];

    weightItem.weight = id;
    weightItem.weightText = weight;
    weightItem.price = price;
    weightItem.regular_price = regular_price;
    weightItem.sale_percent = sale_percent;
    weightItem.img = img;
  }

  selectWeight(prices: Prices, i: number) {
    this.form.value.weight[i].price = prices.price;
    this.form.value.weight[i].weight = prices.id;
    this.form.value.weight[i].regular_price = prices.regular_price;
    this.form.value.weight[i].sale_percent = prices.sale_percent;
  }

  filterReset(): void {
    this.productSearch = '';
    this.filter = '';
    this.filterIng = '';
    this.valueFilterMark = '';
    this.valueFilterIng = '';
  }

  addToCart(id: number, i: number): void {
    const product = this.products[i].prices.find(
      (item: Prices) => item.id === id
    );
    if (product) {
      this.GAService.eventEmitter('add_to_cart', {
        items: [
          {
            item_name: this.products[i].name,
            item_id: product.id,
            price: product.price,
            quantity: 1,
          },
        ],
      });
    }

    this.cartLogicService.addProduct(id);
  }

  openModal(productId: number): void {
    const product = this.products.find(
      (item: ProductData) => item.id === productId
    );

    if (product) {
      this.GAService.eventEmitter('view_item', {
        items: [
          {
            item_name: product.name,
            item_id: product.id,
            price: product.prices[0].price,
            quantity: 1,
          },
        ],
      });
    }

    this.router.navigate([], {
      queryParams: {
        productId: productId,
      },
    });
  }

  closeModal(): void {
    this.storageService.removeData('product_modal_id');
    !this.shouldSkipHomeNavigation && this.router.navigate([]).then(() => this.modalService.dismissAll());
  }

  subscribeOnQueryParamsChange(): void {
    this.route.queryParams.subscribe({
      next: (value: any) => {
        this.productId = value?.productId;
        if (value?.productId) {
          this.modalService
            .open(this.cardProduct, {
              size: 'xl',
              windowClass: 'test',
              modalDialogClass: 'test',
            })
            .result.finally(() => {
              this.closeModal();
            });
        }
      },
    });
  }

  ngAfterViewInit(): void {
    this.subscribeOnQueryParamsChange();
    this.showFilters();
  }

  close(): void {
    this.modalService.dismissAll();
  }

  openApp(url: string): void {
    this.modalService.dismissAll();
    window.open(url, '_blank');
  }

  openModalWithApp(): void {
    if (window.innerWidth <= 768 && !this.cookieService.get('MOBILE_MODAL')) {
      this.cookieService.set('MOBILE_MODAL', 'true');
      this.modalService.open(this.downloadApp);
    }
  }

  getLastSegmentFromUrl(): string {
    const url = window.location.href;
    const segments = url.split('/');
    return segments.pop() || '';
  }

  setMetaTags(): void {
    const lastSegment = this.getLastSegmentFromUrl();

    common.forEach((tag) => this.meta.updateTag(tag));

    switch (lastSegment) {
      case 'rolls':
        this.title.setTitle(
          'Смачні роли на будь-який смак - Замовляйте в 3303.ua!'
        );
        rolls.forEach((tag) => this.meta.updateTag(tag));
        break;

      case 'sushi':
        this.title.setTitle(
          'Суші 3303 | Робіть онлайн замовлення японської кухні'
        );
        sushi.forEach((tag) => this.meta.updateTag(tag));
        break;

      case 'sets':
        this.title.setTitle(
          'Смачні суші сети та японські страви на сайті 3303.ua'
        );
        sets.forEach((tag) => this.meta.updateTag(tag));
        break;

      case 'snacks':
        this.title.setTitle(
          'Вишукані закуски | 3303.ua - доставка японської кухні'
        );
        snacks.forEach((tag) => this.meta.updateTag(tag));
        break;

      case 'rice':
        this.title.setTitle(
          'Вибір найсмачніших рисових страв від Суші 3303.ua'
        );
        rice.forEach((tag) => this.meta.updateTag(tag));
        break;

      case 'philadelphia':
        this.title.setTitle('Роли Філадельфія – Класика Японської Кухні | 3303.ua');
        philadelphia.forEach((tag) => this.meta.updateTag(tag));
        break;

      case 'noodles':
        this.title.setTitle(
          'Оберіть свої улюблені страви з локшини на 3303.ua!'
        );
        noodles.forEach((tag) => this.meta.updateTag(tag));
        break;

      case 'drinks':
        this.title.setTitle('Різноманітний вибір освіжаючих напоїв | 3303.ua');
        drinks.forEach((tag) => this.meta.updateTag(tag));
        break;

      case 'roll-chef':
        this.title.setTitle(
          'Роли від шеф-кухаря: смаковиті комбінації на 3303.ua'
        );
        rollChef.forEach((tag) => this.meta.updateTag(tag));
        break;
      case 'additionally':
        this.title.setTitle(
          'Додаткові товари до вашого замовленная на 3303.ua'
        );
        rollChef.forEach((tag) => this.meta.updateTag(tag));
        break;
      default:
        this.title.setTitle(
          'Суші 3303 | Доставка японської кухні | Швидка доставка'
        );
        home.forEach((tag) => this.meta.updateTag(tag));
        break;
    }
  }

  navigateToInfoPage(e: NavigationPath) {
    //skip navigation back home
    this.shouldSkipHomeNavigation = true;
    const { url, params } = e;
    this.modalService.dismissAll();
    this.router.navigate(url, { queryParams: params });
  }
}
