import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {ErrorHandlerComponent} from "./pages/error-handler/components/error-handler.component";
import {ReviewPageComponent} from "./pages/review-page/components/review-page.component";
import {CheckoutCartComponent} from "./pages/checkout-cart/components/checkout-cart.component";
import { childGuard, selectCityRouteGuard } from './shared/utils/guards/select-city-route.guard';
import { devWebsite } from './shell/auth/developer-site-auth/dev-website.guard';
import { DeveloperAuthComponent } from './shell/auth/developer-site-auth/developer-auth/developer-auth.component';
import { env, environment } from 'src/environments/environment.prod';
import { oldRouteGuard } from './shared/utils/guards/old-route.guard';
import { MenuPickupResolver, MenuPickupCategoriesResolver, MenuPickupMarksResolver, MenuPickupIngredientsResolver } from './pages/menu-pickup/resolvers/menu-pickup.resolver';

const oldRoutePaths = ['home', 'home/:id', 'p', 'info', 'ordering', 'profile', 'app-link', 'reviews', 'checkout-card'];

const oldRoutes: Routes = oldRoutePaths.map(path => ({ path, pathMatch: 'full', canActivate: [oldRouteGuard], children:[] }));

const routes: Routes = [
  {
    path: "",
    canActivate: [selectCityRouteGuard],
    pathMatch:'full'
  },
  {
    path: '404',
    loadChildren: () => import('./pages/not-found/modules/not-found.module').then(m => m.NotFoundModule)
  },
  {
    path: ":cityName",
    canActivate:[childGuard],
    children: [
      {
        path: 'home',
        loadChildren: () => import('./pages/home/module/home.module').then(m => m.HomeModule),

      },
      {
        path: 'ordering',
        loadChildren: () => import('./pages/ordering/module/ordering.module').then(m => m.OrderingModule)
      },
      {
        path: 'info',
        loadChildren: () => import('./pages/information/information-page.module').then(m => m.InformationPageModule)
      },
      {
        path: 'profile',
        loadChildren: () => import('./pages/profile/module/profile.module').then(m => m.ProfileModule)
      },
      {
        path: 'app-link',
        loadChildren: () => import('./link-page/link-page.module').then(m => m.LinkPageModule)
      },
      {
        path: 'p',
        loadChildren: () => import('./pages/activate-promocode/module/activate-promocode.module')
          .then((m) => m.ActivatePromocodeModule),
      },
      {path: 'reviews', component: ReviewPageComponent},
      { path: 'checkout-card', component: CheckoutCartComponent },
      {
        path: 'menu-pickup', loadComponent: () => import('./pages/menu-pickup/menu-pickup.component').then(c => c.MenuPickupComponent),
        resolve: {
          products: MenuPickupResolver,
          categories: MenuPickupCategoriesResolver,
          marks: MenuPickupMarksResolver,
          ingredients: MenuPickupIngredientsResolver
        }
      },
      { path: 'error', component: ErrorHandlerComponent },
      {
        path: '**',
        redirectTo: window.matchMedia('(display-mode: standalone)').matches? '/menu-pickup' : environment.url === env.developer? '/login':'/404'
      }
    ]
  },
];

const devRoutes: Routes = [
  {
    path: 'login', component: DeveloperAuthComponent},
  {
    path: '',
    canActivate: [devWebsite],
    pathMatch: 'full',
    children:[]
  },
  ...routes.slice(1)
]

const appliedRoutes: Routes = [...oldRoutes, ...(environment.url === env.developer ? devRoutes : routes)];

@NgModule({
  imports: [RouterModule.forRoot(appliedRoutes)],
  //  imports: [RouterModule.forRoot([...oldRoutes,...routes])],
  exports: [RouterModule]
})
export class AppRoutingModule {}
