<div class="d-flex justify-content-center align-items-center my-5" *ngIf="!categories && !categoriesInput">
  <div class="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
</div>

<div class="menu">
    @if(categoriesInput?.length){
    <div *ngFor="let object of categoriesInput" class="menu--item" dimensions (click)="emitCategory(object)">
      <div>
        @let isLinkActivated = object.id === currentActivatedCategory()?.id;
        <img *ngIf="object?.img" [ngClass]="[isLinkActivated?'link--current':'link--not-current']"
          [attr.data-src]="object?.img" alt="Categories image" loading="lazy" width="64" height="64">
      </div>
      <span class="menu--item__title" [ngStyle]="menuStyle?.value?.label"
        [ngClass]="[isLinkActivated?'name--current':'name--not-current']">{{object?.name}}</span>
    </div>
    }
    @else {
    <div *ngFor="let object of categories" class="menu--item" [routerLink]="['../','home', object.site_url]" dimensions
      (click)="onCategoryChange(object.sort)">
      @let isActiveLink = object.site_url && link.includes(object.site_url);
      <div>
        <img *ngIf="object?.img" [ngClass]="[isActiveLink? 'link--current':'link--not-current']"
          [attr.data-src]="object?.img" alt="Categories image" loading="lazy" width="64" height="64">
      </div>
      <span class="menu--item__title" [ngStyle]="menuStyle?.value?.label"
        [ngClass]="[isActiveLink? 'name--current':'name--not-current']">{{object?.name}}
      </span>
    </div>
    }
  

  <div *ngIf="needShowEcoPizza" class="menu--item" (click)=ecoPizzaNavigate()>
    <div>
      <img class="link--not-current" src="assets/icons/pizza.webp" alt="eco pizza logo" loading="lazy" width="64"
        height="64">
    </div>
    <span class="menu--item__title name--not-current" [ngStyle]="menuStyle?.value?.label">Eco Pizza</span>
  </div>

</div>
