import { HttpRequest, HttpParams } from '@angular/common/http';

export const requestPathConfig: { [key: string]: (req: HttpRequest<any>) => HttpRequest<any> } = {
    '/assets/i18n': (req: HttpRequest<any>) => {
        return req.clone({
            params: (req.params ? req.params : new HttpParams()).set(
                't',
                new Date().getTime().toString()
            )
        });
    },
};
