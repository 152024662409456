import { Injectable } from '@angular/core';
import { Duration } from 'luxon';
import { Observable, interval, map, takeWhile, tap } from 'rxjs';
import { CountdownCounter } from '../../countdown-counter/interfaces/countdown-counter.interface';

@Injectable()
export class CountdownService {

  /**
   * @param timeLeft seconds left till the end of the discount offer
   */
  startCountdown(timeLeft: number): Observable<CountdownCounter> {
    const timeLeftMillis = timeLeft * 1000;

    return interval(1000).pipe(
      map((val) => timeLeftMillis - val * 1000),
      takeWhile(diff => diff > 0),
      map(ms => this.formatTime(ms)),
    );

  }

  private formatTime(ms: number): CountdownCounter {
    const duration = Duration.fromMillis(ms).shiftTo('days', 'hours', 'minutes', 'seconds');
    const { days = 0, hours = 0, minutes = 0, seconds = 0 } = duration.toObject();

    return {
      days: Math.floor(days).toString().padStart(2, '0'),
      hours: Math.floor(hours).toString().padStart(2, '0'),
      minutes: Math.floor(minutes).toString().padStart(2, '0'),
      seconds: Math.floor(seconds).toString().padStart(2, '0')
    };
  }
}
