import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from "@angular/common/http";
import {Observable} from "rxjs";
import {AppApiUrls} from "../../../../../app.api.urls";
import {BonusesInterface, CartInterface} from "../interfaces/cart.interface";
import {SetConstructor} from "../../../../../pages/set-constructor/interfaces/set-constructor.interface";
import {StorageLogicService} from "../../../../../shared/utils/services/storage-logic.service";
import { CountryCityNotificationService } from 'src/app/shared/utils/services/country-city-notification.service';

@Injectable()
export class CartRestService {

  constructor(
    private http: HttpClient, 
    public storageService: StorageLogicService,
    private coutryCityService: CountryCityNotificationService) {}

  private getParams(cartId?: string, partOfSet?: number): {[key: string]: string | number} {
    let params: {city_id: number, part_of_set?: number, cart_id?: string } = {
      city_id: this.coutryCityService.cityInfo.id,
    };
    if (partOfSet) {
      params.part_of_set = partOfSet;
    }
    if (cartId && cartId !== '0') {
      params.cart_id = cartId
    }
    return params;
  }

  get(cartId: string): Observable<CartInterface> {
    return this.http.get<CartInterface>(AppApiUrls.cart(), {
      params: this.getParams(cartId)
    });
  }

  getSet(cartId: string): Observable<SetConstructor> {
    return this.http.get<SetConstructor>(AppApiUrls.setConstructor(), 
    {params: this.getParams(cartId, 1)});
  }

  addToCart(cartId: string, productId: number, partOfSet?: number): Observable<CartInterface> {
    return this.http.post<CartInterface>(AppApiUrls.cart(), {product_id: productId}, 
    {params: this.getParams(cartId, partOfSet)});
  }

  deleteFromCart(cartId: string, productId: number, partOfSet?: number): Observable<CartInterface> {
    return this.http.delete<CartInterface>(AppApiUrls.cart(), {
      body: {product_id: productId},
      params: this.getParams(cartId, partOfSet)
    });
  }

  deleteAllFromCart(cartId: string, id: number): Observable<CartInterface> {
    return this.http.delete<CartInterface>(AppApiUrls.cart(), {
      body: {
        product_id: id,
        delete_all: true
      },
      params: this.getParams(cartId)
    });
  }

  deleteSet(cartId: string): Observable<CartInterface> {
    return this.http.delete<CartInterface>(AppApiUrls.setConstructor(), {
      params: this.getParams(cartId, 1)
    });
  }

  deleteCart(cartId: string): Observable<CartInterface> {
    return this.http.delete<CartInterface>(AppApiUrls.cart(), {
     params: this.getParams(cartId)
    });
  }

  getBonusInfo(cartId: string, pickup?: number, time?: string): Observable<BonusesInterface> {
    let httpParams: HttpParams = new HttpParams()
    .set('city_id', this.coutryCityService.cityInfo.id)
    .set('lang', this.coutryCityService.lang$ || 'ua')
    .set('cart_id', cartId);
    if (time) {
      httpParams = httpParams.set('time', time);    
    } 
    if (pickup) {
      httpParams = httpParams.set('pickup', pickup);
    } 
    return this.http.get<BonusesInterface>(AppApiUrls.bonusInfo(), {
      params: httpParams
    });
  }
}
