import { Injectable, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ThemeLogicService implements OnDestroy {
  private darkThemeKey = 'darkTheme';
  public themeChanged: Subject<void> = new Subject<void>();

  private prefersDarkScheme: MediaQueryList;
  private mediaQueryListener?: (e: MediaQueryListEvent) => void;

  constructor() {
    this.prefersDarkScheme = window.matchMedia('(prefers-color-scheme: dark)');
    this.mediaQueryListener = this.handleMediaQueryChange.bind(this);
    this.detectPreferredTheme();
    this.prefersDarkScheme.addEventListener('change', this.mediaQueryListener);
  }

  ngOnDestroy(): void {
    if (this.mediaQueryListener) {
      this.prefersDarkScheme.removeEventListener(
        'change',
        this.mediaQueryListener
      );
    }
  }

  get isDarkTheme(): boolean {
    return JSON.parse(localStorage.getItem(this.darkThemeKey) || 'false');
  }

  toggleDarkTheme(): void {
    const isDarkTheme = !this.isDarkTheme;
    localStorage.setItem(this.darkThemeKey, JSON.stringify(isDarkTheme));
    this.setDarkTheme(isDarkTheme);
    this.themeChanged.next();
  }

  setDarkTheme(isDarkTheme: boolean): void {
    if (isDarkTheme) {
      document.body.classList.add('dark-theme');
    } else {
      document.body.classList.remove('dark-theme');
    }
  }

  private detectPreferredTheme() {
    const isDarkTheme = this.isDarkTheme;
    if (
      isDarkTheme ||
      (isDarkTheme === false && this.prefersDarkScheme.matches)
    ) {
      localStorage.setItem('darkTheme', String(true))
      this.setDarkTheme(true);
    } else {
      localStorage.setItem('darkTheme', String(false))
      this.setDarkTheme(false);
    }
  }

  private handleMediaQueryChange(e: MediaQueryListEvent) {
    const isDarkMode = e.matches;
    localStorage.setItem(this.darkThemeKey, JSON.stringify(isDarkMode));
    this.setDarkTheme(isDarkMode);
    this.themeChanged.next();
  }
}
