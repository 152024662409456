import {Injectable} from "@angular/core"
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpParams,
  HttpRequest
} from "@angular/common/http"
import {Observable, tap} from "rxjs"
import {requestPathConfig} from "./request-config"
import {NavigationExtras, Router} from "@angular/router"
import {StorageLogicService} from "../../shared/utils/services/storage-logic.service";
import { AppApiUrls } from "src/app/app.api.urls"

@Injectable()
export class HttpRequestInterceptor implements HttpInterceptor {
  constructor(public storageService: StorageLogicService, private router: Router) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let newReq

    const path = Object.keys(requestPathConfig).find(key =>
      req.url.includes(key)
    )

    if (path) {
      newReq = requestPathConfig[path](req)
    } else {
      newReq = this.defaultRequestModification(req)
    }

    if (req.headers.get('skipInterceptor')) {
      return next.handle(newReq);
    } else {
      return next.handle(newReq).pipe(
        tap({
          error: (err) => {
            this.errorHandler(err);
          },
        })
      );
    }
  }

  defaultRequestModification(req: HttpRequest<any>): HttpRequest<any> {
    return req.clone({
      params: (req.params ? req.params : new HttpParams())
        .set('lang', this.storageService.getData('lang') || '')
        .set('platform', 'site'),
      headers: req.headers.set(
        'Authorization',
        this.storageService.getData('Authorization')?.pub_id || ''
      )
    })
  }

  errorHandler(err: HttpErrorResponse): void {
    const { status, message, url } = err;

    if (window.location.pathname.includes('menu-pickup')) return;
      
    if (url?.includes('/app/cart') || url?.includes(AppApiUrls.devWeb())) return;

    if (url?.includes('promocode/use')) return;

    const navigationExtras: NavigationExtras = {
      state: {status, message},
    }

    switch (err.status) {
      case 404:
        this.router.navigate(['/404'])
        break
      default:
        this.router.navigate(['/error'], navigationExtras)
        break
    }
  }
}
