<div class="container">
    <div class="row">
        <div class="col-4 offset-4">
            <h2 class="mt-3">Site dev version</h2>
            <input placeholder="Input your developer password" type="text" [(ngModel)]="pass" name="pass" id="pass" class="my-3">
            <button (click)="devAuth.savePass(pass)">Go to dev version</button>
        </div>
    </div>
    <div class="row">
        <div class="col-4 offset-4">
            <h5 class="mt-3">Just want to order sushi? Our website is <a href="https://3303.ua" target="_blank">here</a></h5>   
        </div>  
    </div>
</div>
