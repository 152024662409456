import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterMark'
})
export class MarkPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if(!value)return null;
    if(!args)return value;
    if(args === '')return value;

    return value.filter(function (item: any) {
      return item.value.marks.find((v:any) => v.id == Number(args))
    })
  }

}
