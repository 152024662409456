import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, Subject, pluck, take, takeUntil, tap} from "rxjs";
import {CartData, CartInterface, Products } from '../interfaces/cart.interface';
import {StorageLogicService} from 'src/app/shared/utils/services/storage-logic.service';
import {CartRestService} from './cart-rest.service';
import {Unsubscribe} from 'src/app/unsubscribe';
import {SET_ID} from 'src/app/pages/set-constructor/interfaces/set-constructor.interface';
import { ProfileRestService } from 'src/app/pages/profile/services/profile-rest.service';
import {Router} from "@angular/router";



@Injectable()
export class CartLogicService extends Unsubscribe {
  salePercentChanged = new Subject<0 | 5>();
  salePercent: 0 | 5 = 0;
  cartLength = 0;
  cartTotal = 0;
  regularPrice = 0;
  maxBonus = 0;
  saleInCurrency = 0;
  freeProductTotal = 0;
  freeProductsData: Products[] = [];


  viewCart: boolean = false;
  cartProducts: CartData = {
    id: +this.storageService.getData('cart'), products: [], free_products: [],
    bonus: {bonus_value: 0, current_percent: 0, time_bonus_schedule: [], total_bonus_schedule: []}
  };

  private cartProductSubj = new BehaviorSubject<Products[]>([])

  constructor(
    private storageService: StorageLogicService,
    private router: Router,
    public cartRestService: CartRestService, public profileService: ProfileRestService) {
    super();
    this.reInitCart();
    this.salePercentChanged
    .pipe(takeUntil(this.$destroy)).subscribe(value => {
      this.salePercent = value;
      this.recalculateTotal();
      this.recalculateSale();
    });
  }

  setCartLength(): void {
    this.cartLength = this.cartProducts.products
    .filter((products) => products.quantity)
    .reduce((a: number, b: { quantity: number }) => a + b.quantity, 0);
  }

  private recalculateSale() {
    let expr = this.cartTotal * this.salePercent / 100;
    if (this.storageService.getData('cityInfo')?.country?.total_round === 1) {
      this.cartTotal = Math.ceil(this.cartTotal-= expr);
      this.saleInCurrency = Math.floor(expr);
    } else {
      this.saleInCurrency = Math.round(expr * 100) / 100;
      this.cartTotal -= this.saleInCurrency;
    }
  }

  get activeBonus() {
    return this.cartProducts.bonus.current_percent;
  }

  get cartProducts$() {
    return this.cartProductSubj.asObservable()
  }

  reInitCart() {
    if (!!this.cartProducts.id) {
      this.cartRestService.get(this.cartProducts.id.toString())
      .pipe(
        tap((cartData) => this.updateCart(cartData.data))).subscribe();
    } else {
      this.cartRestService.getBonusInfo('')
      .pipe(tap((data) => {
        this.cartProducts.bonus = data.data;
        if (this.cartProducts.bonus) {
          this.calculateMaxBonus();
          this.cartProducts.bonus.current_percent = this.calculateActiveIfNotSupplied();
        }
      })).subscribe();
    }
  }

  calcQuantityFreeProducts(): void {
    this.freeProductTotal = 0;
    this.cartProducts.products.forEach((item) => {
      this.cartProducts.free_products.forEach((item1, freeProdIndex) => {
        if (item1.paid_product && item.id === item1.paid_product.id) {
          this.freeProductTotal += item.price! * item.quantity;
          this.cartProducts.free_products[freeProdIndex].quantity += item.quantity;
        }
      })
    });
  }

  recalculateTotal() {
    const bodyOrder = this.cartProducts.products.map((item) => {
     if(!item.regular_price){
       item.regular_price = item.price
     }
      return {
        sum: item.price! * item.quantity,
        id: item.id,
        regularPrice: item.regular_price! * item.quantity
      };
    });
    let total = bodyOrder.reduce((a, b) => a + (b.sum || 0), 0);
    let regularPriceTotal = bodyOrder.reduce((a, b) => a + (b.regularPrice || 0), 0);
    if (this.storageService.getData('cityInfo')?.country?.total_round === 1) {
      this.cartTotal = Math.ceil(total);
    } else {
      this.cartTotal = Math.round(total * 100) / 100;
    }
    this.regularPrice = regularPriceTotal - this.cartTotal
  }

  updateCart(cartData: CartData) {
    if(this.router.url.includes('ordering') && !cartData.products.length){
      this.router.navigate(['/','../home']);
    }
    this.cartProducts = cartData;
    this.freeProductsData = this.cartProducts?.free_products?.map(item => {return {...item}}) ?? [];
    this.calcQuantityFreeProducts();
    this.storageService.setData('cart', this.cartProducts.id);
    this.recalculateTotal();
    this.setCartLength();
    this.calculateMaxBonus();
    this.recalculateSale();

    this.cartProductSubj.next(this.cartProducts.products)
  }

  setCartTotal(total: number) {
    this.cartTotal = total;
  }

  addProduct(productId: number, openCart = true) {
    this.handleCartUpdate(this.cartRestService.addToCart(this.cartProducts.id.toString(), productId), openCart);
  }

  addFromRecommended(productId: number) {
    return this.cartRestService.addToCart(this.cartProducts.id.toString(), productId)
    .pipe(pluck('data'), tap((cartData) => this.updateCart(cartData)));
  }

  addToSet(productId: number) {
    return this.cartRestService.addToCart(this.cartProducts.id.toString(), productId, 1);
  }

  deleteFromSet(productId: number) {
    return this.cartRestService.deleteFromCart(this.cartProducts.id.toString(), productId, 1);
  }

  deleteProduct(productId: number) {
    this.handleCartUpdate(this.cartRestService.deleteFromCart(this.cartProducts.id.toString(), productId), this.viewCart);
  }

  deleteAllOfSameProduct(productId: number) {
    if (productId === SET_ID) {
      this.handleCartUpdate(this.cartRestService.deleteSet(this.cartProducts.id.toString()), this.viewCart);
    }
    this.handleCartUpdate(this.cartRestService.deleteAllFromCart(this.cartProducts.id.toString(), productId), this.viewCart);
  }

  clearCart() {
    this.handleCartUpdate(this.cartRestService.deleteCart(this.cartProducts.id.toString()), false);
  }

  cartUpdate() {
    const cartId = this.cartProducts.id || this.storageService.getData('cart');
    this.handleCartUpdate(this.cartRestService.get(cartId.toString()), this.viewCart);
  }

  orderAgain(quicksaleId: number): void {
    this.handleCartUpdate(this.profileService.reorder(quicksaleId), true);
  }

  updateBonusInfo(pickup?: 1 | 0, time?: string) {
    return this.cartRestService.getBonusInfo(this.cartProducts.id.toString(), pickup, time)
    .pipe(tap((data) =>
    this.cartProducts.bonus = data.data));
  }

  resetCart() {
    this.storageService.setData('cart', '0');
    const bonus = this.cartProducts.bonus;
    this.cartTotal = 0;
    this.regularPrice = 0
    this.cartLength = 0;
    this.cartProducts = {
      id: +this.storageService.getData('cart'),
      free_products: [],
      products: [],
      bonus: {...bonus, bonus_value: 0, current_percent: 0}
    };
    this.freeProductsData = [];
    this.saleInCurrency = 0;
    this.salePercent = 0;
    this.freeProductTotal = 0;
    this.freeProductTotal = 0;
    if (this.cartProducts.bonus) {
      this.cartProducts.bonus.current_percent = this.calculateActiveIfNotSupplied();
      this.calculateMaxBonus();
    }
  }

  private calculateActiveIfNotSupplied() {
    if (this.cartProducts.bonus) {
      const timeBonus = this.cartProducts.bonus.time_bonus_schedule.find(item => item.active)?.percent || 0;
      const bonusByTotal = this.cartProducts.bonus.total_bonus_schedule
      .find(item => item.total_from <= this.cartTotal && this.cartTotal <= item.total_to)?.percent || 0;
      return timeBonus + bonusByTotal;
    }
    return 0;
  }

  calculateMaxBonus() {
    if (this.cartProducts.bonus)  {
      const timeBonus = this.cartProducts.bonus.time_bonus_schedule.find(item => item.active)?.percent || 0;
      const totalPercents = this.cartProducts.bonus.total_bonus_schedule.map(item => item.percent);
      const maxTotalPercent = totalPercents.length ? Math.max(...totalPercents) : 0;
      this.maxBonus = timeBonus + maxTotalPercent;
    } else {
      this.maxBonus = 0;
    }
  }

  private handleCartUpdate(obs: Observable<CartInterface>, openCart = true) {
    obs.pipe(
      take(1),
      pluck('data'),
      tap((cartData) => this.updateCart(cartData)))
    .subscribe({complete: () => this.viewCart = openCart});
  }
}
