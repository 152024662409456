import {Component, OnInit} from '@angular/core';
import {Unsubscribe} from "../../../../unsubscribe";
import {StorageLogicService} from "../../../../shared/utils/services/storage-logic.service";
import {CountryCityNotificationService} from 'src/app/shared/utils/services/country-city-notification.service';
import {DecorationRestService} from "../../../../shared/decoration/services/decoration-rest.service";
import {Attribute, Attributes} from "../../../../shared/decoration/interface/decoration";
import {ThemeLogicService} from "../../../../shared/theme/services/theme-logic.service";
import {takeUntil} from "rxjs";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent extends Unsubscribe implements OnInit {
  logoStyle?: Attribute
  deliveryInfoStyle?: Attribute
  contactsStyle?: Attribute
  headerBackgroundImage?: Attribute
  logoUrl = ''

  constructor(
    private storageService: StorageLogicService,
    public countryCityService: CountryCityNotificationService,
    public decorationRestService: DecorationRestService,
    public themeLogicService: ThemeLogicService
  ) {
    super();
  }

  ngOnInit(): void {
    this.themeLogicService.themeChanged.pipe(takeUntil(this.$destroy)).subscribe(() => {
        this.logoUrl = this.themeLogicService.isDarkTheme ? 'assets/darklogo.webp' :'assets/logo.webp'
    });
    this.getCityInfo();
    this.getLogoUrl()
    this.getDecoration()
  }

  getLogoUrl() {
    this.decorationRestService.attributes.pipe(takeUntil(this.$destroy)).subscribe(attributes => {
      const logo =  attributes.find(({attribute})=> attribute === Attributes.logo)
      const defaultLogoUrl = this.themeLogicService.isDarkTheme ? 'assets/darklogo.webp' :'assets/logo.webp'
      this.logoUrl = logo?.value || defaultLogoUrl
    });
  }

  getDecoration() {
    this.decorationRestService.attributes.subscribe(attributes => {
      this.logoStyle =  attributes.find(({attribute})=> attribute === Attributes.logo)
      this.deliveryInfoStyle =  attributes.find(({attribute})=> attribute === Attributes.deliveryInfo)
      this.contactsStyle =  attributes.find(({attribute})=> attribute === Attributes.contacts)
      this.headerBackgroundImage =  attributes.find(({attribute})=> attribute === Attributes.headerBackgroundImage)
    });
  }

  getCityInfo() {
    this.storageService.setData('minPrice', String(this.countryCityService.cityInfo.min_price));
  }
}
