import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {catchError, Observable, Subject} from "rxjs";
import {TokenClientResponse, UserInfo} from "../../pages/profile/interfaces/profile.interface";

@Injectable({
  providedIn: 'root'
})
export class GoogleAuthService {
  private client: any
  private accessToken: string = '';
  public userSubject = new Subject<UserInfo | null>()

  constructor(private http: HttpClient) {
    this.initGoogleAuth();
  }
   initGoogleAuth(): void {
    if (!window.location.href.includes('checkout-card') && navigator.onLine) {
      // @ts-ignore
    this.client = google.accounts.oauth2.initTokenClient({
        client_id: '574472089104-tbher5us71us3obvvan9g90ecsuqpmdm.apps.googleusercontent.com',
        scope: 'https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email',
        callback: (response: TokenClientResponse) => {
          this.accessToken = response.access_token;
          this.fetchUserInfo();
        }
      });        
    }
  }
   fetchUserInfo(): void {
    this.getUserInfo().subscribe(
      (userInfo: UserInfo) => {
        this.userSubject.next(userInfo);
      },
      (error) => {
        console.error('Failed to fetch user info', error);
      }
    );
  }

  getUserInfo(): Observable<UserInfo> {
    const url = `https://www.googleapis.com/oauth2/v1/userinfo?alt=json&access_token=${this.accessToken}`;
    return this.http.get<UserInfo>(url).pipe(
      catchError((error) => {
        console.error('Failed to get user info', error);
        return [];
      })
    );
  }

  getAuthCode(): void {
    this.client.requestAccessToken();
  }

  signOut(): void {
    localStorage.removeItem('Authorization')
    this.userSubject.next(null)
  }

}
