import {Component, inject, Inject, OnInit, Renderer2} from '@angular/core';
import {NavigationEnd, Router} from "@angular/router";
import {filter, takeUntil} from "rxjs";
import {GoogleTagManagerService} from "angular-google-tag-manager";
import {changeKeyAnalyticsInHead, setSiteIndex} from "./shared/utils/GTM-detect";
import {StorageLogicService} from "./shared/utils/services/storage-logic.service";
import {Attributes} from "./shared/decoration/interface/decoration";
import {DecorationRestService} from "./shared/decoration/services/decoration-rest.service";
import {DOCUMENT} from "@angular/common";
import {CanonicalService} from "./shared/utils/services/canonical.service";
import {Unsubscribe} from "./unsubscribe";
import { cityNames } from './shared/const/city-names.const';
import { SentryReplayService } from './shared/sentry-replay/sentry-replay.service';

declare var gtag: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent extends Unsubscribe implements OnInit {

  sentryReplayService = inject(SentryReplayService);

  constructor(@Inject(DOCUMENT) private document: Document, private router: Router, private gtmService: GoogleTagManagerService, public storageService: StorageLogicService, public decorationRestService: DecorationRestService, private canonicalService: CanonicalService,
  private readonly renderer: Renderer2) {
    super();
  }

  title = 'site3303';

  ngOnInit(): void {
    this.sentryReplayService.monitorReplayOnRoute();
    this.watchInstallEvent();

    this.appendGoogleMapsScript();

    const navEndEvent$ = this.router.events.pipe(
      filter(e => e instanceof NavigationEnd)
    );
    navEndEvent$.subscribe((e: any) => {
      const gtmTag = {
        event: 'page',
        data: e.urlAfterRedirects
      };
      this.gtmService.pushTag(gtmTag);
    });
    this.getCanonical();
    changeKeyAnalyticsInHead()
    setSiteIndex()

    const navEndEvent$1 = this.router.events.pipe(
      filter(e => e instanceof NavigationEnd)
    );
    navEndEvent$1.subscribe((e: any) => {
      gtag('config', this.storageService.getData('cityInfo')?.google_analytics_key ? this.storageService.getData('cityInfo').google_analytics_key : 'G-YM8HVNQD4X', {'page_path': e.urlAfterRedirects});
    });
    this.getDecoration();
  }

  getDecoration() {
    this.decorationRestService.attributes.subscribe(attributes => {
      const attributeMap = new Map<string, string>();
      attributes.forEach(({attribute, value}) => attributeMap.set(attribute, value));

      const primaryColor = attributeMap.get(Attributes.primaryColor) || '#35753A';
      const secondaryColor = attributeMap.get(Attributes.secondaryColor) || '#656E76';
      const backgroundUrl = attributeMap.get(Attributes.background);

      this.document.documentElement.style.setProperty('--primary', primaryColor);
      this.document.documentElement.style.setProperty('--secondary', secondaryColor);
      document.body.style.backgroundImage = `url('${backgroundUrl}')`;
    });
  }

  getCanonical() {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).pipe(takeUntil(this.$destroy)).subscribe(() => {
      this.canonicalService.setCanonicalURL();
    });
  }

  private appendGoogleMapsScript(): void {
    const apiKey = this.storageService.getData('cityInfo')?.google_maps_map_key ?? '';

    const scriptSrc = `https://maps.googleapis.com/maps/api/js?key=${apiKey}`;

    if (this.findExistingGoogleMapsScript()) {
      return;
    }

    let script: HTMLScriptElement = this.renderer.createElement('script');
    script.src = scriptSrc;
    script.async = true;

    this.renderer.appendChild(this.document.head, script);
  }

  private findExistingGoogleMapsScript(): HTMLScriptElement | undefined {
    const srcFragment = 'https://maps.googleapis.com/maps/api/js?key=';
    const googleMapsScript = Array.from(this.document.head.querySelectorAll('script'))
      .find(script => script.src.includes(srcFragment));
    
    return googleMapsScript;
  }

  private watchInstallEvent(): void {
    window.matchMedia('(display-mode: standalone)').addEventListener('change', (event) => {
      if (event.matches) {
        const currentCity = cityNames[this.storageService.getData('city')];
        this.router.navigate(['/', currentCity, 'menu-pickup']);
      } else {
        this.unregisterServiceWorkerInBrowser();
      }
    });
  }

  private unregisterServiceWorkerInBrowser(): void {
    navigator.serviceWorker.getRegistrations().then(registrations => {
      registrations.forEach(r => {
        if (r.active?.scriptURL.includes('ngsw-worker.js')) {
          r.unregister().then(success => { 
            if (success) {
              console.log('Unregistered Service Worker in Browser')
            }
          })
        }
      }) 
    })
  }
}
