import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, catchError, of } from 'rxjs';
import { Products } from '../interfaces/home.interface';
import {
  FilterByIngInterface,
  FilterByMarksInterface,
} from '../interfaces/filterByIng.interface';
import { AppApiUrls } from '../../../app.api.urls';
import { StorageLogicService } from '../../../shared/utils/services/storage-logic.service';
import { Router } from '@angular/router';

@Injectable()
export class HomeRestService {
  constructor(
    private http: HttpClient,
    public storageService: StorageLogicService,
    private router: Router
  ) {}

  getProducts(id?: string) {
    if (!id || id === 'set-constructor') {
      return this.get();
    }
    return this.getByCategory(id).pipe(
      catchError((item) => {
        this.router.navigate(['/']);
        return of(null);
      })
    );
  }

  get(): Observable<Products> {
    return this.http.get<Products>(AppApiUrls.product(), {
      params: {
        city_id: this.storageService.getData('city')!,
        lang: this.storageService.getData('lang')!,
      },
    });
  }
  getByCategory(url: string): Observable<Products> {
    return this.http.get<Products>(AppApiUrls.product(), {
      params: {
        city_id: this.storageService.getData('city')!,
        cat_url: url,
        lang: this.storageService.getData('lang')!,
      },
    });
  }
  getIngByFilter(): Observable<FilterByIngInterface> {
    return this.http.get<FilterByIngInterface>(AppApiUrls.ingFilter());
  }
  getMarksByFilter(): Observable<FilterByMarksInterface> {
    return this.http.get<FilterByMarksInterface>(AppApiUrls.ingMarks());
  }

  getOfflineMenu(): Observable<Products> {
    return this.http.get<Products>(AppApiUrls.product(), {
      params: {
        city_id: this.storageService.getData('city')!,
        lang: this.storageService.getData('lang')!,
        all: true
      },
    });
  }
}
