import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {AppApiUrls} from "../../../app.api.urls";
import {CityInfoInterface} from "../../../shell/components/header/interfaces/city-info.interface";
import {StorageLogicService} from "../../../shared/utils/services/storage-logic.service";
import {PreorderTimesInterface, RecomendedData} from "../interfaces/ordering.interface";

@Injectable()
export class OrderingRestService {

  constructor(private http: HttpClient, public storageService: StorageLogicService) { }

  sendOrder(body: any): Observable<any> {
    return this.http.post<any>(AppApiUrls.sendOrder(), body, {headers:{skipInterceptor:'true'}})
  }
  getInfo(): Observable<CityInfoInterface> {
    return this.http.get<CityInfoInterface>(AppApiUrls.cityInfo(), {
      params: {
        city_id: this.storageService.getData('city')!,
        lang: this.storageService.getData('lang')!
      }
    })
  }

  getPreorderTime(pickup: number): Observable<PreorderTimesInterface> {
    return this.http.get<PreorderTimesInterface>(AppApiUrls.preorderTime(), {
      params: {
        city_id: this.storageService.getData('city')!,
        pickup
      }
    })
  }

  getRecommendedProducts(): Observable<RecomendedData> {
    return this.http.get<RecomendedData>(AppApiUrls.recommended(), {
      params: {
        city_id: this.storageService.getData('city')!,
        cart_id: this.storageService.getData('cart')!,
      }
    })
  }
}
