import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {CeoMessageData, PlacesInterface, SocialMedia} from "../interfaces/footer.interface";
import {CityInfoInterface} from "../../header/interfaces/city-info.interface";
import {AppApiUrls} from "../../../../app.api.urls";
import {StorageLogicService} from "../../../../shared/utils/services/storage-logic.service";

@Injectable({
  providedIn: 'root'
})
export class FooterRestService {

  constructor(private http: HttpClient, public storageService: StorageLogicService) { }

  getInfo(): Observable<CityInfoInterface> {
    return this.http.get<CityInfoInterface>(AppApiUrls.cityInfo(), {
      params: {
        city_id: this.storageService.getData('city')!,
        lang: this.storageService.getData('lang')!
      }
    })
  }

  getPlaces(): Observable<PlacesInterface> {
    return this.http.get<PlacesInterface>(AppApiUrls.PlacesByCity(), {
      params: {
        city_id: this.storageService.getData('city')!,
        lang: this.storageService.getData('lang')!
      }
    })
  }

  getSocialMedia(): Observable<SocialMedia> {
    return this.http.get<SocialMedia>(AppApiUrls.socialMedia(), {
      params: {
        city_id: this.storageService.getData('city')!,
      }
    })
  }

  postCeoMessage(body: CeoMessageData): Observable<CeoMessageData> {
    return this.http.post<CeoMessageData>(AppApiUrls.ceoMessage(),body
      ,{
        params: {
          city_id: this.storageService.getData('city'),
        }
      })
  }
}
