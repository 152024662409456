import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {takeUntil} from "rxjs";
import {createHash} from "crypto";

import {CheckoutCartRestService} from "../services/checkout-cart-rest.service";
import {CheckoutCartInterface} from "../interfaces/checkout-cart.interface";
import {Unsubscribe} from "../../../unsubscribe";
import {StorageLogicService} from "../../../shared/utils/services/storage-logic.service";
import {CheckoutCartModule} from "../module/checkout-cart.module";
import {CartLogicService} from "../../../shell/components/navbar/cart/services/cart-logic.service";

@Component({
  selector: 'app-checkout-card',
  templateUrl: './checkout-cart.component.html',
  styleUrls: ['./checkout-cart.component.scss'],
  providers: [CheckoutCartModule]
})
export class CheckoutCartComponent extends Unsubscribe implements OnInit {


  cartId!: number
  data!: CheckoutCartInterface
  liqPayData!: string
  liqPaySignature!: string

  constructor(
    private route: ActivatedRoute,
    private checkoutCartService: CheckoutCartRestService,
    public storageService: StorageLogicService,
    private cartLogicService: CartLogicService
  ) {
    super()
  }

  ngOnInit(): void {
    this.getQueryParams()
  }

  getQueryParams(): void {
    this.route.queryParams
      .pipe(takeUntil(this.$destroy))
      .subscribe({
        next: value => {
          this.cartId = value['cart_id']
          this.get()
        }
      })
  }

  get(): void {
    this.checkoutCartService.get(this.cartId)
      .pipe(takeUntil(this.$destroy))
      .subscribe({
        next: value => {
          this.data = value
        }, complete: () => {
          this.cartLogicService.resetCart();
          this.liqPayOrdering(this.data.total, this.data.private_key, this.data.public_key)

          if (!this.storageService.getData('lang')) {
            this.storageService.setData('lang', this.data.lang)
          }
          if (!this.storageService.getData('city')) {
            this.storageService.setData('city', this.data.city_id.toString())
          }
        }
      })
  }

  liqPayOrdering(total: number, privateKey: string, publicKey: string): void {
    const decodePrivateKey = atob(privateKey).split('').reverse().join('')
    const decodePublicKey = atob(publicKey).split('').reverse().join('')

    const json_string = {
      "public_key": decodePublicKey,
      "result_url": 'https://' + window.location.host + '/ordering/order-success',
      "server_url": 'https://storage.3303.ua/api/app/order/liqpay_callback?id=' + this.cartId.toString(),
      "version": '3',
      "action": 'pay',
      "amount": total.toString(),
      "currency": 'UAH',
      "description": this.cartId.toString(),
      "order_id": this.cartId.toString()
    }
    const data = btoa(JSON.stringify(json_string))
    const sign_string = decodePrivateKey + data + decodePrivateKey
    const signature = createHash('sha1').update(sign_string).digest('base64');

    this.liqPayData = data
    this.liqPaySignature = signature
  }


}
