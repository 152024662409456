import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { catchError, of } from "rxjs";
import { AppApiUrls } from "src/app/app.api.urls";

@Injectable({providedIn: 'root'})
export class DevAuthService {
    constructor(private http: HttpClient, private router: Router) {}

    verifyPass(password: string) {
        return this.http.post<{success: boolean, message: string}>(AppApiUrls.devWeb(), {'password': password})
        .pipe(catchError(()=> of({ message:'', success:false })))
    }

    savePass(pass: string) {
        localStorage.setItem('devPass', JSON.stringify(pass));
        this.router.navigate(['/']);
    }
}