import {forkJoin, of, switchMap} from "rxjs";
import {CountryCityNotificationService} from "./country-city-notification.service";
import {StorageLogicService} from "./storage-logic.service";
import {detectLang} from "../detect-lang";
import {NavbarRestService} from "src/app/shell/components/navbar/services/navbar-rest.service";
import {InformationRestService} from "src/app/pages/information/services/information-rest.service";

export function initApp(
    storageService: StorageLogicService, countryCityService: CountryCityNotificationService, 
    navbarService: NavbarRestService, informationService: InformationRestService) {
    return () => {
    if (!storageService.getData('lang') || !storageService.getData('city')) {
        const city = "1";
        const pixelKey = "";
        const lang = detectLang();
        storageService.setData('lang', lang);
        storageService.setData('city', city);
        storageService.setData('pixel_key', pixelKey);
        storageService.setData('askToSelectCountry', '1'); //open modal
    }

    return forkJoin({
        cityInfo: navbarService.getInfo(),
        countries: navbarService.getCountries(),
        infoPages: informationService.getInfo()
    }).pipe(
        switchMap(({cityInfo, countries, infoPages}) => {
            if (cityInfo.success && countries.length && infoPages.success) {
                countryCityService.updateCitiesState(cityInfo.data);
                countryCityService.updateInfoPages(infoPages.data);
                return countryCityService.updateCountriesState(countries);
            }
            return of(null);
        })
    );
    }
}