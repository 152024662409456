import { inject } from "@angular/core";
import { ActivatedRouteSnapshot, ResolveFn } from "@angular/router";
import { HomeRestService } from "../../home/services/home-rest.service";
import { Allergens, ProductData } from "../../home/interfaces/home.interface";
import { CategoriesData } from "src/app/shell/components/menu/interfaces/menu.interface";
import { MenuRestService } from "src/app/shell/components/menu/services/menu-rest.service";
import { IngData, MarksData } from "../../home/interfaces/filterByIng.interface";
import { filter, map, tap } from "rxjs";

export const MenuPickupResolver:ResolveFn<ProductData[]> = (route: ActivatedRouteSnapshot) => {
    return inject(HomeRestService).getOfflineMenu().pipe(
        map(res => res.data || []),
        tap(async (products) => {
            try {
                const allergens = [...collectAllergens(products)].map(allergen => fetch(allergen.img, {mode:"no-cors"}));
                await Promise.allSettled(allergens);
            } catch (e) {
                console.log(e)
            }
        })
    );

    function collectAllergens(data:ProductData[]): Set<Allergens> {
        const uniqueAllergens = new Set<Allergens>();
        data.forEach(product => {
            product.allergens.forEach(allergen=>uniqueAllergens.add(allergen))
        })

        return uniqueAllergens;
    }
}

export const MenuPickupCategoriesResolver:ResolveFn<CategoriesData[]> = (route: ActivatedRouteSnapshot) => {
    return inject(MenuRestService).get().pipe(
        map(res => res.data)
    );
}

export const MenuPickupMarksResolver:ResolveFn<MarksData[]> = (route: ActivatedRouteSnapshot) => {
    return inject(HomeRestService).getMarksByFilter().pipe(
        map(res => res.data || []),
        filter(data => data.length > 0),
        tap(async(marks) => {
            const allMarkSrc = marks.map(m => fetch(m.img, {mode:'no-cors'}));
            try {
                await Promise.allSettled(allMarkSrc);
            } catch (e) {
                console.log('marks error')
            }
        })
    );
}

export const MenuPickupIngredientsResolver:ResolveFn<IngData[]> = (route: ActivatedRouteSnapshot) => {
    return inject(HomeRestService).getIngByFilter().pipe(
        map(res => res.data || []),
        filter(data => data.length > 0),
        tap(async(ingredients) => {
            const allIngredientsSrc = ingredients.map(ing => fetch(ing.img, {mode:'no-cors'}));
            try {
                await Promise.allSettled(allIngredientsSrc);
            } catch (e) {
                console.log('ingredients error')
            }
        })
    );
}