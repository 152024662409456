import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable, map} from "rxjs";
import {Cities, CitiesData, CountriesData, NavbarInterface} from "../interfaces/navbar.interface";
import {AppApiUrls} from "../../../../app.api.urls";
import {CityInfoInterface} from "../../header/interfaces/city-info.interface";
import {StorageLogicService} from "../../../../shared/utils/services/storage-logic.service";

@Injectable()
export class NavbarRestService {

  constructor(private http: HttpClient, public storageService: StorageLogicService) { }

  getCountries(): Observable<CountriesData[]> {
    return this.http.get<CountriesData[]>(AppApiUrls.countries(), {
      params: {
        lang: this.storageService.getData('lang')!,
      }
    }).pipe(
      map((countries) => {
       return countries.map(country => {
          return {...country, cities: 
            country.cities.map(city => {
            if (city.id === Cities.Sumy) {
              return {...city, pixel_key: '2596595467162636'};
            }
            return city;
          })}; 
        });
      })
    )
  }
  
  getCities(country_id: number): Observable<NavbarInterface<CitiesData>> {
    return this.http.get<NavbarInterface<CitiesData>>(AppApiUrls.cities(), {
      params: {
        lang: this.storageService.getData('lang')!,
        country_id
      }
    }).pipe(
      map(({success, data}) => {
        const cities = data.map(city => {
          if (city.id === Cities.Sumy) {
            return {...city, pixel_key: '2596595467162636'};
          } else {
            return {...city, pixel_key: ''};
          }
        });
        return {success, data: cities};
      })
    );
  }

  getInfo(): Observable<CityInfoInterface> {
    return this.http.get<CityInfoInterface>(AppApiUrls.cityInfo(), {
      params: {
        city_id: this.storageService.getData('city'),
        lang: this.storageService.getData('lang')
      }
    })
  }

  getBonusInfo(): Observable<any> {
    return this.http.get<any>(AppApiUrls.bonusInfo(), {
    params: {
      city_id: this.storageService.getData('city') || '5',
    }
    })
  }

  postCallBackRequest(phone: any): Observable<any> {
    return this.http.post<any>(AppApiUrls.callbackRequest(),phone
      ,{
        params: {
          city_id: this.storageService.getData('city') || '1',
          phone: phone
        }
      })
  }
}
