import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  NgbDropdownModule,
  NgbToastModule,
  NgbTooltipModule,
} from '@ng-bootstrap/ng-bootstrap';
import { ShareButtonsModule } from 'ngx-sharebuttons/buttons';
import { ShareIconsModule } from 'ngx-sharebuttons/icons';
import { TranslateModule } from '@ngx-translate/core';
import { ProductCardComponent } from './components/product-card.component';
import { ProductCardRestService } from './services/product-card-rest.service';
import { DimensionsModule } from '../directives/dimensions.module';
import { PipesModule } from '../pipes/pipes.module';
import { CountdownCounterModule } from "../countdown-counter/module/countdown-counter.module";

@NgModule({
  declarations: [ProductCardComponent],
  exports: [ProductCardComponent],
  providers: [ProductCardRestService],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgbTooltipModule,
    ShareButtonsModule.withConfig({
        debug: true,
    }),
    ShareIconsModule,
    TranslateModule,
    NgbToastModule,
    NgbDropdownModule,
    DimensionsModule,
    PipesModule,
    CountdownCounterModule
],
})
export class ProductCardModule {}
