import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { feedbackIntegration } from '@sentry/angular';

@Injectable({
  providedIn: 'root'
})
export class ReportModalService {

  constructor(private readonly translateService: TranslateService){}

  public async showDefaultReportDialog() {
    const feedback = feedbackIntegration({
  // Disable the injection of the default widget
      autoInject: false,
      showBranding: false,
      formTitle: "Повідомте нам про помилку",
      submitButtonLabel: "Відправити звіт",
      cancelButtonLabel: "Скасувати",
      confirmButtonLabel: "Підтвердити",
      addScreenshotButtonLabel: "Додати скріншот",
      removeScreenshotButtonLabel: "Видалити скріншот",
      nameLabel: "Ім'я",
      namePlaceholder: "Ваше ім'я",
      emailLabel: "E-mail",
      emailPlaceholder: "Ваш e-mail",
      isRequiredLabel: "(Обов'язкове поле)",
      messageLabel: "Опис",
      messagePlaceholder: "Розкажіть нам, що сталося",
      successMessageText: "Дякуємо за повідомлення!",
      
      themeLight: {
        background: "#fff",
        foreground: "#535B63",
        outline: "1px auto #656E76"
      },
      themeDark: {
        background: "#0f0f0f",
        foreground: "#B4B6B7",
        outline: "1px auto #ECEFF0"
      },
    });
    
      const form = await feedback.createForm();
      form.appendToDom();
      form.open();
  }
}
