<div id="carouselAutoplaying" class="carousel slide" data-bs-ride="carousel" data-bs-interval="4000">
  <div class="carousel-inner border">
    <div class="carousel-item size" *ngFor="let product of banners; let i = index" [ngClass]="{'active': i === 0}" dimensions>
      <img *ngIf="!product.video && product.img" class="bd-placeholder-img bd-placeholder-img-lg d-block w-100"
           [attr.data-src]="product.img" alt="orange tree" (click)="actionOnClick(product)" loading="lazy" (load)="removeSkeleton()"
           aria-hidden="true">
      <video *ngIf="product.video" class="bd-placeholder-img bd-placeholder-img-lg d-block w-100" playsinline autoplay
             [muted]="true" loop (click)="actionOnClick(product)">
        <source [src]="product.img" type="video/mp4">
      </video>
    </div>
  </div>
  <ng-container *ngIf="banners?.length">
    <button class="carousel-control-prev button-prev" data-bs-target="#carouselAutoplaying" data-bs-slide="prev">
      <svg class="carousel-control-prev-icon" viewBox="0 0 100 100">
        <path d="M 10,50 L 60,100 L 70,90 L 30,50  L 70,10 L 60,0 Z" class="arrow"></path>
      </svg>
    </button>
    <button class="carousel-control-next button-next" data-bs-target="#carouselAutoplaying" data-bs-slide="next">
      <svg class="carousel-control-next-icon" viewBox="0 0 100 100">-->
        <path d="M 10,50 L 60,100 L 70,90 L 30,50  L 70,10 L 60,0 Z" class="arrow"
              transform="translate(100, 100) rotate(180) "></path>
      </svg>
    </button>
    <div class="carousel-indicators">
      <div class="color" *ngFor="let product of banners; let i = index" data-bs-target="#carouselAutoplaying"
          [attr.data-bs-slide-to]="i" [ngClass]="{'active': i === 0}"></div>
    </div>
</ng-container>
</div>
