import {ChangeDetectionStrategy, Component} from '@angular/core';
import {CartRestService} from "../services/cart-rest.service";
import {Unsubscribe} from "../../../../../unsubscribe";
import {Bonuses, Products} from "../interfaces/cart.interface";
import {ActivatedRoute, Router} from "@angular/router";
import {StorageLogicService} from "../../../../../shared/utils/services/storage-logic.service";
import {GoogleAnalyticsLogicService} from "../../../../../shared/analytics/service/GoogleAnalytics-logic.service";
import {CartLogicService} from "../services/cart-logic.service";
import {CountryCityNotificationService} from 'src/app/shared/utils/services/country-city-notification.service';
import {filter, takeUntil, tap} from 'rxjs';
import { ProfileDataservice } from 'src/app/pages/profile/services/profile-data.service';

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss'],
  changeDetection:ChangeDetectionStrategy.OnPush
})
export class CartComponent extends Unsubscribe {

  constructor(
    private GAService: GoogleAnalyticsLogicService,
    public cartRestService: CartRestService,
    public cartLogicService: CartLogicService,
    private router: Router,
    public profileData: ProfileDataservice,
    public storageService: StorageLogicService,
    protected countryCityService: CountryCityNotificationService,
    private activatedRoute:ActivatedRoute
  ) {
    super();
   this.monitorLanguageChange();
  }

  returnBonuses(): Bonuses {
    return this.cartLogicService.cartProducts.bonus;
  }

  get minPrice() {
    return this.countryCityService.cityInfo.min_price;
  }

  deleteProduct(obj: Products) {
    this.GAService.eventEmitter(
      'remove_from_cart',
      {
        items: [{
          item_name: obj.name,
          item_id: obj.id,
          price: obj.price,
          quantity: 1
        }]
      });
    this.cartLogicService.deleteProduct(obj.id);
  }

  addProduct(obj: Products) {
    this.GAService.eventEmitter(
      'add_to_cart',
      {
        items: [{
          item_name: obj.name,
          item_id: obj.id,
          price: obj.price,
          quantity: 1
        }]
      });
    this.cartLogicService.addProduct(obj.id);
  }

  deleteAllProduct(obj: Products) {
    this.GAService.eventEmitter(
      'remove_from_cart',
      {
        items: [{
          item_name: obj.name,
          item_id: obj.id,
          price: obj.price,
          quantity: obj.quantity
        }]
      })
    this.cartLogicService.deleteAllOfSameProduct(obj.id);
  }

  deleteCart(): void {
   this.cartLogicService.clearCart();
  }


  redirectToOrdering() {
    const items = this.cartLogicService.cartProducts.products.map((product) => {
      return {
        item_name: product.name,
        item_id: product.id,
        price: product.price,
        quantity: product.quantity};
    });

    this.GAService.eventEmitter(
      'begin_checkout',
      {
        items
      });
    this.router.navigate(['../ordering'], {relativeTo:this.activatedRoute});
  }

  monitorLanguageChange() {
    this.countryCityService.languageChanged$.pipe(
      takeUntil(this.$destroy),
      filter(() => this.cartLogicService.cartProducts.id !== 0),
      tap(() => this.cartLogicService.cartUpdate())).subscribe();
  }
}
