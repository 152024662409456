export interface ProfileInterface {
  success: boolean;
  data: ProfileData;
}

export interface ProfileData {
  pub_id: string;
  phone: string;
  name: string;
  email: string;
  url_img: string;
  city_id: number;
  verified: string;
  birthday?: string;
}

export interface AddressesInterface {
  success: boolean;
  data: AddressData;
}

export interface AddressData {
  primary: Addresses
  additional: Addresses[]
}

export interface Addresses {
  id: number;
  address: string;
  house: string;
  attic: string;
  floor: string;
  apt: string;
  latitude: null | number;
  longitude: null | number;
}

export enum AuthStatus {
  true = 1,
  false = 0
}

export interface BalanceInterface {
  data: Balance;
  success: boolean;
}

export interface Balance {
  balance: string;
  history: bonusHistory[];
}

export interface bonusHistory {
  date:  string;
  description: string;
  expired: boolean;
  value: string;
}

export interface TokenClientResponse {
  access_token: string;
  expires_in?: number;
  id_token?: string;
  scope?: string;
  token_type?: string;
}

export interface UserInfo {
  id: string;
  email: string;
  verified_email: boolean;
  name: string;
  given_name: string;
  family_name: string;
  picture: string;
  locale: string;
}

export interface UserBirthdayResponse {
  success: boolean;
  data: {
    available: boolean;
    birthday?:string
  }
}