import { Injectable } from "@angular/core";
import { CitiesData, CountriesData, ModuleType, NavbarInterface } from "src/app/shell/components/navbar/interfaces/navbar.interface";
import { StorageLogicService } from "./storage-logic.service";
import { CityInfoData, Modules } from "src/app/shell/components/header/interfaces/city-info.interface";
import { InfoData } from "src/app/pages/information/interfaces/information.interface";
import { TranslateService } from "@ngx-translate/core";
import { NavbarRestService } from "src/app/shell/components/navbar/services/navbar-rest.service";
import { BehaviorSubject, Observable, Subject, filter, forkJoin, of, switchMap, tap } from "rxjs";
import { InformationRestService } from "src/app/pages/information/services/information-rest.service";
import {ProfileDataservice} from "../../../pages/profile/services/profile-data.service";
import {ProfileRestService} from "../../../pages/profile/services/profile-rest.service";

@Injectable({providedIn: 'root'})
export class CountryCityNotificationService {
    languageChanged$ = new Subject<string>();
    langChanedRefetchRequired = false;
    cityChanged$ = new Subject<void>();
    countryChangeFinished$ = new Subject<void>();
    lang$!: string | null;
    selectedCityId!: string | null;
    placeholder!: string;
    mask!: string;

    countriesList!: CountriesData[];
    citiesList!: CitiesData[];
    selectedCountry!: CountriesData;
    cityModule!: Record<ModuleType, Modules | undefined>;
    cityInfo!: CityInfoData;
    infoPages!: InfoData[];

    currentCityName = new BehaviorSubject<string>(this.storageService.getData('city_name'))


    constructor(
        private storageService: StorageLogicService,
        private translate: TranslateService,
        private navbarService: NavbarRestService,
        private infoService: InformationRestService,
        private profileDataservice: ProfileDataservice,

    ) {}

    updateCitiesState(data: CityInfoData) {
        this.lang$ = this.storageService.getData('lang');
        this.selectedCityId = this.storageService.getData('city');
        this.storageService.setData('cityInfo', data);
        this.cityInfo = data;
        this.setCityModules();
        this.phonesMask();
        this.currentCityName.next(data.name);
    }

    phonesMask() {
        switch (this.cityInfo.country.phone_code.length) {
            case 4:
                this.placeholder = `${this.cityInfo.country.phone_code} (XX) XXX XX XX`;
                this.mask = '+000 (00) 000 00 00';
                break;
              case 3:
                this.placeholder = `${this.cityInfo.country.phone_code} (XXX) XX XXXX`;
                this.mask = '+00 (000) 00 0000';
                break;
        }
    }

    updateCountriesState(countries: CountriesData[]):Observable<NavbarInterface<CitiesData>|null> {
        this.countriesList = countries;
        this.selectedCountry = this.countriesList.find(country => country.phone_code === this.cityInfo.country.phone_code)!;
        this.storageService.setData('country', this.selectedCountry);
        if (this.selectedCountry && !this.selectedCountry.languages.find(lang => lang.code === this.lang$)) {
            this.changeLanguage(this.selectedCountry.languages[0].code);
            return of(null);
        } else {
            return this.sendCitiesListUpdateRequest();
        }
    }

    modifyLanguageBeforeRequests() {
        if (this.selectedCountry && !this.selectedCountry.languages.find(lang => lang.code === this.lang$)) {
            this.storageService.setData('lang',this.selectedCountry.languages[0].code);
            this.lang$ = this.selectedCountry.languages[0].code;
        }
    }

    sendCitiesListUpdateRequest() {
        return this.navbarService.getCities(this.selectedCountry.id)
        .pipe((tap((citiesData) => this.citiesList = citiesData.data)));
    }

    changeLanguage(newLangCode: string) {
        this.storageService.setData('lang', newLangCode);
      let historyRequest;
      if (this.storageService.getData('Authorization')?.pub_id) {
        historyRequest = this.profileDataservice.retrieveUserHistory();
      } else {
        historyRequest = of(null); // Создание "пустого" Observable, если пользователь не авторизован
      }
        return forkJoin({
            city: this.navbarService.getInfo(),
            countries: this.navbarService.getCountries(),
            infoPages: this.infoService.getInfo(),
            history: historyRequest,
        }).pipe(
            switchMap(({city, countries, infoPages}) => {
                this.lang$ = newLangCode;
                this.translate.use(this.lang$);
                this.storageService.setData('cityInfo', city.data);
                this.storageService.setData('city_name', city.data.name);
                this.cityInfo = city.data;
                this.currentCityName.next(city.data.name)
                this.setCityModules();
                this.infoPages = infoPages.data;
                this.languageChanged$.next(this.lang$);
                this.phonesMask();
                this.countryChangeFinished$.next();
                return this.updateCountriesState(countries);
        })
        ).subscribe();
    }

    updateInfoPages(infoPages: InfoData[]): void {
        this.infoPages = infoPages;
    }

    applyCity() {
        return this.navbarService.getInfo().pipe(
            filter(response => response.success),
            tap((response) => {
                this.updateCitiesState(response.data);
            }));
    }

    private setCityModules(): void {
        this.cityInfo = this.storageService.getData('cityInfo');
        const moduleTypes = [
          ModuleType.OnlinePayment,
          ModuleType.Pickup,
          ModuleType.OrderTracking,
          ModuleType.Vacancy,
          ModuleType.Card,
          ModuleType.Delivery,
          ModuleType.Bonuses,
          ModuleType.Preorder,
          ModuleType.DontCall
        ];

        this.cityModule = moduleTypes.reduce((result, type) => {
            result[type] = this.cityInfo.modules.find(item => item.type === type);
            return result;
          }, {} as Record<ModuleType, Modules | undefined>);
    }

    performCityCountryFetchRequest() {
    return this.navbarService.getCountries().pipe(
        filter(() => this.countriesList.length === 0 || this.langChanedRefetchRequired),
        tap(countries => {
            this.countriesList = countries;
            this.selectedCountry = countries.find(country => country.phone_code === this.cityInfo.country.phone_code)!;
            this.langChanedRefetchRequired = false;
        }));
    }
}
