import {Environment, selectDomain} from "../app/shared/utils/select-domain";

export enum env{
  localhost = 'http://127.0.0.1:8000/',
  developer = 'https://storage.dev.3303.ua/',
  ecopizza = 'https://storage-ecopizza.3303.ua/',
  ecopizzadev = 'https://storage-ecopizza.dev.3303.ua/',
  production = 'https://storage.3303.ua/'
}

export const domains: Environment[] = [
  {
    server: env.production,
    domain: 'https://3303.ua',
    id: '3303'
  },
  {
    server: env.developer,
    domain: 'https://dev.3303.ua',
    id: '3303'
  },
  {
    server: env.ecopizza,
    domain: 'https://crm-ecopizza.3303.ua',
    id: '3303'
  },
  {
    server: env.ecopizzadev,
    domain: 'https://crm-ecopizza.dev.3303.ua',
    id: '3303'
  },
  {
    server: env.developer,
    domain: 'http://localhost:4200',
    id: '3303'
  },
  {
	  server: env.production,
	  domain: 'https://www.3303.ua',
	  id: '3303'
  }
];

export const environment = {
  production: false,
  url: selectDomain(),
  firebaseConfig: {
    apiKey: 'AIzaSyD9w3ubAxbdkJUmhj8QWWU0Ur5Mu4TgASA',
    authDomain: 'sushi-3303.firebaseapp.com',
    databaseURL: '',
    projectId: 'sushi-3303',
    storageBucket: 'sushi-3303.appspot.com',
    messagingSenderId: '574472089104',
    appId: '1:574472089104:web:9c34df2e591fbeacb76495',
    measurementId: 'G-3Q3QKRB9KG'
  },
  sentryConfig: {
    dsn: 'https://680de5b98f555f216be30b16fe82183e@o4504436440432640.ingest.us.sentry.io/4508399349334016',
    propagationTarget: ["https://3303.ua/"],
    authToken: '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',
    debug: false
  },
  storageServiceKey: 'secret-key'
};



/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
