import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {takeUntil} from "rxjs";
import {Unsubscribe} from "../../../unsubscribe";
import {FilterByIngInterface, FilterByMarksInterface} from "../../../pages/home/interfaces/filterByIng.interface";
import {HomeRestService} from "../../../pages/home/services/home-rest.service";
import {FilterType} from "../../../pages/home/interfaces/home.interface";

@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss'],
})
export class FilterComponent extends Unsubscribe implements OnInit {
  filterTypes = [
    {
      type: FilterType.cheap,
      img: 'assets/icons/sorting-arrow.webp',
    },
    {
      type: FilterType.expensive,
      img: 'assets/icons/sorting-arrow.webp',
    },
    {
      type: FilterType.novelty,
      img: 'assets/icons/novelty.webp',
    },
  ];

  @Input() selectedFilter = '';
  @Input() filter = '';
  @Input() filterIng = '';
  @Input() productSearch = '';
  @Input() valueFilterIng = '';
  @Input() valueFilterMark = '';
  @Input() filterByIng: FilterByIngInterface = {};
  @Input() filterByMark: FilterByMarksInterface = {};
  @Input() showFilter: boolean = true;
  @Input() hideFilterIng: boolean = false;
  @Input() hideFilterMark: boolean = false;

  @Output() filterChange = new EventEmitter();
  @Output() filterIngChange = new EventEmitter();
  @Output() productSearchChange = new EventEmitter();
  @Output() valueFilterIngChange = new EventEmitter();
  @Output() valueFilterMarkChange = new EventEmitter();
  @Output() filterByIngChange = new EventEmitter();
  @Output() filterByMarkChange = new EventEmitter();
  @Output() sortProductsByPrice = new EventEmitter<FilterType>();

  constructor(private homeService: HomeRestService) {
    super();
  }

  ngOnInit(): void {
    this.getIngByFilter();
    this.getMarksByFilter();
  }

  selectMarkFilter(type: string, id: string, name: string): void {
    if (type === 'mark') {
      this.filterChange.emit(id.toString());
      this.valueFilterMarkChange.emit(name);
    }
    if (type === 'ing') {
      this.filterIngChange.emit(id.toString());
      this.valueFilterIngChange.emit(name);
    }
  }

  filterResetSearch(): void {
    this.productSearchChange.emit('');
  }
  filterResetIng(): void {
    this.filterIngChange.emit('');
    this.valueFilterIngChange.emit('');
  }
  filterResetMark(): void {
    this.filterChange.emit('');
    this.valueFilterMarkChange.emit('');
  }

  getIngByFilter(): void {
    this.homeService
      .getIngByFilter()
      .pipe(takeUntil(this.$destroy))
      .subscribe({
        next: (value) => this.filterByIngChange.emit(value),
      });
  }

  getMarksByFilter(): void {
    this.homeService
      .getMarksByFilter()
      .pipe(takeUntil(this.$destroy))
      .subscribe({
        next: (value) => this.filterByMarkChange.emit(value),
      });
  }

  sortProducts(filter: FilterType): void {
    this.selectedFilter = filter;
    this.sortProductsByPrice.emit(filter)
  }

  shouldRotate(type: FilterType) {
    return type === FilterType.expensive ? 'transform:rotate(180deg)':null;
  }

  protected readonly FilterType = FilterType;
}
