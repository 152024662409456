import {AfterContentInit, Component, Inject, Input, OnInit} from '@angular/core';
import {BannerData, BannersInterface} from "../../banners/interfaces/banners.interface";
import {DOCUMENT} from "@angular/common";
import {Router} from "@angular/router";
import {interval, merge, Subject, Subscription, take, takeUntil} from "rxjs";
import {Unsubscribe} from "../../../../unsubscribe";

@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss']
})
export class CarouselComponent extends Unsubscribe implements AfterContentInit {

  @Input() banners!: BannerData[] | undefined;
  constructor(@Inject(DOCUMENT) private document: Document, private router: Router) {
    super();
  }


  ngAfterContentInit(): void {
    interval(4000)
      .pipe(take(1))
      .subscribe(() => {
        const nextButton = this.document.querySelector('.button-next');
        if (nextButton) {
          (nextButton as HTMLElement).click();
        }
      });
  }
  actionOnClick(object: BannerData): void {
    if (object.web_action) {
      this.document.location.href = object.web_action;
    } else if (object.product_id) {
      this.router.navigate(['/home', object.site_url], {
          queryParams: {
            productId: object.product_id,
          },
      }).then();
    }
  }

  removeSkeleton(): void {
    const skeleton = this.document.getElementById('skeleton');

    if (skeleton) {
      skeleton.remove();
    }
  }
}
