import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ShellComponent} from './components/shell/shell.component';
import {NavbarComponent} from "./components/navbar/components/navbar.component";
import {HeaderComponent} from "./components/header/components/header.component";
import {MenuComponent} from "./components/menu/components/menu.component";
import {RouterModule} from "@angular/router";
import {NgbModule} from "@ng-bootstrap/ng-bootstrap";
import {CartComponent} from "./components/navbar/cart/components/cart.component";
import {MenuRestService} from "./components/menu/services/menu-rest.service";
import {CartRestService} from "./components/navbar/cart/services/cart-rest.service";
import {HeaderRestService} from "./components/header/services/header-rest.service";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {BannersComponent} from "./components/banners/components/banners.component";
import {BannersRestService} from "./components/banners/services/banners-rest.service";
import {TranslateModule} from "@ngx-translate/core";
import {NgCircleProgressModule} from "ng-circle-progress";
import { CarouselComponent } from './components/carousel/components/carousel.component';
import {HomeModule} from "../pages/home/module/home.module";
import {FooterComponent} from "./components/footer/components/footer.component";
import {InformationRestService} from "../pages/information/services/information-rest.service";
import {CartLogicService} from "./components/navbar/cart/services/cart-logic.service";
import { ProfileRestService } from '../pages/profile/services/profile-rest.service';
import { DeveloperAuthComponent } from './auth/developer-site-auth/developer-auth/developer-auth.component';
import {DimensionsModule} from "../shared/directives/dimensions.module";
import { CustomCarouselComponent } from './components/custom-carousel/custom-carousel.component';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';

const declarations = [
  ShellComponent,
  NavbarComponent,
  HeaderComponent,
  FooterComponent,
  MenuComponent,
  CartComponent,
  BannersComponent,
  CarouselComponent
]

@NgModule({
  declarations: [
    ...declarations,
    DeveloperAuthComponent,
    CustomCarouselComponent,
  ],
    imports: [
        CommonModule,
        RouterModule,
        NgbModule,
        FormsModule,
        HomeModule,
        TranslateModule,
        NgCircleProgressModule,
        NgxMaskDirective,
        NgxMaskPipe,
        ReactiveFormsModule,
        DimensionsModule,
    ],
    exports: [...declarations],
  providers: [
    MenuRestService,
    CartRestService,
    CartLogicService,
    HeaderRestService,
    BannersRestService,
    InformationRestService,
    ProfileRestService,
    provideNgxMask()
  ]
})
export class ShellModule {
}
