import {Injectable} from '@angular/core';
import * as CryptoJS from 'crypto-js';
import {environment} from "../../../../environments/environment.prod";

@Injectable({
  providedIn: 'root'
})

export class StorageLogicService {
  private readonly secretKey = environment.storageServiceKey

  constructor() {}

  setData(key: string, value: any) {
    // localStorage.setItem(key, this.encrypt(JSON.stringify(value)));
    localStorage.setItem(key, JSON.stringify(value));
  }

  getData(key: string): any {
    try {
      // const data = localStorage.getItem(key);
      // return this.decrypt(data);
      return JSON.parse(localStorage.getItem(key)!);
    } catch (error) {
      console.error(error);
      localStorage.removeItem(key);
      return null;
    }
  }

  removeData(key: string) {
    localStorage.removeItem(key);
  }

  setSessionData(key: string, value: any): void {
    sessionStorage.setItem(key, JSON.stringify(value));
  }

  getSessionData(key: string) {
    try {
      return JSON.parse(sessionStorage.getItem(key)!);
    } catch (error) {
      console.error(error);
      return null;
    }
  }
  
  removeSessionData(key: string): void {
    sessionStorage.removeItem(key);
  }

  private encrypt(txt: string): string {
    return CryptoJS.AES.encrypt(txt, this.secretKey).toString();
  }

  private decrypt(txtToDecrypt: string | null) {
    if (!txtToDecrypt) {
      return null;
    }
    return JSON.parse(CryptoJS.AES.decrypt(txtToDecrypt, this.secretKey).toString(CryptoJS.enc.Utf8)) || null;
  }
}
