import {environment} from "../environments/environment.prod";

export class AppApiUrls {
  public static domain = environment.url;

  // получение 20 случайных товаров
  public static product = () => `${AppApiUrls.domain}api/app/products`;
  // получение категорий
  public static categories = () => `${AppApiUrls.domain}api/app/products/category`;
  // получение товаров по категории
  public static categoriesProduct = () => `${AppApiUrls.domain}api/app/products`;
  // корзина
  public static cart = () => `${AppApiUrls.domain}api/app/cart`;
  public static countries = () => `${AppApiUrls.domain}api/app/countries`;
  // получение городов
  public static cities = () => `${AppApiUrls.domain}api/app/cities`;
  // Информация по городу
  public static cityInfo = () => `${AppApiUrls.domain}api/app/city_info`;
  // Отделения по городу
  public static PlacesByCity = () => `${AppApiUrls.domain}api/app/places`;
  // Информация по пользователю
  public static userInfo = () => `${AppApiUrls.domain}api/app/user/`;
  // Адреса
  public static userAddresses = () => `${AppApiUrls.domain}api/app/user/addresses`;
  // Выбор главного адреса
  public static selectAddresses = () => `${AppApiUrls.domain}api/app/user/primary_address`;
  // Отримання історії замовлень
  public static userHistory = () => `${AppApiUrls.domain}api/app/user/order_history`;
  // Получение баннеров
  public static banners = () => `${AppApiUrls.domain}api/app/banners`;
  // Отправка заказа
  public static sendOrder = () => `${AppApiUrls.domain}api/app/order`;
  // Авторизация
  public static auth = () => `${AppApiUrls.domain}api/app/user/auth`;
  // Інгредієнти для фільтра
  public static ingFilter = () => `${AppApiUrls.domain}api/app/filter/ingredients`;
  // Продукт-марки для фільтра
  public static ingMarks = () => `${AppApiUrls.domain}api/app/filter/marks`;
  // Відстеження замовлення
  public static tracking = () => `${AppApiUrls.domain}api/app/delivery_status/`;
  // Замовити знову
  public static reorder = () => `${AppApiUrls.domain}api/app/cart/reorder`;
  // Відправка телефона на верифікацію
  public static sendPhone = () => `${AppApiUrls.domain}api/app/user/phone_verification`;
  // Сторінки інформації
  public static info = () => `${AppApiUrls.domain}api/app/about`;
  // Відгуки
  public static reviews = () => `${AppApiUrls.domain}api/app/review`;
  // Оплата з додатку
  public static checkoutCart = () => `${AppApiUrls.domain}api/app/order/checkout`;
  // Інфо по бонусам
  public static bonusInfo = () => `${AppApiUrls.domain}api/app/bonus_values`;
  // Баланс бонусів
  public static bonusBalance = () => `${AppApiUrls.domain}api/app/user/bonuses/balance`;
  // Час для передзамовлення
  public static preorderTime = () => `${AppApiUrls.domain}api/app/preorder_time`;
  public static socialMedia = () => `${AppApiUrls.domain}api/app/city_socials`;
  // Конструктор сета
  public static setConstructor = () => `${AppApiUrls.domain}api/app/set_constructor`;
  // Запит "передзвоніть мені"
  public static callbackRequest = () => `${AppApiUrls.domain}api/app/callback_request`;
  // Написати директору
  public static ceoMessage = () => `${AppApiUrls.domain}api/app/ceo_message`;
  public static recommended = () => `${AppApiUrls.domain}api/app/recommended`;
  // Доступ до dev-версії
  public static devWeb = () => `${AppApiUrls.domain}api/app/access`;
  public static decoration = () => `${AppApiUrls.domain}api/app/decoration`;
  // Улюблені товари
  public static favoriteProducts=()=>`${AppApiUrls.domain}api/app/saved_product`;
  //Промо
  public static promoCodes = (endpoint:'list'|'use' | 'create') => `${AppApiUrls.domain}api/app/promocode/${endpoint}`;
  //Подарункові товари
  public static freeProducts = (endpoint:'list'|'get') => `${AppApiUrls.domain}api/app/bonus_product/${endpoint}`;
  //Отримання опцій для автокомпліта адреси
  public static locationsAutocomplete = () => `${AppApiUrls.domain}api/app/delivery/autocomplete`;
  //Перевірка покриття зони доставки
  public static zoneCoverageByPlaceId = () => `${AppApiUrls.domain}api/app/delivery/check`;
  //Перевірка покриття зони покриття після переміщення маркера
  public static zoneCoverageByCoordinates = () => `${AppApiUrls.domain}api/app/delivery/check-coordinates`;
  //Отримання координат для старих адрес
  public static unverifiedAddressCoordinates = () => `${AppApiUrls.domain}api/app/delivery/check-address`;
}
