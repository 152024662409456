import {Component, OnInit} from '@angular/core';
import {delay, forkJoin, merge, switchMap, takeUntil, tap} from "rxjs";

import {FooterRestService} from "../services/footer-rest.service";
import {PlacesData, SocialMediaData} from "../interfaces/footer.interface";
import {Unsubscribe} from "../../../../unsubscribe";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import { CountryCityNotificationService } from 'src/app/shared/utils/services/country-city-notification.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent extends Unsubscribe implements OnInit {

  places!: PlacesData;
  socialMedia: SocialMediaData[] = [];
  showModal: boolean = false;
  showThankYouModal: boolean = false;
  form!: FormGroup;
  phoneLength: number = 0;

  constructor(
    private footerService: FooterRestService,
    public countryCityService: CountryCityNotificationService,
    private fb: FormBuilder,
  ) {
    super();
  }

  ngOnInit(): void {
    this.updateData().subscribe();
    this.setupLanguageAndCityChangeSubscription();
    this.formInit();
  }

  formInit(): void {
    this.form = this.fb.group({
      id: [null],
      name: [null, [Validators.required]],
      phone: [null, [Validators.required]],
      message: [null, [Validators.required]]
    });
  }

  private getPlaces() {
    return this.footerService.getPlaces();
  }

  private getSocialMedia() {
    return this.footerService.getSocialMedia();
  }

  updateData() {
    return forkJoin([this.getPlaces(),this.getSocialMedia()]).pipe(
    tap(([places, media]) => {
      this.socialMedia = media.data;
      this.places = places.data;
    }));
  }

  setupLanguageAndCityChangeSubscription() {
    merge(
      this.countryCityService.languageChanged$,
      this.countryCityService.cityChanged$).pipe(
        takeUntil(this.$destroy),
        switchMap(() => this.updateData())).subscribe();
  }

  postCeoMessage(): void {
    this.footerService.postCeoMessage(this.form?.value)
      .pipe(takeUntil(this.$destroy),
        tap(() => {
          this.closeModal();
          this.showThankYouModal = true;
        }),
        delay(2000),
        tap(() => this.closeThankYouModal())
      )
      .subscribe({
        error: () => {
          this.closeModal();
        }
      });
  }

  keyPressNumbers(event: { keyCode: number; preventDefault: () => void; }) {
    const inp = String.fromCharCode(event.keyCode);
    const phoneControl = this.form.get('phone');

    if (phoneControl && /[0-9]/.test(inp)) {
      const currentValue = phoneControl.value || '';
      if (currentValue === '' && !currentValue.startsWith(this.countryCityService.selectedCountry.phone_code)) {
        phoneControl.setValue(this.countryCityService.selectedCountry.phone_code + currentValue);
      }
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }

  onInputFocus() {
    const phoneControl = this.form.get('phone');
    if (phoneControl) {
      const currentValue = phoneControl.value || '';
      if (currentValue.trim() === '') {
        if (!currentValue.startsWith(this.countryCityService.selectedCountry.phone_code)) {
          phoneControl.setValue(this.countryCityService.selectedCountry.phone_code + currentValue);
        }
      }
    }
  }

  openModal(): void{
    this.showModal = true;
  }

  closeModal(): void{
    this.form.reset();
    this.showModal = false;
  }

  closeThankYouModal(): void {
    this.showThankYouModal = false;
  }
}
