import {MetaDefinition} from "@angular/platform-browser";

export const common : MetaDefinition[] = [
 {property: 'og:image', content: 'https://3303.ua/assets/logo.webp'},
 {name: 'og:site_name', content: 'Суші 3303'},
 {name: 'copyright', content: 'Суші 3303'},
 {name: 'language', content: 'UA'},
 {name: 'twitter:image', content: 'https://3303.ua/assets/logo.webp'},
 {name: 'twitter:site_name', content: 'Суші 3303'},
 {name: 'twitter:country-name', content: 'Україна'},
 {name: 'og:country-name', content: 'Україна'}
]
export const home: MetaDefinition[] = [
  {name: 'googlebot', content: 'notranslate'},
  {property: 'og:url', content: 'https://3303.ua/home/'},
  {property: 'og:description', content: 'Великий вибір оригінальних і дуже смачних суші сетів, доступних для замовлення! З доставкою по місту безкоштовно. Запрошуємо ознайомитись з актуальним меню на сторінці сайту.'},
  {property: 'og:image', content: 'https://3303.ua/assets/logo.webp'},
  {name: 'og:title', property: 'og:title', content: 'Суші 3303 | Доставка японської кухні | Швидка доставка'},
  // {name: 'apple-itunes-app', content: ''},
  {name: 'msapplication-navbutton-color', content: '#000'},
  {name: 'theme-color', content: '#000'},
  {name: 'keywords', content: 'Суші, ролли, локшина, доставка їжі, доставка японської їжі, доставка суші, доставка ролів, доставка суші Запоріжжя, доставка суші Дніпро, доставка суші Івано-Франківськ, доставка суші Хмельницький, доставка суші Суми, безкоштовна доставка, безкоштовна доставка суші, безкоштовна доставка ролів, найкращі суші, смачні суші, найкращі роли, смачні роли, локшина WOK, смачна локшина, замовити суші, замовити роли, замовити локшину, замовити доставку'},
  {name: 'copyright', content: 'Суші 3303'},
  {name: 'language', content: 'UA'},
  {name: 'subject', content: 'Безкоштовна доставка суші, ролів, локшини та напоїв в Україні'},
  {name: 'description', content: 'Великий вибір оригінальних і дуже смачних суші сетів, доступних для замовлення! З доставкою по місту безкоштовно. Запрошуємо ознайомитись з актуальним меню на сторінці сайту.'},
  {name: 'twitter:title', content: 'Суші 3303 | Доставка японської кухні | Швидка доставка '},
  {name: 'twitter:description', content: 'Великий вибір оригінальних і дуже смачних суші сетів, доступних для замовлення! З доставкою по місту безкоштовно. Запрошуємо ознайомитись з актуальним меню на сторінці сайту.'},
  {name: 'twitter:url', content: 'https://3303.ua/home'},
  {name: 'twitter:image', content: 'https://3303.ua/assets/logo.webp'},
  {name: 'twitter:site_name', content: 'Суші 3303'},
];


export const rolls: MetaDefinition[] = [
  {property: 'og:url', content: 'https://3303.ua/home/rolls'},
  {name: 'twitter:url', content: 'https://3303.ua/home/rolls'},
  {name: 'title', content: 'Смачні роли на будь-який смак - Замовляйте в 3303.ua!'},
  {name: 'og:title', property: 'og:title', content: 'Смачні роли на будь-який смак - Замовляйте в 3303.ua!'},
  {name: 'twitter:title', content: 'Смачні роли на будь-який смак - Замовляйте в 3303.ua!'},
  {
    property: 'og:description',
    content: 'Сторінка "Роли" на сайті 3303.ua вражає своїм широким вибором свіжих та неймовірно смачних ролів японської кухні, які задовольнять навіть найвибагливіші смакові пристрасті.'
  },
  {
    name: 'description',
    content: 'Сторінка "Роли" на сайті 3303.ua вражає своїм широким вибором свіжих та неймовірно смачних ролів японської кухні, які задовольнять навіть найвибагливіші смакові пристрасті.'
  },
  {
    name: 'twitter:description',
    content: 'Сторінка "Роли" на сайті 3303.ua вражає своїм широким вибором свіжих та неймовірно смачних ролів японської кухні, які задовольнять навіть найвибагливіші смакові пристрасті.'
  },
  {
    name: 'keywords',
    content: 'Суші, роли, локшина, доставка їжі, доставка японської їжі, доставка суші, доставка ролів, доставка суші Запоріжжя, доставка суші Дніпро, доставка суші Івано-Франківськ, доставка суші Хмельницький, доставка суші Суми, безкоштовна доставка, безкоштовна доставка суші, безкоштовна доставка ролів, найкращі суші, смачні суші, найкращі роли, смачні роли, замовити суші, замовити роли, замовити доставку'
  },
];

export const sushi: MetaDefinition[] = [
  {property: 'og:url', content: 'https://3303.ua/home/sushi'},
  {name: 'twitter:url', content: 'https://3303.ua/home/sushi'},
  {name: 'title', content: 'Суші 3303 | Робіть онлайн замовлення японської кухні'},
  {name: 'og:title', property: 'og:title', content: 'Суші 3303 | Робіть онлайн замовлення японської кухні'},
  {name: 'twitter:title', content: 'Суші 3303 | Робіть онлайн замовлення японської кухні'},
  {
    property: 'og:description',
    content: 'Неперевершені та смачні суші з швидкою доставкою від Суші 3303 - насолоджуйтесь найкращими стравами японської кухні не виходячи з дому! Замовляйте просто зараз!'
  },
  {
    name: 'description',
    content: 'Неперевершені та смачні суші з швидкою доставкою від Суші 3303 - насолоджуйтесь найкращими стравами японської кухні не виходячи з дому! Замовляйте просто зараз!'
  },
  {
    name: 'twitter:description',
    content: 'Неперевершені та смачні суші з швидкою доставкою від Суші 3303 - насолоджуйтесь найкращими стравами японської кухні не виходячи з дому! Замовляйте просто зараз!'
  },
  {
    name: 'keywords',
    content: 'Суші, роли, локшина, доставка їжі, доставка японської їжі, доставка суші, доставка ролів, доставка суші Запоріжжя, доставка суші Дніпро, доставка суші Івано-Франківськ, доставка суші Хмельницький, доставка суші Суми, безкоштовна доставка, безкоштовна доставка суші, безкоштовна доставка ролів, найкращі суші, смачні суші, найкращі роли, смачні роли, замовити суші, замовити роли, замовити доставку'
  },
];

export const sets: MetaDefinition[] = [
  {property: 'og:url', content: 'https://3303.ua/home/sets'},
  {name: 'twitter:url', content: 'https://3303.ua/home/sets'},
  {name: 'title', content: 'Смачні суші сети та японські страви на сайті 3303.ua'},
  {name: 'og:title', property: 'og:title', content: 'Смачні суші сети та японські страви на сайті 3303.ua'},
  {name: 'twitter:title', content: 'Смачні суші сети та японські страви на сайті 3303.ua'},
  {
    property: 'og:description',
    content: 'Розкішні суші cети та різноманітні японські страви - ідеальний варіант для насолоди смаком і задоволення гастрономічних пристрастей, замовляйте на 3303.ua прямо зараз!'
  },
  {
    name: 'description',
    content: 'Розкішні суші cети та різноманітні японські страви - ідеальний варіант для насолоди смаком і задоволення гастрономічних пристрастей, замовляйте на 3303.ua прямо зараз!'
  },
  {
    name: 'twitter:description',
    content: 'Розкішні суші cети та різноманітні японські страви - ідеальний варіант для насолоди смаком і задоволення гастрономічних пристрастей, замовляйте на 3303.ua прямо зараз!'
  },
  {
    name: 'keywords',
    content: 'Суші, сети, сет ролів, замовити сет ролів, замовити сет суші, роли, локшина, доставка їжі, доставка японської їжі, доставка суші, доставка ролів, доставка суші Запоріжжя, доставка суші Дніпро, доставка суші Івано-Франківськ, доставка суші Хмельницький, доставка суші Суми, безкоштовна доставка, безкоштовна доставка суші, безкоштовна доставка ролів, найкращі суші, смачні суші, найкращі роли, смачні роли, замовити суші, замовити роли, замовити доставку'
  },
];

export const snacks: MetaDefinition[] = [
  {property: 'og:url', content: 'https://3303.ua/home/snacks'},
  {name: 'twitter:url', content: 'https://3303.ua/home/snacks'},
  {name: 'title', content: 'Вишукані закуски | 3303.ua - доставка японської кухні'},
  {name: 'og:title', property: 'og:title', content: 'Вишукані закуски | 3303.ua - доставка японської кухні'},
  {name: 'twitter:title', content: 'Вишукані закуски | 3303.ua - доставка японської кухні'},
  {
    property: 'og:description',
    content: 'Погрузіться у світ смаків японських снеків, які ви можете замовити на 3303.ua! Розкрийте нові гастрономічні враження та насолоджуйтесь широким асортиментом смачних страв.'
  },
  {
    name: 'description',
    content: 'Погрузіться у світ смаків японських снеків, які ви можете замовити на 3303.ua! Розкрийте нові гастрономічні враження та насолоджуйтесь широким асортиментом смачних страв.'
  },
  {
    name: 'twitter:description',
    content: 'Погрузіться у світ смаків японських снеків, які ви можете замовити на 3303.ua! Розкрийте нові гастрономічні враження та насолоджуйтесь широким асортиментом смачних страв.'
  },
  {
    name: 'keywords',
    content: 'Суші, гарячі закуски, спрінг роли, салат хіяші, салат чука, роли без рису, замовити спрінг роли, роли, локшина, доставка їжі, доставка японської їжі, доставка суші, доставка ролів, доставка суші Запоріжжя, доставка суші Дніпро, доставка суші Івано-Франківськ, доставка суші Хмельницький, доставка суші Суми, безкоштовна доставка, безкоштовна доставка суші, безкоштовна доставка ролів, найкращі суші, смачні суші, найкращі роли, смачні роли, замовити суші, замовити роли, замовити доставку'
  },
];
export const rice: MetaDefinition[] = [
  {property: 'og:url', content: 'https://3303.ua/home/rice'},
  {name: 'twitter:url', content: 'https://3303.ua/home/rice'},
  {name: 'title', content: 'Вибір найсмачніших рисових страв від Суші 3303.ua'},
  {name: 'og:title', property: 'og:title', content: 'Вибір найсмачніших рисових страв від Суші 3303.ua'},
  {name: 'twitter:title', content: 'Вибір найсмачніших рисових страв від Суші 3303.ua'},
  {
    property: 'og:description',
    content: 'На нашому сайті Суші 3303 ви знайдете найкращі та вишукані рисові страви японської кухні, які прекрасно підійдуть для вашого смаку та задовольнять усі ваші кулінарні бажання.'
  },
  {
    name: 'description',
    content: 'На нашому сайті Суші 3303 ви знайдете найкращі та вишукані рисові страви японської кухні, які прекрасно підійдуть для вашого смаку та задовольнять усі ваші кулінарні бажання.'
  },
  {
    name: 'twitter:description',
    content: 'На нашому сайті Суші 3303 ви знайдете найкращі та вишукані рисові страви японської кухні, які прекрасно підійдуть для вашого смаку та задовольнять усі ваші кулінарні бажання.'
  },
  {
    name: 'keywords',
    content: 'Замовити смажений рис, доставка рису, доставка смаженого рису, доставка, замовити доставку, рис, рис з морепродуктами, рис з м\'ясом, смачний рис, замовити рис в Запоріжжі, замовити рис в Дніпрі, замовити рис в Івано-Франківську, замовити рис в Сумах, замовити рис в Хмельницьку'
  },
];

export const noodles: MetaDefinition[] = [
 {property: 'og:url', content: 'https://3303.ua/home/noodles'},
 {name: 'twitter:url', content: 'https://3303.ua/home/noodles'},
 {name: 'title', content: 'Оберіть свої улюблені страви з локшини на 3303.ua!'},
 {name: 'og:title', property: 'og:title', content: 'Оберіть свої улюблені страви з локшини на 3303.ua!'},
 {name: 'twitter:title', content: 'Оберіть свої улюблені страви з локшини на 3303.ua!'},
 {
    property: 'og:description',
    content: 'На цій сторінці сайту 3303.ua ви знайдете різноманітні смачні страви з локшиною та макаронами, які задовольнять ваші смакові пристрасті та подарують незабутні кулінарні враження.'
  },
 {
    name: 'description',
    content: 'На цій сторінці сайту 3303.ua ви знайдете різноманітні смачні страви з локшиною та макаронами, які задовольнять ваші смакові пристрасті та подарують незабутні кулінарні враження.'
  },
 {
    name: 'twitter:description',
    content: 'На цій сторінці сайту 3303.ua ви знайдете різноманітні смачні страви з локшиною та макаронами, які задовольнять ваші смакові пристрасті та подарують незабутні кулінарні враження.'
  },
 {
    name: 'keywords',
    content: 'Замовити локшину, локшина WOK, WOK, рисова локшина, яєчна локшина, скляна локшина, фунчоза, Болоньєзе, Удон, доставка локшини, доставка смачної локшини, доставка, замовити доставку, локшина, локшина з морепродуктами, локшина з м\'ясом, замовити локшину в Запоріжжі, замовити локшину в Дніпрі, замовити локшину в Івано-Франківську, замовити локшину в Сумах, замовити локшину в Хмельницьку'
  },
];

export const drinks: MetaDefinition[] = [
 {property: 'og:url', content: 'https://3303.ua/home/drinks'},
 {name: 'twitter:url', content: 'https://3303.ua/home/drinks'},
 {name: 'title', content: 'Різноманітний вибір освіжаючих напоїв | 3303.ua'},
 {name: 'og:title', property: 'og:title', content: 'Різноманітний вибір освіжаючих напоїв | 3303.ua'},
 {name: 'twitter:title', content: 'Різноманітний вибір освіжаючих напоїв | 3303.ua'},
 {
    property: 'og:description',
    content: 'Широкий асортимент освіжаючих напоїв, включаючи газовані, негазовані та безалкогольні варіанти, які відмінно доповнять ваші замовлені страви - на сторінці "Напої" 3303.ua.'
  },
 {
    name: 'description',
    content: 'Широкий асортимент освіжаючих напоїв, включаючи газовані, негазовані та безалкогольні варіанти, які відмінно доповнять ваші замовлені страви - на сторінці "Напої" 3303.ua.'
  },
 {
    name: 'twitter:description',
    content: 'Широкий асортимент освіжаючих напоїв, включаючи газовані, негазовані та безалкогольні варіанти, які відмінно доповнять ваші замовлені страви - на сторінці "Напої" 3303.ua.'
  },
 {
    name: 'keywords',
    content: 'Напої, безалкогольні напої, роли, локшина, доставка їжі, доставка японської їжі, доставка суші, доставка ролів, доставка суші Запоріжжя, доставка суші Дніпро, доставка суші Івано-Франківськ, доставка суші Хмельницький, доставка суші Суми, безкоштовна доставка, безкоштовна доставка суші, безкоштовна доставка ролів, найкращі суші, смачні суші, найкращі роли, смачні роли, замовити суші, замовити роли, замовити доставку'
  },
];

export  const rollChef: MetaDefinition[] = [
{property: 'og:url', content: 'https://3303.ua/home/roll-chef'},
{name: 'twitter:url', content: 'https://3303.ua/home/roll-chef'},
{name: 'title', content: 'Роли від шеф-кухаря: смаковиті комбінації на 3303.ua'},
{name: 'og:title', property: 'og:title', content: 'Роли від шеф-кухаря: смаковиті комбінації на 3303.ua'},
{name: 'twitter:title', content: 'Роли від шеф-кухаря: смаковиті комбінації на 3303.ua'},
{
    property: 'og:description',
    content: 'Дуже смачні та вишукані роли від нашого шеф-кухаря: розкрийте яскравий смак японської кухні з неперевершеними ролами Суші 3303, доступними для доставки по всьому місту.'
  },
{
    name: 'description',
    content: 'Дуже смачні та вишукані роли від нашого шеф-кухаря: розкрийте яскравий смак японської кухні з неперевершеними ролами Суші 3303, доступними для доставки по всьому місту.'
  },
{
    name: 'twitter:description',
    content: 'Дуже смачні та вишукані роли від нашого шеф-кухаря: розкрийте яскравий смак японської кухні з неперевершеними ролами Суші 3303, доступними для доставки по всьому місту.'
  },
{
    name: 'keywords',
    content: 'Суші, преміум роли, роли, локшина, доставка їжі, доставка японської їжі, доставка суші, доставка ролів, доставка суші Запоріжжя, доставка суші Дніпро, доставка суші Івано-Франківськ, доставка суші Хмельницький, доставка суші Суми, безкоштовна доставка, безкоштовна доставка суші, безкоштовна доставка ролів, найкращі суші, смачні суші, найкращі роли, смачні роли, замовити суші, замовити роли, замовити доставку'
  },
];

export  const philadelphia: MetaDefinition[] = [
  {property: 'og:url', content: 'https://3303.ua/home/philadelphia'},
  {name: 'twitter:url', content: 'https://3303.ua/home/philadelphia'},
  {name: 'title', content: 'Роли Філадельфія – Класика Японської Кухні | 3303.ua'},
  {name: 'og:title', property: 'og:title', content: 'Роли Філадельфія – Класика Японської Кухні | 3303.ua'},
  {name: 'twitter:title', content: 'Роли Філадельфія – Класика Японської Кухні | 3303.ua'},
  {
    property: 'og:description',
    content: 'Замовляйте найпопулярніші роли Філадельфія на сайті 3303.ua! Свіжий лосось, ніжний крем-сир і традиційний рис – ідеальний вибір для справжніх гурманів.'
  },
  {
    name: 'description',
    content: 'Замовляйте найпопулярніші роли Філадельфія на сайті 3303.ua! Свіжий лосось, ніжний крем-сир і традиційний рис – ідеальний вибір для справжніх гурманів.'
  },
  {
    name: 'twitter:description',
    content: 'Замовляйте найпопулярніші роли Філадельфія на сайті 3303.ua! Свіжий лосось, ніжний крем-сир і традиційний рис – ідеальний вибір для справжніх гурманів.'
  },
  {
    name: 'keywords',
    content: 'Суші, преміум роли, роли, локшина, доставка їжі, доставка японської їжі, доставка суші, доставка ролів, доставка суші Запоріжжя, доставка суші Дніпро, доставка суші Івано-Франківськ, доставка суші Хмельницький, доставка суші Суми, безкоштовна доставка, безкоштовна доставка суші, безкоштовна доставка ролів, найкращі суші, смачні суші, найкращі роли, смачні роли, замовити суші, замовити роли, замовити доставку'
  },
];

export  const additionally: MetaDefinition[] = [
{property: 'og:url', content: 'https://3303.ua/home/additionally'},
{name: 'twitter:url', content: 'https://3303.ua/home/additionally'},
{name: 'title', content: 'Додаткові товари до вашого замовленная на 3303.ua'},
{name: 'og:title', property: 'og:title', content: 'Додаткові товари до вашого замовленная на 3303.ua'},
{name: 'twitter:title', content: 'Додаткові товари до вашого замовленная на 3303.ua'},
{
    property: 'og:description',
    content: 'Сторінка "Додаткові товари" на сайті 3303.ua рекомендує вам товари, які можна замовити разом із вишуканими ролами та вдосконалити і покращити ваші смакові забаганки.'
  },
{
    name: 'description',
    content: 'Сторінка "Додаткові товари" на сайті 3303.ua рекомендує вам товари, які можна замовити разом із вишуканими ролами та вдосконалити і покращити ваші смакові забаганки.'
  },
{
    name: 'twitter:description',
    content: 'Сторінка "Додаткові товари" на сайті 3303.ua рекомендує вам товари, які можна замовити разом із вишуканими ролами та вдосконалити і покращити ваші смакові забаганки.'
  },
{
    name: 'keywords',
    content: 'Суші, преміум роли, роли, локшина, доставка їжі, доставка японської їжі, доставка суші, доставка ролів, доставка суші Запоріжжя, доставка суші Дніпро, доставка суші Івано-Франківськ, доставка суші Хмельницький, доставка суші Суми, безкоштовна доставка, безкоштовна доставка суші, безкоштовна доставка ролів, найкращі суші, смачні суші, найкращі роли, смачні роли, замовити суші, замовити роли, замовити доставку'
  },
];
export  const contract: MetaDefinition[] = [
{property: 'og:url', content: 'https://3303.ua/contract'},
{name: 'twitter:url', content: 'https://3303.ua/contract'},
{name: 'title', content: 'Швидке та зручне оформлення договору на 3303.ua'},
{name: 'og:title', property: 'og:title', content: 'Швидке та зручне оформлення договору на 3303.ua'},
{name: 'twitter:title', content: 'Швидке та зручне оформлення договору на 3303.ua'},
{
    property: 'og:description',
    content: 'Сторінка нашого сайту містить унікальний контракт з інформацією про неозначені послуги, що надаються компанією Суші 3303, включаючи вартість, деталі та тривалість контракту.'
  },
{
    name: 'description',
    content: 'Сторінка нашого сайту містить унікальний контракт з інформацією про неозначені послуги, що надаються компанією Суші 3303, включаючи вартість, деталі та тривалість контракту.'
  },
{
    name: 'twitter:description',
    content: 'Сторінка нашого сайту містить унікальний контракт з інформацією про неозначені послуги, що надаються компанією Суші 3303, включаючи вартість, деталі та тривалість контракту.'
  },
{
    name: 'keywords',
    content: 'Суші, преміум роли, роли, локшина, доставка їжі, доставка японської їжі, доставка суші, доставка ролів, доставка суші Запоріжжя, доставка суші Дніпро, доставка суші Івано-Франківськ, доставка суші Хмельницький, доставка суші Суми, безкоштовна доставка, безкоштовна доставка суші, безкоштовна доставка ролів, найкращі суші, смачні суші, найкращі роли, смачні роли, замовити суші, замовити роли, замовити доставку'
  },
]
export  const contacts: MetaDefinition[] = [
{property: 'og:url', content: 'https://3303.ua/contacts'},
{name: 'twitter:url', content: 'https://3303.ua/contacts'},
{name: 'title', content: 'Контакти | Суші 3303 | Доставка японської кухні'},
{name: 'og:title', property: 'og:title', content: 'Контакти | Суші 3303 | Доставка японської кухні'},
{name: 'twitter:title', content: 'Контакти | Суші 3303 | Доставка японської кухні'},
{
    property: 'og:description',
    content: 'Контакти Суші 3303: зв\'яжіться з нами зараз, щоб замовити свою улюблену страву японської кухні та насолодитися швидкою і надійною доставкою прямо до вашого порогу.'
  },
{
    name: 'description',
    content: 'Контакти Суші 3303: зв\'яжіться з нами зараз, щоб замовити свою улюблену страву японської кухні та насолодитися швидкою і надійною доставкою прямо до вашого порогу.'
  },
{
    name: 'twitter:description',
    content: 'Контакти Суші 3303: зв\'яжіться з нами зараз, щоб замовити свою улюблену страву японської кухні та насолодитися швидкою і надійною доставкою прямо до вашого порогу.'
  },
{
    name: 'keywords',
    content: 'Суші, преміум роли, роли, локшина, доставка їжі, доставка японської їжі, доставка суші, доставка ролів, доставка суші Запоріжжя, доставка суші Дніпро, доставка суші Івано-Франківськ, доставка суші Хмельницький, доставка суші Суми, безкоштовна доставка, безкоштовна доставка суші, безкоштовна доставка ролів, найкращі суші, смачні суші, найкращі роли, смачні роли, замовити суші, замовити роли, замовити доставку'
  },
];
export  const delivery: MetaDefinition[] = [
{property: 'og:url', content: 'https://3303.ua/delivery'},
{name: 'twitter:url', content: 'https://3303.ua/delivery'},
{name: 'title', content: 'Служба доставки 3303: Найшвидша доставка у місті'},
{name: 'og:title', property: 'og:title', content: 'Служба доставки 3303: Найшвидша доставка у місті'},
{name: 'twitter:title', content: 'Служба доставки 3303: Найшвидша доставка у місті'},
{
    property: 'og:description',
    content: 'На сайті 3303.ua ви знайдете найкращу доставку продуктів харчування зі швидкістю, надійністю та перевіреним сервісом - замовляйте онлайн легко та зручно прямо зараз!'
  },
{
    name: 'description',
    content: 'На сайті 3303.ua ви знайдете найкращу доставку продуктів харчування зі швидкістю, надійністю та перевіреним сервісом - замовляйте онлайн легко та зручно прямо зараз!'
  },
{
    name: 'twitter:description',
    content: 'На сайті 3303.ua ви знайдете найкращу доставку продуктів харчування зі швидкістю, надійністю та перевіреним сервісом - замовляйте онлайн легко та зручно прямо зараз!'
  },
{
    name: 'keywords',
    content: 'Суші, преміум роли, роли, локшина, доставка їжі, доставка японської їжі, доставка суші, доставка ролів, доставка суші Запоріжжя, доставка суші Дніпро, доставка суші Івано-Франківськ, доставка суші Хмельницький, доставка суші Суми, безкоштовна доставка, безкоштовна доставка суші, безкоштовна доставка ролів, найкращі суші, смачні суші, найкращі роли, смачні роли, замовити суші, замовити роли, замовити доставку'
  },
];
export  const allergens: MetaDefinition[] = [
{property: 'og:url', content: 'https://3303.ua/allergens'},
{name: 'twitter:url', content: 'https://3303.ua/allergens'},
{name: 'title', content: 'Перелік можливих алергенів всієї продукції Суші 3303'},
{name: 'og:title', property: 'og:title', content: 'Перелік можливих алергенів всієї продукції Суші 3303'},
{name: 'twitter:title', content: 'Перелік можливих алергенів всієї продукції Суші 3303'},
{
    property: 'og:description',
    content: 'Дізнайтесь про алергени, звернувшись до нашої інформаційної сторінки, де представлена актуальна інформація щодо вмісту можливих алергенів в продукції Суші 3303.ua.'
  },
{
    name: 'description',
    content: 'Дізнайтесь про алергени, звернувшись до нашої інформаційної сторінки, де представлена актуальна інформація щодо вмісту можливих алергенів в продукції Суші 3303.ua.'
  },
{
    name: 'twitter:description',
    content: 'Дізнайтесь про алергени, звернувшись до нашої інформаційної сторінки, де представлена актуальна інформація щодо вмісту можливих алергенів в продукції Суші 3303.ua.'
  },
{
    name: 'keywords',
    content: 'Суші, преміум роли, роли, локшина, доставка їжі, доставка японської їжі, доставка суші, доставка ролів, доставка суші Запоріжжя, доставка суші Дніпро, доставка суші Івано-Франківськ, доставка суші Хмельницький, доставка суші Суми, безкоштовна доставка, безкоштовна доставка суші, безкоштовна доставка ролів, найкращі суші, смачні суші, найкращі роли, смачні роли, замовити суші, замовити роли, замовити доставку'
  },
];
export  const about: MetaDefinition[] = [
{property: 'og:url', content: 'https://3303.ua/about'},
{name: 'twitter:url', content: 'https://3303.ua/about'},
{name: 'title', content: 'Суші 3303 | Японська кухня | Інформація про нас '},
{name: 'og:title', property: 'og:title', content: 'Суші 3303 | Японська кухня | Інформація про нас'},
{name: 'twitter:title', content: 'Суші 3303 | Японська кухня | Інформація про нас '},
{
    property: 'og:description',
    content: 'Дізнавайтесь більше про нас – наші неперевершені суші, широкий вибір японської кухні та напоїв, а також швидка доставка по всьому місту прямо до Вас від Суші 3303.'
  },
{
    name: 'description',
    content: 'Дізнавайтесь більше про нас – наші неперевершені суші, широкий вибір японської кухні та напоїв, а також швидка доставка по всьому місту прямо до Вас від Суші 3303.'
  },
{
    name: 'twitter:description',
    content: 'Дізнавайтесь більше про нас – наші неперевершені суші, широкий вибір японської кухні та напоїв, а також швидка доставка по всьому місту прямо до Вас від Суші 3303.'
  },
{
    name: 'keywords',
    content: 'Суші, преміум роли, роли, локшина, доставка їжі, доставка японської їжі, доставка суші, доставка ролів, доставка суші Запоріжжя, доставка суші Дніпро, доставка суші Івано-Франківськ, доставка суші Хмельницький, доставка суші Суми, безкоштовна доставка, безкоштовна доставка суші, безкоштовна доставка ролів, найкращі суші, смачні суші, найкращі роли, смачні роли, замовити суші, замовити роли, замовити доставку'
  },
];
export  const bonus: MetaDefinition[] = [
{property: 'og:url', content: 'https://3303.ua/home/bonus'},
{name: 'twitter:url', content: 'https://3303.ua/home/bonus'},
{name: 'title', content: 'Бонусна програма від суші 3303: Отримуйте подарунки'},
{name: 'og:title', property: 'og:title', content: 'Бонусна програма від суші 3303: Отримуйте подарунки'},
{name: 'twitter:title', content: 'Бонусна програма від суші 3303: Отримуйте подарунки'},
{
    property: 'og:description',
    content: 'На сторінці "Бонуси" сайту 3303.ua зібрана детальна інформація про вигідні пропозиції та переваги бонусної програми суші 3303, яка підкреслить ваш комфорт та вигоду.'
  },
{
    name: 'description',
    content: 'На сторінці "Бонуси" сайту 3303.ua зібрана детальна інформація про вигідні пропозиції та переваги бонусної програми суші 3303, яка підкреслить ваш комфорт та вигоду.'
  },
{
    name: 'twitter:description',
    content: 'На сторінці "Бонуси" сайту 3303.ua зібрана детальна інформація про вигідні пропозиції та переваги бонусної програми суші 3303, яка підкреслить ваш комфорт та вигоду.'
  },
{
    name: 'keywords',
    content: 'Суші, преміум роли, роли, локшина, доставка їжі, доставка японської їжі, доставка суші, доставка ролів, доставка суші Запоріжжя, доставка суші Дніпро, доставка суші Івано-Франківськ, доставка суші Хмельницький, доставка суші Суми, безкоштовна доставка, безкоштовна доставка суші, безкоштовна доставка ролів, найкращі суші, смачні суші, найкращі роли, смачні роли, замовити суші, замовити роли, замовити доставку'
  },
];


