import { Component} from '@angular/core';
import { DevAuthService } from '../dev-auth.service';

@Component({
  selector: 'app-developer-auth',
  templateUrl: './developer-auth.component.html',
  styleUrls: ['./developer-auth.component.scss']
})
export class DeveloperAuthComponent {
  pass = '';
  constructor(protected devAuth: DevAuthService) { }
}
