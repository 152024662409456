import {Injectable} from "@angular/core";
import {HttpClient, HttpParams} from "@angular/common/http";
import {Observable, forkJoin} from "rxjs";
import {InformationInterface} from "../interfaces/information.interface";
import {AppApiUrls} from "../../../app.api.urls";
import {StorageLogicService} from "../../../shared/utils/services/storage-logic.service";

@Injectable()

export class InformationRestService {

  constructor(private http: HttpClient, public storageService: StorageLogicService) {
  }

  getInfo(pageId?: number): Observable<InformationInterface> {
    let params: HttpParams;
    if (pageId === undefined) {
    params = new HttpParams()
      .set('city_id', this.storageService.getData('city'));
    } else {
      params = new HttpParams()
        .set('city_id', this.storageService.getData('city'))
        .set('id', pageId);
    }
    return this.http.get<InformationInterface>(AppApiUrls.info(), {
      params
    });
  }
}
