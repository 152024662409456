import {domains} from "../../../environments/environment.prod";

export interface Environment{
  server: string;
  domain: string;
  id: string;
}

export function selectDomain(): string {
  const foundDomain = domains.find(item => item.domain === window.location.origin);
  if (!foundDomain) {
    throw new Error("Domain not found");
  }
  return foundDomain.server;
}
