<div class="align">
  <div class="checkout-container">

    <img class="logo" src="/assets/logo.webp" alt="Logo image" loading="lazy" width="100" height="82">

    <p class="text">До оплати {{data?.total}}₴</p>

    <form class="mb-3" ngNoForm method="POST" action="https://www.liqpay.ua/api/3/checkout" accept-charset="utf-8">
      <input type="hidden" name="data" [value]="liqPayData"/>
      <input type="hidden" name="signature" [value]="liqPaySignature"/>
      <input type="image" src="//static.liqpay.ua/buttons/p1ru.radius.png"/>
    </form>
  </div>
</div>
