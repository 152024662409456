<div class="row filter-container">
  <div class="filter-mobile col-3">
    <span class="fw-bold filter-title" translate="FILTER.SEARCH"><span>:</span> </span>
    <div  class="filter-input">
      <input class="search" placeholder="{{'HOME.ENTER_PRODUCT_NAME' | translate}}" type="text"
                  [(ngModel)]="productSearch" (ngModelChange)="productSearchChange.emit($event)">
      <div [ngClass]="{'hidden': !productSearch}" class="ms-2 cursor-pointer">
        <div (click)="filterResetSearch()">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
               class="bi bi-x-circle" viewBox="0 0 16 16">
            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
            <path
              d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
          </svg>
        </div>
      </div>
    </div>
  </div>


  <div class="filter-mobile col-3" *ngIf="!hideFilterIng">
    <span class="fw-bold filter-title" translate="FILTER.INGREDIENTS"><span>:</span> </span>
    <div  class="filter-input">
    <div ngbDropdown class="d-inline-block">
      <button *ngIf="!valueFilterIng" class="search" ngbDropdownToggle translate="FILTER.ALL"></button>
      <button *ngIf="valueFilterIng" class="search" ngbDropdownToggle>{{valueFilterIng}}</button>
      <div class="drop-menu-filter" ngbDropdownMenu aria-labelledby="dropdownBasic1">
        <button *ngFor="let object of filterByIng.data" class="drop-menu-button"
                (click)="selectMarkFilter('ing', object.id.toString(), object.name)" ngbDropdownItem>
          <div class="d-flex justify-content-sm-between mx-4 align-items-center">
            <span class="ms-2">{{object.name}}</span>
            <div dimensions class="marking-filter ms-2"> <img  [attr.data-src]="object.img" alt="" loading="lazy" class="w-100 object-fit-contain"></div>
           
          </div>
        </button>
      </div>
    </div>
    <div [ngClass]="{'hidden': !filterIng}"class="ms-2 cursor-pointer">
      <div (click)="filterResetIng()">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
            class="bi bi-x-circle" viewBox="0 0 16 16">
          <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
          <path
            d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
        </svg>
      </div>
    </div>
  </div>
  </div>
  <div class="filter-mobile col-3" *ngIf="!hideFilterMark">
    <span class="fw-bold filter-title" translate="FILTER.MARKS"><span>:</span></span>
    <div  class="filter-input">
    <div ngbDropdown class="d-inline-block">
      <button *ngIf="!valueFilterMark" class="search" ngbDropdownToggle translate="FILTER.ALL"></button>
      <button *ngIf="valueFilterMark" class="search" ngbDropdownToggle>{{valueFilterMark}}</button>
      <div class="drop-menu-filter" ngbDropdownMenu aria-labelledby="dropdownBasic1">
        <button *ngFor="let object of filterByMark.data" class="drop-menu-button"
                (click)="selectMarkFilter('mark', object.id.toString(), object.name)" ngbDropdownItem>
          <div class="d-flex justify-content-sm-between mx-4 align-items-center" dimensions>
            <span class="ms-2">{{object.name}}</span>
            <div class="marking-filter ms-2">
              <img  [attr.data-src]="object.img" class="w-100 object-fit-content">
            </div>
     
          </div>
        </button>
      </div>
    </div>
    <div [ngClass]="{'hidden': !filter}" class="ms-2 cursor-pointer">
      <div (click)="filterResetMark()">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
            class="bi bi-x-circle" viewBox="0 0 16 16">
          <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
          <path
            d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
        </svg>
      </div>
    </div>
  </div>
  </div>


  <div class="filter-mobile col-3" *ngIf="showFilter">
    <span class="fw-bold filter-title" translate="FILTER.SORT"></span>
    <div class="filter-input">
      <div ngbDropdown class="d-inline-block">
        <button *ngIf="!selectedFilter" class="search" ngbDropdownToggle translate="FILTER.ALL"></button>
        <button *ngIf="selectedFilter" class="search" ngbDropdownToggle>{{ 'FILTER.' + selectedFilter | translate }}
        </button>
        <div class="drop-menu-filter" ngbDropdownMenu aria-labelledby="dropdownBasic1">
          <button *ngFor="let filter of filterTypes" class="drop-menu-button"
                  (click)="sortProducts(filter.type)" ngbDropdownItem>
            <div class="d-flex justify-content-sm-between mx-4 align-items-center" dimensions>
              <span class="ms-2">{{ 'FILTER.' + filter.type| translate }}</span>
              <img width="20" class="ms-2" [src]="filter.img" alt="" [style]="shouldRotate(filter.type)">
            </div>
          </button>
        </div>
      </div>
          <div [ngClass]="{'hidden': !selectedFilter}" class="ms-2 cursor-pointer">
            <div (click)="sortProducts(FilterType.random)">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                  class="bi bi-x-circle" viewBox="0 0 16 16">
                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                <path
                  d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
              </svg>
            </div>
          </div>
    </div>
</div>


</div>
