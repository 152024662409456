import {AfterViewInit, ChangeDetectorRef, Component, ElementRef, Renderer2, ViewChild} from '@angular/core';
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {pluck, switchMap, tap} from "rxjs";
import {FormArray, FormBuilder, FormGroup} from "@angular/forms";
import {Set} from "../interfaces/set-constructor.interface";
import {Unsubscribe} from "../../../unsubscribe";
import {FilterByIngInterface, FilterByMarksInterface} from "../../home/interfaces/filterByIng.interface";
import {CartRestService} from "../../../shell/components/navbar/cart/services/cart-rest.service";
import {Instagram, ProductData} from "../../home/interfaces/home.interface";
import {ProductDataAdapter} from "../../../shared/utils/poduct-data-adapter";
import {StorageLogicService} from "../../../shared/utils/services/storage-logic.service";
import { CartLogicService } from 'src/app/shell/components/navbar/cart/services/cart-logic.service';
import {CountryCityNotificationService} from 'src/app/shared/utils/services/country-city-notification.service';
import {Router} from '@angular/router';
import {ThemeLogicService} from "../../../shared/theme/services/theme-logic.service";

@Component({
  selector: 'app-set-constructor',
  templateUrl: './set-constructor.component.html',
  styleUrls: ['./set-constructor.component.scss']
})
export class SetConstructorComponent extends Unsubscribe implements AfterViewInit {
  readonly minProductsInSet = 5;
  products: ProductData[] = [];
  set: Set[] = [];
  instagram: Instagram[] = [];
  loader = true;
  static defaultImageUrl = '../../../assets/set-constructor.png';
  setImage: string[] = [];
  productSearch = '';
  filter = '';
  filterIng = '';
  valueFilterIng = '';
  valueFilterMark = '';
  showProducts = false;
  isOpenSaveSetModal: boolean = false;
  filterByIng: FilterByIngInterface = {}
  filterByMark: FilterByMarksInterface = {}
  pieces = 0;
  price = 0;
  oldPrice = 0;
  weight = 0;


  form!: FormGroup;

  @ViewChild('setConstructorModal') setConstructorModal!: ElementRef;
  @ViewChild('productImageView') productImageView: ElementRef<HTMLDivElement> | null = null;

  constructor(
    private modalService: NgbModal,
    private fb: FormBuilder,
    private cartRestService: CartRestService,
    private productDataAdapter: ProductDataAdapter,
    private storageService: StorageLogicService,
    private cartService: CartLogicService,
    private cdr: ChangeDetectorRef,
    private renderer: Renderer2,
    public countryCityService: CountryCityNotificationService,
    private themeLogicService: ThemeLogicService,
    private router: Router) {
      super();
  }

  ngAfterViewInit(): void {
    this.getAvailableProducts()
    .pipe(tap((setData) => {
      if (this.cartService.cartProducts.id === 0) {
        this.storageService.setData('cart', setData.cart_id);
        this.cartService.cartUpdate();
      }
    })).subscribe();
  }

  filterReset(): void {
    this.productSearch = '';
    this.filter = '';
    this.filterIng = '';
    this.valueFilterMark = '';
    this.valueFilterIng = '';
  }

  getAvailableProducts() {
    return this.cartRestService.getSet(this.cartService.cartProducts.id.toString())
    .pipe(pluck('data'),
    tap((setData) => {
      this.filterReset();
      this.products = setData.available;
      this.set = setData.set;
      this.loader = false;
      this.instagram = setData.instagram;
      this.cdr.detectChanges();
      this.form = this.fb.group({
        weight: this.fb.array(this.products.map((item) => this.generateFormGroupElement(item)))
      });
      this.pieces = this.set.reduce((total: number, product) => total + product.pieces, 0);
      this.price = this.set.reduce((total: number, product) => total + product.price, 0);
      this.oldPrice = this.set.reduce((total: number, product) => total + product.old_price, 0);
      this.weight = this.set.reduce((total: number, product) => {
        const weightValue = parseFloat(product.weight.replace('г', ''));
        total += weightValue;
        return total;
      }, 0);

    }),
    tap(() => this.generateSetConstructorPreview(this.set.length)));
  }

  generateFormGroupElement(item: ProductData): FormGroup {
    return this.productDataAdapter.adaptProductData(item);
  }

  get formArrayElement(): FormArray {
    return this.form.get('weight') as FormArray;
  }

  private clearPreviewImgContainer(container?: HTMLDivElement) {
    if (!container) {
      return;
    }
    const children = Array.from(container.childNodes);
    children.forEach(elem => this.renderer.removeChild(container, elem));
  }

  generateSetConstructorPreview(count: number) {
    const container = this.productImageView?.nativeElement;
    this.clearPreviewImgContainer(container);
    if (!count) {
      const imageView = this.renderer.createElement('img');
      this.renderer.setAttribute(imageView, 'src', SetConstructorComponent.defaultImageUrl);
      this.renderer.setStyle(imageView, 'width', '100%');
      this.renderer.appendChild(container, imageView);
      this.setImage = [imageView];
      return;
    }

    const rows = count > this.minProductsInSet ? 2 : 1;
    const preview = [];

    for (let i = 0; i < count; i++) {
      const imageView = this.renderer.createElement('img');
      const firstRow = (Math.floor(i / (count / rows)) + 1) % 2;
      const elementOrder = Math.floor(i % (count / rows));
      this.renderer.setAttribute(imageView, 'src', this.set[i].thumbnail);
      this.renderer.setStyle(imageView, 'width', '100%');
      this.renderer.setStyle(imageView, 'gridRow', `${firstRow ? '1 / 21' : '10 / 30'}`);

      const gridColumnStart = !elementOrder ? (firstRow ? 4 : 1) : elementOrder * 3 + (firstRow ? 4 : 1);
      const gridColumnEnd = !elementOrder ? (firstRow ? 9 : 6) : elementOrder * 3 + (firstRow ? 9 : 6);
      this.renderer.setStyle(imageView, 'gridColumn', `${gridColumnStart} / ${gridColumnEnd}`);
      preview.push(imageView);
    }
    this.setImage = [];
    preview.forEach(element => {
      this.renderer.appendChild(container, element);
      this.setImage.push(element);
    });
  }

  addToSet(index: number): void {
    this.cartService.addToSet(index)
    .pipe(switchMap(() => {
      this.showProducts = false;
      return this.getAvailableProducts();
    })).subscribe() ;
  }

  removeFromSet(id: number): void {
    this.cartService.deleteFromSet(id)
    .pipe(switchMap(() => {
      return this.getAvailableProducts();
    })).subscribe();
  }

  deleteSet(): void {
    this.cartRestService.deleteSet(this.cartService.cartProducts.id.toString())
    .pipe(switchMap(() => this.getAvailableProducts()))
    .subscribe();
  }

  goToProducts() {
    this.showProducts = true;
  }

  returnFromProducts() {
    this.showProducts = false;
    this.cdr.detectChanges();
    const container = this.productImageView?.nativeElement;
    this.clearPreviewImgContainer(container);
    this.setImage.forEach(elem => this.renderer.appendChild(container, elem));
  }

  closeModal() {
    this.cartService.cartUpdate();
    this.modalService.dismissAll();
    this.router.navigate(['/home']);
  }

  closeSetConstructor(content?: any) {
    if (content && this.set.length >= this.minProductsInSet) {
      this.isOpenSaveSetModal = true;
      this.modalService.open(content, {size: 'sm', keyboard: false, backdrop: 'static', windowClass: 'custom-modal-sm'});
    } else {
      this.deleteSet();
      this.closeModal();
    }
  }

   srcPieceImage() {
    if (this.themeLogicService.isDarkTheme) {
      return 'assets/piece-white.png'
    } else {
      return 'assets/piece.png'
    }
   }

  openInst(link: string): void {
    window.open(link, '_blank');
  }
}
