<nav class="navbar">

  <img class="navbar-img" [src]="!isDarkTheme ? '/assets/logo.webp' : '/assets/reviews/logo_dark.svg'" alt="Animated wave shape" loading="lazy">

  <div>
    <span class="navbar-header d-block ps-2" translate="REVIEWS.YOUR_FEEDBACK_IS_GREAT"></span>
    <span class="navbar-header d-block ps-2" translate="REVIEWS.IMPORTANT_FOR_US"></span>
  </div>
</nav>
<main class="body">

  <div class="text-center p-4">
  <span class="d-block body-header" translate="REVIEWS.CHOOSE_THE_MOST_BELOW"></span>
  <span class="d-block body-header" translate="REVIEWS.A_CONVENIENT_OPTION"></span>
  </div>

  <img class="body-img-left" src="/assets/reviews/left.webp" alt="Left sushi image" loading="lazy">

  <div class="body-icon">

  <div *ngFor="let object of channelsData?.data?.channels" class="d-flex flex-column align-items-center pt-5">
    <a [href]="getLink(object)">
    <img class="body-img-icon" src="/assets/reviews/{{object.channel.img_link}}" alt="Feviews image" loading="lazy">
    </a>
    <span class="body-icon-header">{{object.channel.name}}</span>

  </div>

  </div>

  <img class="body-img-right" src="/assets/reviews/right.webp" alt="Right sushi image" loading="lazy">

</main>
