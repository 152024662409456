import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from '../components/home.component';
import { HomeRestService } from '../services/home-rest.service';
import { HomeResolver } from '../resolvers/home.resolver';
import { SearchPipe } from '../pipes/search.pipe';
import { MarkPipe } from '../pipes/mark.pipe';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  NgbDropdownModule,
  NgbToastModule,
  NgbTooltipModule,
} from '@ng-bootstrap/ng-bootstrap';
import { IngPipe } from '../pipes/ing.pipe';
import { ShareButtonsModule } from 'ngx-sharebuttons/buttons';
import { ShareIconsModule } from 'ngx-sharebuttons/icons';
import { TranslateModule } from '@ngx-translate/core';
import { NavbarRestService } from '../../../shell/components/navbar/services/navbar-rest.service';
import { Shell } from '../../../shell/shell.service';
import { SetConstructorComponent } from '../../set-constructor/components/set-constructor.component';
import { FilterComponent } from '../../../shared/filter/components/filter.component';
import { ProductCardModule } from '../../../shared/product-card/product-card.module';
import { DimensionsModule } from '../../../shared/directives/dimensions.module';
import { ProductItemModule } from 'src/app/shared/product-item/product-item.module';

const routes: Routes = Shell.childRoutes([
  {
    path: '',
    component: HomeComponent,
    resolve: {
      products: HomeResolver,
    },
    runGuardsAndResolvers: 'always',
    children: [
      {
        path: ':id',
        component: HomeComponent,
      },
    ],
  },
]);

@NgModule({
  declarations: [
    HomeComponent,
    SearchPipe,
    MarkPipe,
    IngPipe,
    SetConstructorComponent,
    FilterComponent,
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    FormsModule,
    NgbDropdownModule,
    ReactiveFormsModule,
    NgbTooltipModule,
    ShareButtonsModule.withConfig({
      debug: true,
    }),
    ShareIconsModule,
    TranslateModule,
    NgbToastModule,
    ProductCardModule,
    DimensionsModule,
    ProductItemModule,
  ],
  exports: [
    SetConstructorComponent,
    HomeComponent,
    SearchPipe,
    MarkPipe,
    IngPipe,
    FilterComponent
  ],
  providers: [NavbarRestService, HomeRestService],
})
export class HomeModule {}
