import { NgbToastModule } from "@ng-bootstrap/ng-bootstrap";
import { ToastService } from "./shared/utils/services/toast.service";
import { NgTemplateOutlet, NgFor } from "@angular/common";
import { Component, inject } from "@angular/core";
import { TranslateModule, TranslatePipe } from "@ngx-translate/core";



@Component({
	selector: 'app-toasts',
	standalone: true,
	imports: [NgbToastModule, NgTemplateOutlet, NgFor, TranslateModule],
	template: `
		 <div *ngFor = "let toast of toastService.toasts; track toast"> 
			<ngb-toast
				[class]="toast.classname"
				[autohide]="true"
				[delay]="toast.delay || 5000"
				(hidden)="toastService.remove(toast)"
			>
				<ng-template [ngTemplateOutlet]="toast.template">{{toast.body | translate}}</ng-template>
			</ngb-toast>
		</div>
	`,
	host: { class: 'toast-container position-fixed top-0 end-0 p-3', style:"z-index: 1200; position: relative; top: 0; end: 0;" },
})
export class ToastsContainer {
	toastService = inject(ToastService);
}