import {StorageLogicService} from "./services/storage-logic.service";
import {CountriesData, Langs} from "../../shell/components/navbar/interfaces/navbar.interface";

export function detectLang(): string {
  const storageService = new StorageLogicService()
  const country = storageService.getData('country');
  const lang = storageService.getData('lang');
  const browserLang = (navigator.language === 'uk' || 'uk-UA') ? Langs.ua : navigator.language;


  if(!country && !lang) {
    return Object.values(Langs).find((language) => language === browserLang) || Langs.en;
  }

  if (country && !lang) {
    const parsedCountry: CountriesData = country;
    const foundLanguage = parsedCountry.languages[0];

    if (foundLanguage) {
      storageService.setData('lang', foundLanguage.code);
      return foundLanguage.code;
    }
  }

  return lang || Langs.en;
}
