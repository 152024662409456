import { Injectable } from '@angular/core';
import {
  AddressData,
  AuthStatus,
  Balance,
  ProfileData,
  Addresses,
  UserInfo,
} from '../interfaces/profile.interface';
import { StorageLogicService } from 'src/app/shared/utils/services/storage-logic.service';
import { BehaviorSubject, Observable, forkJoin, pluck, switchMap, take, tap } from 'rxjs';
import { ProfileRestService } from './profile-rest.service';
import { HistoryData } from '../interfaces/history.interface';
import { GoogleAuthService } from '../../../shell/auth/google-auth.service';

@Injectable({
  providedIn: 'root',
})
export class ProfileDataservice {
  constructor(
    private storageService: StorageLogicService,
    private profileService: ProfileRestService,
    private googleAuthService: GoogleAuthService
  ) {
    this.initService();
  }

  authStatus = AuthStatus.false;
  userSubject: Observable<UserInfo | null> = this.googleAuthService.userSubject;

  Authorization: ProfileData | undefined =
    this.storageService.getData('Authorization');
  authDataStream = new BehaviorSubject<ProfileData | undefined>(this.storageService.getData('Authorization'));
  
  bonusesBalance: Balance | null = null;
  addresses: AddressData | null = null;
  history: HistoryData[] | null = null;

  isAuthorizedStream = new BehaviorSubject<boolean>(!!this.Authorization);

  private isPhoneVerified = new BehaviorSubject<boolean>(!!this.storageService.getData('Authorization')?.phone);

  get authPhoneStatus(){
    return this.isPhoneVerified.asObservable();
  }

  initService() {
    if (this.Authorization?.pub_id) {
      forkJoin([
        this.retrieveUserInformation(),
        this.retrieveUserAddresses(),
        this.retrieveBonusBalance(),
        this.retrieveUserHistory(),
      ]).subscribe();
    }
  }

  retrieveBonusBalance() {
    return this.profileService
      .getBalance()
      .pipe(tap((response) => (this.bonusesBalance = response.data)));
  }

  retrieveUserAddresses() {
    return this.profileService.getAddresses().pipe(
      pluck('data'),
      tap((response) => (this.addresses = response))
    );
  }

  retrieveUserHistory() {
    return this.profileService
      .getUserHistory()
      .pipe(tap((response) => (this.history = response.data)));
  }

  retrieveUserInformation() {
    return this.profileService.getInfo().pipe(
      tap((userInfo) => {
        this.Authorization = userInfo.data;
        this.authStatus = AuthStatus.true;
        this.storageService.setData('Authorization', userInfo.data);
      })
    );
  }

  signIn(recaptchaToken?: string | null) {
    this.googleAuthService.getAuthCode();
    this.authStatus = AuthStatus.false;
    return this.userSubject.pipe(
      take(1),
      switchMap((googleUserData) => {
        const authData = {
          city_id: this.storageService.getData('city'),
          url_img: googleUserData?.picture,
          name: googleUserData?.name,
          email: googleUserData?.email,
          ...(recaptchaToken?{ recaptchaToken } : {})
        };
        return this.profileService.auth(authData);
      }),
      tap((authData) => {
        this.Authorization = authData.data;
        this.authDataStream.next(authData.data);
        this.storageService.setData('Authorization', authData.data);
        this.authStatus = AuthStatus.true;
        this.isAuthorizedStream.next(true);
        this.isPhoneVerified.next(!!authData.data.phone);
      }),
      switchMap(() => {
        return forkJoin([
          this.retrieveUserAddresses(),
          this.retrieveUserHistory(),
          this.retrieveBonusBalance(),
        ]);
      })
    );
  }

  signOut() {
    this.googleAuthService.signOut();
    this.authStatus = AuthStatus.false;
    this.history = null;
    this.addresses = null;
    this.Authorization = undefined;
    this.isAuthorizedStream.next(false);
  }

  deleteAddress(id: number) {
    this.profileService
      .deleteAddress(id)
      .subscribe((addressData) => (this.addresses = addressData.data));
  }

  editAddress(formValue: Addresses) {
    const filteredAddress = {
      ...formValue,
      attic: formValue.attic ?? '',
      floor: formValue.floor ?? '',
      apt: formValue.apt ?? '',
    };
    return this.profileService
      .editAddress(filteredAddress)
      .pipe(
        tap((addressesResponse) => (this.addresses = addressesResponse.data))
      );
  }

  selectAddress(id: number) {
    this.profileService
      .selectAddress(id)
      .subscribe((addressData) => (this.addresses = addressData.data));
  }

  addAddress(formValue: Addresses) {
    const filteredAddress = {
      ...formValue,
      attic: formValue.attic ?? '',
      floor: formValue.floor ?? '',
      apt: formValue.apt ?? '',
    };
    return this.profileService
      .createAddresses(filteredAddress)
      .pipe(
        tap((addressesResponse) => (this.addresses = addressesResponse.data))
      );
  }

  updateAuthImage() {}

  updatePhoneVerificationStatus(value:boolean){
    this.isPhoneVerified.next(value);
  }
}
