<div class="container d-flex justify-content-center align-items-center">
  <div class="error-content d-flex flex-column align-items-center">
    <h2 class="error-content__title" translate="ERROR.TITLE"></h2>
    <h3 class="error-content__status" translate="ERROR.STATUS"></h3>
    <p class="error-content__message" translate="ERROR.MESSAGE"></p>
    <button class="btn error-content__button" (click)="redirectToMainPage()" translate="NOT_FOUND.HOME"></button>
  </div>
  <img class="d-block error-img" src="../../../assets/error.png" alt="Error" loading="lazy">
</div>

