import { Routes } from '@angular/router';
import {ShellComponent} from "./components/shell/shell.component";


export class Shell {
  static childRoutes(routes: Routes): Routes {
    return [{
      path: '',
      component: ShellComponent,
      children: routes,
    }];
  }
}
