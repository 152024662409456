import { inject, Injectable } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';

import { sentryReplay } from 'src/main';
import { sentryConfig } from 'src/environments/sentry-config';

@Injectable({
  providedIn: 'root',
})
export class SentryReplayService {
  private readonly router = inject(Router);
  private readonly replay = sentryReplay;
  private readonly targetRoutes: (string | RegExp)[] = sentryConfig.targetRoutesForReplay;

  public monitorReplayOnRoute(): void {
    if (!this.replay || !this.targetRoutes || !this.targetRoutes?.length) return;

    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        const currentRoute = event.urlAfterRedirects;

        if (this.isTargetRoute(currentRoute)) {
          this.replay?.start();
        } else {
          this.replay?.stop();
        }
      });
  }

  private isTargetRoute(route: string): boolean {
    if (!route) return false;

    return this.targetRoutes.some((value) => this.matchesRoute(value, route));
  }

  private matchesRoute(value: string | RegExp, currentRoute: string): boolean {
    if (typeof value === 'string') {
      return currentRoute.includes(value);
    }

    if (value instanceof RegExp) {
      return value.test(currentRoute);
    }

    return false;
  }
}
