import {Component, OnInit} from '@angular/core';
import {ReviewsRestService} from "../services/reviews-rest.service";
import {takeUntil} from "rxjs";
import {ActivatedRoute} from "@angular/router";
import {Channels, ReviewsInterface} from "../interfaces/reviews.interface";
import {TranslateService} from "@ngx-translate/core";
import {Unsubscribe} from "../../../unsubscribe";
import {ReviewPageModule} from "../module/review-page.module";
import {ThemeLogicService} from "../../../shared/theme/services/theme-logic.service";

@Component({
  selector: 'app-review-page',
  templateUrl: './review-page.component.html',
  styleUrls: ['./review-page.component.scss'],
  providers: [ReviewPageModule]
})
export class ReviewPageComponent extends Unsubscribe implements OnInit {

  orderId!: number
  channelsData!: ReviewsInterface

  constructor(
    private service: ReviewsRestService,
    private route: ActivatedRoute,
    private translate: TranslateService,
    private themeLogicService: ThemeLogicService,
  )
  {
    super()
  }

  get isDarkTheme(): boolean {
    return this.themeLogicService.isDarkTheme
  }

  ngOnInit(): void {
    this.getQueryParams()
    this.get()
  }

  getQueryParams(): void {
    this.route.queryParams
      .pipe(takeUntil(this.$destroy))
      .subscribe({
        next: value => {
          this.orderId = value['order_id']
        }
      })
  }

  getLink(channel: Channels): string {
    if (channel.channel.type === 'bot') {
      return channel.link + this.orderId
    } else {
      return channel.link
    }
  }

  get(): void {
    this.service.get(this.orderId)
      .pipe(takeUntil(this.$destroy))
      .subscribe({
        next: value => {
          this.channelsData = value
        }, complete: () => {
          this.translate.use(this.channelsData.data.lang)
        }
      })
  }

}
