import { Injectable } from '@angular/core';
import { StorageLogicService } from './storage-logic.service';
import { HttpClient } from '@angular/common/http';
import { AppApiUrls } from 'src/app/app.api.urls';
import {
  FavoriteProductResp,
  Products,
} from 'src/app/pages/home/interfaces/home.interface';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FavoriteProductsApiService {
  constructor(
    private storageService: StorageLogicService,
    private http: HttpClient
  ) {}

  getFavoriteProducts(): Observable<Products> {
    return this.http.get<Products>(AppApiUrls.favoriteProducts(), {
      params: { city_id: this.storageService.getData('city')! },
    });
  }

  addFavoriteProduct(
    prodId: number
  ): Observable<{ data: FavoriteProductResp; success: boolean }> {
    return this.http.post<{ data: FavoriteProductResp; success: boolean }>(
      AppApiUrls.favoriteProducts(),
      { product_id: prodId }
    );
  }

  unfavoriteProduct(
    prodId: number
  ): Observable<{ data: string; success: boolean }> {
    return this.http.delete<{ data: string; success: boolean }>(
      `${AppApiUrls.favoriteProducts()}/${prodId}`
    );
  }
}
