<div class="container carousel">
  <div class="d-flex justify-content-center align-items-center my-5 carousel__skeleton" *ngIf="!banners?.data">
    <div class="lds-spinner">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>

  <app-carousel
    [banners]="banners?.data"
  ></app-carousel>
</div>
