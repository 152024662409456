<div class="d-flex justify-content-center align-items-center flex-column" *ngIf="cartLogicService.cartProducts.products.length === 0">
  <img style="width: 15rem" alt="Empty cart" src="assets/cart-empty-chef.webp" loading="lazy">
  <span class="my-2" translate="CART.CART_EMPTY"></span>
</div>

<div class="d-flex justify-content-center align-items-center" *ngIf="cartLogicService.cartProducts.products.length">
  <button (click)="deleteCart()" class="btn mt-2 mb-3 clear-card" translate="CART.CART_CLEAR"></button>
</div>

<div class="container" *ngIf="cartLogicService.cartProducts$ | async as products">
  <div class="cart__items">
    <ng-container *ngFor="let object of products">
      <div class="col-12 my-2 d-flex justify-content-center">
        <div class="card-item row">
          <div class="col-12">
            <div class="d-flex justify-content-end mt-2">
              <svg  (click)="deleteAllProduct(object)" xmlns="http://www.w3.org/2000/svg"
                  width="16" height="16" fill="#E12829" class="bi-x-lg item-actions"
                  viewBox="0 0 16 16">
                <path fill-rule="evenodd"
                      d="M13.854 2.146a.5.5 0 0 1 0 .708l-11 11a.5.5 0 0 1-.708-.708l11-11a.5.5 0 0 1 .708 0Z"/>
                <path fill-rule="evenodd"
                      d="M2.146 2.146a.5.5 0 0 0 0 .708l11 11a.5.5 0 0 0 .708-.708l-11-11a.5.5 0 0 0-.708 0Z"/>
              </svg>
            </div>
          </div>
          <div style="height: 3.5rem" class="col-5" dimensions>
            <img *ngIf="object.img" style="object-fit: contain; height: 5rem; width: 5rem;" [attr.data-src]="object.img"
                class="card-img-top mx-auto my-auto d-block" alt="..." loading="lazy" appImgStyle>
            <img *ngIf="!object.img" style="object-fit: contain; height: 5rem; width: 5rem;" src="assets/sushi-like.webp"
                class="card-img-top mx-auto my-auto d-block" alt="..." loading="lazy" appImgStyle>
          </div>
          <div style="height: 3.5rem" class="col-7 my-3">
            <span class="item">{{object.name}}</span>
          </div>
          <ng-container *ngIf="object.regular_price !== object.price">
            <p class="sale-price mb-2">
              <span
                class="sale-price__sum">{{object.regular_price | number: '1.0-2'}} {{countryCityService.selectedCountry.currency}}</span>
            </p>
          </ng-container>
          <div class="col-4 mb-3 d-flex">
            <span  *ngIf="object.id !== 73726678">
            <span class="fw-bold grey-font item-actions" (click)="deleteProduct(object)">-</span>
            <span class="green-font mx-3">{{object.quantity}}</span>
            <span class="fw-bold grey-font item-actions" (click)="addProduct(object)">+</span>
            </span>
          </div>
          <div class="col-8 mb-3 d-flex justify-content-between">
            <span class="grey-font">{{object.weight}}</span>
            <span class="green-font">{{object.price | number: '1.0-2'}} {{countryCityService.selectedCountry.currency}}</span>
          </div>
        </div>
      </div>
    </ng-container>
</div>
  <ng-container *ngIf="cartLogicService.cartProducts.products.length">
    <div class="cart__summary">
      <div class="col-12 d-flex justify-content-between">
        <span class="green-font ms-3" translate="CART.TOTAL"><span class="green-font">:</span></span>
        <span class="grey-font fw-bold me-3">{{cartLogicService.cartTotal| number: '1.0-2'}} {{countryCityService.selectedCountry.currency}}</span>
      </div>
        <div *ngIf="cartLogicService.regularPrice !== 0" class="col-12 d-flex justify-content-between">
          <span class="red-font ms-3" translate="CART.DISCOUNT_AMOUNT"><span class="red-font">: </span></span>
          <span class="red-font me-3">{{cartLogicService.regularPrice| number: '1.0-2'}} {{countryCityService.selectedCountry.currency}}</span>
        </div>
      <div *ngIf="returnBonuses() && profileData.Authorization && countryCityService.cityModule.bonuses?.status === 1" class="col-12 d-flex justify-content-between">
        <span class="green-font ms-3" translate="CART.BONUSES"><span class="green-font">:</span></span>
        <span class="grey-font fw-bold me-3">{{(returnBonuses().bonus_value || 0).toFixed(2)}} {{countryCityService.selectedCountry.currency}}</span>
      </div>

      <div class="container text-center">
        <span class="text-center small-red-font px-3"
              *ngIf="cartLogicService.cartTotal < minPrice && countryCityService.cityModule.pickup?.status === 0"
              translate="CART.CART_WARNING"
              [translateParams]="{ minPrice, currency: countryCityService.selectedCountry.currency }"
        ></span>
        <button (click)="redirectToOrdering()" [disabled]="cartLogicService.cartTotal < minPrice && countryCityService.cityModule.pickup?.status === 0" class="btn mt-4 green-button" translate="CART.ORDER"></button>
      </div>
</div>
  </ng-container>
</div>
