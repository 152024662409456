import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DimensionsDirective } from './dimensions.directive';
import { ClickOutsideDirective } from './click-outside.directive';
import { DefaultImgDirective } from './default-img.directive';
import { ImgStyleDirective } from './img-style.directive';

@NgModule({
  declarations: [DimensionsDirective, ClickOutsideDirective, DefaultImgDirective, ImgStyleDirective],
  exports: [DimensionsDirective, ClickOutsideDirective, DefaultImgDirective, ImgStyleDirective],
  imports: [CommonModule],
})
export class DimensionsModule {}
