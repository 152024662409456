import { Injectable } from '@angular/core';
import {Observable} from "rxjs";
import {HttpClient} from "@angular/common/http";

import {CityInfoInterface} from "../interfaces/city-info.interface";
import {AppApiUrls} from "../../../../app.api.urls";
import {StorageLogicService} from "../../../../shared/utils/services/storage-logic.service";

@Injectable()
export class HeaderRestService {

  constructor(private http: HttpClient, public storageService: StorageLogicService) { }

  getInfo(): Observable<CityInfoInterface> {
    return this.http.get<CityInfoInterface>(AppApiUrls.cityInfo(), {
      params: {
        city_id: this.storageService.getData('city') || '',
        lang: this.storageService.getData('lang') || ''
      }
    })
  }
}
