import { Directive, ElementRef, Renderer2, AfterViewInit, QueryList } from '@angular/core';

@Directive({
  selector: '[dimensions]',
})
export class DimensionsDirective implements AfterViewInit {

  constructor(private elementRef: ElementRef, private renderer: Renderer2) { }

  ngAfterViewInit() {
    const element = this.elementRef.nativeElement;
    const width = element.clientWidth
    const height = element.clientHeight
    const images = element.querySelectorAll('img') as QueryList<HTMLImageElement>
    Array.from(images).forEach((image) => {
      const dataSrc = (image as HTMLImageElement).getAttribute('data-src');
      const fallbackImg = (image as HTMLImageElement).getAttribute('data-fallbackimg');
      if (dataSrc) {
        try{
          const newSrc = this.addDimensionsToSrc(dataSrc, width, height);
          this.renderer.setAttribute(image, 'src', newSrc);
        }catch(err){
            this.renderer.setProperty(image,'src',fallbackImg);
        }
      }
      else{
        if(fallbackImg){
          this.renderer.setProperty(image,'src', fallbackImg);
        }
      }
    });
  }

  private addDimensionsToSrc(src: string, width: number, height: number): string {
    const url = new URL(src);
    url.searchParams.set('w', width.toString());
    return url.toString();
  }
}
