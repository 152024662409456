import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {ReviewsInterface} from "../interfaces/reviews.interface";
import {AppApiUrls} from "../../../app.api.urls";

@Injectable()

export class ReviewsRestService {
  constructor(private http: HttpClient) {
  }

  get(order_id: number): Observable<ReviewsInterface> {
    return this.http.get<ReviewsInterface>(AppApiUrls.reviews(), {
      params: {
        order_id
      }
    })
  }
}
