import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sortByPrice',
})
export class SortByPricePipe implements PipeTransform {
  transform(value: any[], ...args: any): any[] {
    if (!Array.isArray(value)) {
      return value;
    }

    return value.sort((a, b) => a.price - b.price);
  }
}
