import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CountdownCounter } from './interfaces/countdown-counter.interface';
import { CountdownAppearance } from './enums/countdown-appearance.enum';

@Component({
  selector: 'app-countdown-counter',
  templateUrl: './countdown-counter.component.html',
  styleUrls: ['./countdown-counter.component.scss'],
  changeDetection:ChangeDetectionStrategy.OnPush
})
export class CountdownCounterComponent {

  @Input() counter?: CountdownCounter;
  @Input() appearance?: CountdownAppearance;

  protected readonly COUNTDOWN_APPEARANCE = CountdownAppearance;
}
