
import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, RedirectCommand, Router, RouterStateSnapshot } from '@angular/router';
import { DevAuthService } from './dev-auth.service';
import { map, pluck} from 'rxjs';
import { StorageLogicService } from 'src/app/shared/utils/services/storage-logic.service';
import { cityNames } from 'src/app/shared/const/city-names.const';

export const devWebsite: CanActivateFn = (route: ActivatedRouteSnapshot,  state: RouterStateSnapshot) => {
  const router = inject(Router);
  const storageService = inject(StorageLogicService);
  const pass = storageService.getData('devPass');
  const isOfflineMode = window.matchMedia('(display-mode: standalone)').matches;
  const cityId = storageService.getData('city');
  const cityTranslit = cityNames[cityId] || cityNames[0];

  if (isOfflineMode) {
    const newUrl = router.createUrlTree(['/', cityTranslit, 'menu-pickup'], { queryParamsHandling: '' });
    return new RedirectCommand(newUrl);
  }
  const devAuthService = inject(DevAuthService);
  
  if (pass === null) {
    return router.createUrlTree(['/login']); 
  } 
  return devAuthService.verifyPass(pass).pipe(
    pluck('success'),
    map((success) => {
    if (!success) {
      return router.createUrlTree(['/login']);
    }

  const newUrl = router.createUrlTree([cityTranslit, 'home'], { queryParamsHandling: '' });

  return new RedirectCommand(newUrl);
  }),
  )
}

