import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable, Subject} from "rxjs";
import {AppApiUrls} from "../../../../app.api.urls";
import {CategoriesInterface} from "../interfaces/menu.interface";
import {StorageLogicService} from "../../../../shared/utils/services/storage-logic.service";

@Injectable()
export class MenuRestService {
  sort$ = new Subject<number>();

  constructor(private http: HttpClient, public storageService: StorageLogicService) {
  }

  get(): Observable<CategoriesInterface> {
    return this.http.get<CategoriesInterface>(AppApiUrls.categories(), {
      params: {
        city_id: this.storageService.getData('city')!,
        lang: this.storageService.getData('lang')!
      }
    })
  }
}
