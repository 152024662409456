import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {catchError, Observable, throwError} from "rxjs";
import {AddressesInterface, BalanceInterface, ProfileInterface, UserBirthdayResponse} from "../interfaces/profile.interface";
import {HistoryInterface} from "../interfaces/history.interface";
import {TrackingInterface} from "../interfaces/tracking.interface";
import {AppApiUrls} from "../../../app.api.urls";
import {StorageLogicService} from "../../../shared/utils/services/storage-logic.service";
import { CartInterface } from 'src/app/shell/components/navbar/cart/interfaces/cart.interface';
import { ProductData } from '../../home/interfaces/home.interface';
import { PromoPreviewResponse, PromoResponse } from '../interfaces/promocode.interface';

@Injectable()
export class ProfileRestService {
  constructor(
    private http: HttpClient,
    public storageService: StorageLogicService
  ) {}

  auth(body: any): Observable<ProfileInterface> {
    return this.http.post<any>(AppApiUrls.auth(), body);
  }

  getInfo(): Observable<ProfileInterface> {
    return this.http.get<ProfileInterface>(AppApiUrls.userInfo(), {
      params: {},
      headers: {
        Authorization:
          this.storageService.getData('Authorization')?.pub_id || '',
      },
    });
  }

  getUserHistory(): Observable<HistoryInterface> {
    return this.http.get<HistoryInterface>(AppApiUrls.userHistory(), {
      params: {
        lang: this.storageService.getData('lang')!,
        full: true,
        city_id: this.storageService.getData('city')!,
      },
      headers: {
        Authorization:
          this.storageService.getData('Authorization')?.pub_id || '',
      },
    });
  }

  tracking(id: number): Observable<TrackingInterface> {
    return this.http.get<TrackingInterface>(AppApiUrls.tracking() + id, {
      params: {
        crm: true,
      },
    });
  }

  getAddresses(): Observable<AddressesInterface> {
    return this.http.get<AddressesInterface>(AppApiUrls.userAddresses(), {
      params: {},
      headers: {
        Authorization:
          this.storageService.getData('Authorization')?.pub_id || '',
      },
    });
  }

  sendPhone(body: any): Observable<any> {
    return this.http.post<any>(AppApiUrls.sendPhone(), body);
  }

  createAddresses(body: any): Observable<AddressesInterface> {
    let headers = new HttpHeaders({
      Authorization: this.storageService.getData('Authorization')?.pub_id || '',
    });
    let options = { headers: headers };
    return this.http.post<AddressesInterface>(
      AppApiUrls.userAddresses(),
      body,
      options
    );
  }

  deleteAddress(id: number): Observable<AddressesInterface> {
    let headers = new HttpHeaders({
      Authorization: this.storageService.getData('Authorization')?.pub_id || '',
    });
    let options = {
      params: { id },
      headers: headers,
    };
    return this.http.delete<AddressesInterface>(
      AppApiUrls.userAddresses(),
      options
    );
  }

  editAddress(form: any): Observable<AddressesInterface> {
    let headers = new HttpHeaders({
      Authorization: this.storageService.getData('Authorization')?.pub_id || '',
    });
    let options = {
      headers: headers,
      params: form,
    };
    return this.http.put<AddressesInterface>(
      AppApiUrls.userAddresses(),
      {},
      options
    );
  }

  selectAddress(id: number): Observable<AddressesInterface> {
    let headers = new HttpHeaders({
      Authorization: this.storageService.getData('Authorization')?.pub_id || '',
    });
    let options = {
      params: {
        id,
      },
      headers: headers,
    };
    return this.http.put<AddressesInterface>(
      AppApiUrls.selectAddresses(),
      {},
      options
    );
  }

  reorder(quicksaleId: number): Observable<CartInterface> {
    let body: {};
    if (this.storageService.getData('cart')) {
      body = {
        city_id: this.storageService.getData('city'),
        cart_id: this.storageService.getData('cart'),
        quicksale_id: quicksaleId,
      };
    } else {
      body = {
        city_id: this.storageService.getData('city'),
        quicksale_id: quicksaleId,
      };
    }

    return this.http.post<any>(AppApiUrls.reorder(), body);
  }

  getBalance(): Observable<BalanceInterface> {
    return this.http.get<BalanceInterface>(AppApiUrls.bonusBalance(), {
      params: {
        city_id: this.storageService.getData('city') || '5',
      },
    });
  }

  createPromocode() {
    let params = {
      onetime: 1,
      pub_id: this.storageService.getData('Authorization')?.pub_id,
    };

    return this.http.post<{success:boolean}>(
      AppApiUrls.promoCodes('create'),
        {},
        { params });
  }

  getPromocodes() {
    let params = {
      referer: this.storageService.getData('Authorization')?.pub_id,
    };
    return this.http
      .get<PromoResponse>(
        AppApiUrls.promoCodes('list'),
        { params }
      )
  }

  activatePromocode(promocode: string) {
    let params = {
      promocode,
      city_id: this.storageService.getData('Authorization').city_id,
    };
    return this.http
      .post<{ success: boolean; message?: string; data?: any }>(
        AppApiUrls.promoCodes('use'),
        {},
        { params, headers: { skipInterceptor: 'true' } }
      )
      .pipe(
        catchError((e) =>
          throwError(() => {
            return e.error.message;
          })
        )
      );
  }

  getFreeProductPreview(promocode:string):Observable<PromoPreviewResponse> {
    let params = {
      city_id: this.storageService.getData('cityInfo').id,
      promocode
    };

    return this.http.get<PromoPreviewResponse>(
      AppApiUrls.freeProducts('get'),
      { params }
    );
  }

  getFreeProducts(): Observable<{success: boolean; data: ProductData[] }> {
    let params = {
    city_id: this.storageService.getData('cityInfo').id,
  };
    return this.http.get<{ success: boolean; data: ProductData[] }>(
      AppApiUrls.freeProducts('list'),
      {params}
    );
  }

  addUserBirthday(birthday: string): Observable<{success:boolean}> {
    return this.http.put<{success:boolean}>(`${AppApiUrls.userInfo()}birthday`, { birthday })
  }

  getUserBirthday(cityId:number):Observable<UserBirthdayResponse> {
    return this.http.get<UserBirthdayResponse>(`${AppApiUrls.userInfo()}birthday`, {
      params: {
        city_id:cityId
      }
    })
  }
}
