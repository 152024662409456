import { Country } from "../../header/interfaces/city-info.interface";

export interface CountriesData {
  cities: CitiesData[];
  currency: string;
  franchise: number;
  id: number;
  image: string;
  languages: Lang[];
  name: string;
  phone_code: string;
  phone_length: number;
  total_round: number;
}

export interface NavbarInterface<T> {
  success: boolean;
  data: T[];
}
export interface CitiesData {
  id: number;
  name: string;
  img: string;
  google_analytics_key: string;
  google_api_key: string;
  google_tag_key: string;
  pixel_key: string;
  country: CountriesData
}

export enum Langs {
  es = 'es',
  ru = 'ru',
  en = 'en',
  ua = 'ua'
}

export enum Cities {
  Zaporizhzhia = 1,
  Dnipro = 2,
  IvanoFrankivsk = 3,
  Kharkiv = 9,
  Khmelnytskyi = 10,
  Sumy = 11,
}

export interface Lang {
  id: number;
  name: string;
  code: Langs
}

export enum ModuleType {
  OnlinePayment = 'online_payment',
  Pickup = 'pickup',
  OrderTracking = 'order_tracking',
  Vacancy = 'vacancy',
  Card = 'card',
  Delivery = 'delivery',
  DontCall = 'dont_call',
  Bonuses = 'bonuses',
  Preorder = 'preorder',
}
