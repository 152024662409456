import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";

import {CheckoutCartInterface} from "../interfaces/checkout-cart.interface";
import {AppApiUrls} from "../../../app.api.urls";

@Injectable({providedIn: 'root'})

export class CheckoutCartRestService {
  constructor(private http: HttpClient) {
  }

  get(cart_id: number): Observable<CheckoutCartInterface> {
    return this.http.get<CheckoutCartInterface>(AppApiUrls.checkoutCart(), {
      params: {
        cart_id
      }
    })
  }
}
