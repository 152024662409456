import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
} from '@angular/core';
import { ProductCardRestService } from '../services/product-card-rest.service';
import { Observable, takeUntil } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { NutrationInterface } from '../interfaces/nutration.interface';
import { Country } from '../../../shell/components/header/interfaces/city-info.interface';
import { CartRestService } from '../../../shell/components/navbar/cart/services/cart-rest.service';
import { Unsubscribe } from '../../../unsubscribe';
import { StorageLogicService } from '../../utils/services/storage-logic.service';
import { CartLogicService } from '../../../shell/components/navbar/cart/services/cart-logic.service';
import { FavoriteProductsApiService } from '../../utils/services/favorite-products-api.service';
import { CountdownService } from '../../utils/services/countdown.service';
import { CountdownCounter } from '../../countdown-counter/interfaces/countdown-counter.interface';
import { CountdownAppearance } from '../../countdown-counter/enums/countdown-appearance.enum';
import { ProductDataAdapter } from '../../utils/poduct-data-adapter';
import { ProductData } from 'src/app/pages/home/interfaces/home.interface';
import { Prices } from 'src/app/pages/home/interfaces/home.interface';

export interface NavigationPath {
  url: string[];
  params: {[key:string]:string}
}

@Component({
  selector: 'app-product-card',
  templateUrl: './product-card.component.html',
  styleUrls: ['./product-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers:[CountdownService]
})
export class ProductCardComponent extends Unsubscribe implements OnInit {
  @Input() item: number = 0;
  @Input() offlineProductItem:ProductData | null = null;
  @Output() navigationPath = new EventEmitter<NavigationPath>();
  product: any = {};
  weightId: number = 0;
  weight: number = 0;
  salePercent: number = 0;
  regularPrice: number = 0;
  price: number = 0;
  img: string = '';
  url: string = '';
  category: number | string = '';
  description: string = '';
  nutritionVisible = true;
  nutrition100!: NutrationInterface;
  nutritionWeight!: NutrationInterface;
  country: Country = this.storageService.getData('country');
  isMobileDevice: boolean = false;
  isFavorite = false;
  readonly shareButtons = ['telegram', 'facebook', 'twitter', 'email', 'copy'];
  readonly shareButtonsMobile = [
    'telegram',
    'facebook',
    'twitter',
    'messenger',
    'viber',
    'email',
    'copy',
  ];


  shareProductCategory='';

  showFavoriteButton = false;
  
  countdownStream!: Observable<CountdownCounter>;
  protected readonly COUNTDOWN_APPEARANCE = CountdownAppearance;

  @HostListener('window:resize')
  onResize(): void {
    this.checkDeviceType();
  }

  constructor(
    private service: ProductCardRestService,
    private cartRestService: CartRestService,
    private cartLogicService: CartLogicService,
    private modalService: NgbModal,
    private router: Router,
    public storageService: StorageLogicService,
    private cdr: ChangeDetectorRef,
    private cartService: CartLogicService,
    private favoriteService: FavoriteProductsApiService,
    private countdownService: CountdownService,
    private readonly productDataAdapter:ProductDataAdapter
  ) {
    super();
  }

  ngOnInit(): void {
    this.url = this.router.url;

    if (this.offlineProductItem) {
      this.product = {data:this.offlineProductItem};
      this.initProductDetails(this.product.data);
    } else {
      this.getProduct();
    }
    
    this.checkDeviceType();
    this.showFavoriteButton = this.storageService.getData('Authorization');
  }

  private initProductDetails(data: ProductData) {
    const defaultNutritionValue = {
      calories: 0,
      carbohydrate: 0,
      fat: 0,
      protein: 0
    };

    this.weightId = data.prices[0]?.id;
    this.weight = data.prices[0]?.weight;
    this.price = data.prices[0]?.price;
    this.img = data.prices[0]?.img ? data.prices[0]?.img : data.img;
    this.nutrition100 = data.prices[0]?.nutrition100 || defaultNutritionValue;
    this.nutritionWeight = data.prices[0]?.nutrition || defaultNutritionValue;
    this.salePercent = data.prices[0]?.sale_percent;
    this.regularPrice = data.prices[0]?.regular_price;
    this.isFavorite = data.saved;
    this.category = data?.cat_id;
    this.setDescription(this.category);
    if (
      this.nutrition100?.protein == 0 &&
      this.nutrition100?.fat == 0 &&
      this.nutrition100?.calories == 0 &&
      this.nutrition100?.carbohydrate == 0
    ) {
        this.nutritionVisible = false;
      }
  }

  checkDeviceType(): void {
    this.isMobileDevice = window.innerWidth <= 768;
  }

  redirectToRoll(productId: number, url?: string): void {
    if (url) {
      this.modalService.dismissAll();
      setTimeout(() => {
        this.router
          .navigate(['home/' + url], {
            queryParams: {
              productId,
            },
          })
          .then();
      }, 1);
    }
  }

  navigateToAllergensPage(): void {
    this.navigationPath.emit({
      url: ['/info'],
      params:{page:'allergens'}
    })
  }

  openInst(link: string): void {
    window.open(link, '_blank');
  }

  selectWeight(weight: any): void {
    this.weightId = weight.id;
    this.weight = weight.weight;
    this.price = weight.price;
    this.img = weight.img;
    this.salePercent = weight.sale_percent;
    this.regularPrice = weight.regular_price;
    this.nutrition100 = weight.nutrition100;
    this.nutritionWeight = weight.nutrition;
  }

  getProduct(): void {
    this.service
      .get(this.item || this.storageService.getData('product_modal_id'))
      .pipe(takeUntil(this.$destroy))
      .subscribe({
        next: (value) => (this.product = value),
        complete: () => {
          this.initProductDetails(this.product.data);

          const discountDeadline = this.productDataAdapter.findSaleDeadline(this.product.data.prices);
          this.initCountdownStream(discountDeadline)

          this.cdr.detectChanges();
        },
      });
  }

  setDescription(category: number | string): void {
    switch (category) {
      case 15:
        this.shareProductCategory='PROMOCODE.SHARE_PRODUCT_CATEGORY.SUSHI_SETS'
        break;
      case 3:
          this.shareProductCategory='PROMOCODE.SHARE_PRODUCT_CATEGORY.ROLLS'
        break;
      case 22:
          this.shareProductCategory='PROMOCODE.SHARE_PRODUCT_CATEGORY.PHILA_SETS'
        break;
      case 10:
          this.shareProductCategory='PROMOCODE.SHARE_PRODUCT_CATEGORY.CHEF'
        break;
      case 12:
         this.shareProductCategory='PROMOCODE.SHARE_PRODUCT_CATEGORY.SUSHI'
        break;
      case 11:
          this.shareProductCategory='PROMOCODE.SHARE_PRODUCT_CATEGORY.FIRST_COURSE'
        break;
      case 5:
          this.shareProductCategory='PROMOCODE.SHARE_PRODUCT_CATEGORY.NOODLES'
        break;
      case 6:
          this.shareProductCategory='PROMOCODE.SHARE_PRODUCT_CATEGORY.RICE'
        break;
      case 7:
          this.shareProductCategory='PROMOCODE.SHARE_PRODUCT_CATEGORY.BEVERAGES'
        break;
      case 16:
          this.shareProductCategory='PROMOCODE.SHARE_PRODUCT_CATEGORY.SAUCES'
        break;
      default:
        this.description = ``;
        break;
    }
  }

  addToCart() {
    this.cartLogicService.addProduct(this.weightId);
    this.modalService.dismissAll();
  }

  handleHeartClick(){
    this.isFavorite?
    this.unfavoriteProduct():
    this.addFavoriteProduct()
  }

  addFavoriteProduct(){
    this.favoriteService.addFavoriteProduct(this.product.data.id).pipe(takeUntil(this.$destroy)).subscribe();
    this.isFavorite = true;
  }

  unfavoriteProduct(){
    this.favoriteService.unfavoriteProduct(this.product.data.id).pipe(takeUntil(this.$destroy)).subscribe();
    this.isFavorite = false;
  }

  private initCountdownStream(timeLeft:number) {
    this.countdownStream = this.countdownService
      .startCountdown(timeLeft)
      .pipe(takeUntil(this.$destroy));
  }
}
