<div [ngClass]="{
    'countdown-wrapper':true,
    'countdown-padding':appearance!==COUNTDOWN_APPEARANCE.ORDER_VIEW && appearance!==COUNTDOWN_APPEARANCE.GALLERY_SM_VIEW, 
    'gallery-view': appearance === COUNTDOWN_APPEARANCE.GALLERY_VIEW, 
    'card-view':appearance===COUNTDOWN_APPEARANCE.PRODUCT_VIEW,
    'order-view':appearance===COUNTDOWN_APPEARANCE.ORDER_VIEW,
    'gallery-sm-view':appearance===COUNTDOWN_APPEARANCE.GALLERY_SM_VIEW 
    }">
    <div class="backdrop" *ngIf="appearance === COUNTDOWN_APPEARANCE.PRODUCT_VIEW"></div>
    <div class="countdown">
        <img src="assets/icons/discount.webp" alt="discount icon" class="countdown__icon">
        <div class="timeblock">
            <p class="timeblock__accent">{{counter?.days}}</p>
            <span>{{"COUNTDOWN.DAY" | translate}}</span>
        </div>
        <div class="timeblock">
            <p class="timeblock__accent">{{counter?.hours}}</p>
            <span>{{"COUNTDOWN.HOUR" | translate}}</span>
        </div>
        <div class="timeblock">
            <p class="timeblock__accent">{{counter?.minutes}}</p>
            <span>{{"COUNTDOWN.MIN" | translate}}</span>
        </div>
        <div class="timeblock">
            <p class="timeblock__accent">{{counter?.seconds}}</p>
            <span>{{"COUNTDOWN.SEC" | translate}}</span>
        </div>
    </div>
</div>