import { CanActivateFn, RedirectCommand, Router } from '@angular/router';
import { StorageLogicService } from '../services/storage-logic.service';
import { inject } from '@angular/core';
import { cityNames } from '../../const/city-names.const';


export const oldRouteGuard: CanActivateFn = (route, state) => {
  const storageService = inject(StorageLogicService);
  const router = inject(Router);
  const cityId = storageService.getData('city');

  //common code
  const transliteratedCityName = cityNames[cityId] || cityNames[0];

  const { queryParams } = route;
  const segments = route.url.map(segment => segment.path);
  const tree = router.createUrlTree([transliteratedCityName, ...segments], { queryParams, queryParamsHandling: 'merge' });
  return new RedirectCommand(tree);
};

