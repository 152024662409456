import {AfterViewInit, Component, EventEmitter, Input, OnInit, Output, signal} from '@angular/core';
import {MenuRestService} from "../services/menu-rest.service";
import {Unsubscribe} from "../../../../unsubscribe";
import {map, merge, pluck, switchMap, take, takeUntil, tap} from "rxjs";
import {NavigationEnd, Router} from "@angular/router";
import {CategoriesData} from "../interfaces/menu.interface";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {SetConstructorComponent} from "../../../../pages/set-constructor/components/set-constructor.component";
import { CountryCityNotificationService } from 'src/app/shared/utils/services/country-city-notification.service';
import {Attribute, Attributes} from "../../../../shared/decoration/interface/decoration";
import {DecorationRestService} from "../../../../shared/decoration/services/decoration-rest.service";
import {StorageLogicService} from "../../../../shared/utils/services/storage-logic.service";


@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent extends Unsubscribe implements OnInit, AfterViewInit {
  @Input() categoriesInput: any = null;
  @Output() activatedCategory = new EventEmitter<number>();
  currentActivatedCategory = signal<null | CategoriesData>(null);

  categories!: CategoriesData[];
  currentLink: string = '';
  menuStyle?: Attribute

  constructor(
    private readonly storageLogicService: StorageLogicService,
    private service: MenuRestService,
    private router: Router,
    private modalService: NgbModal,
    private countryCityService: CountryCityNotificationService,
    public decorationRestService: DecorationRestService,
    public menuRestService: MenuRestService,
  ) {
    super();
  }

  public get needShowEcoPizza(): boolean {
    return this.storageLogicService.getData('city') == 2
  }

  ngOnInit(): void {
    if (!this.categoriesInput) {
    this.getDecoration()
      this.getCategories().pipe(take(1)).subscribe(categories => {
        this.categories = categories
        const selectedCategory = categories.find(category => `/home/${category.site_url}` === this.link)
        this.onCategoryChange(selectedCategory?.sort || 0)
      });
      this.router.events.pipe(takeUntil(this.$destroy)).subscribe((event) => {
        if (event instanceof NavigationEnd) {
          const isSetConstructor = event.url.split('/').includes('set-constructor')
          if (isSetConstructor) {
            this.openSetsConstructor(SetConstructorComponent);
          }
        }
      });
      this.setupLanguageCityChangeSubs();
    }
  }

  onCategoryChange(sort: number){
    this.menuRestService.sort$.next(sort);
  }

  emitCategory(object: CategoriesData) {
    if (this.currentActivatedCategory()?.id === object.id) {
      return;
    }
    this.currentActivatedCategory.set(object);
    this.activatedCategory.emit(object.id);
  }

  ngAfterViewInit() {
    if (this.router.url.includes('set-constructor')) {
      this.openSetsConstructor(SetConstructorComponent);
    }
  }

  get link(): string {
    return this.currentLink = this.router.url;
  }
  getDecoration(){
    this.decorationRestService.attributes.subscribe(attributes => {
      this.menuStyle = attributes.find(({attribute})=> attribute === Attributes.menu)
    });
  }
  setupLanguageCityChangeSubs() {
    merge(
      this.countryCityService.languageChanged$,
      this.countryCityService.cityChanged$
    ).pipe(
      takeUntil(this.$destroy),
      switchMap(() => this.getCategories()),
      tap((categories) => this.categories = categories)
    ).subscribe();
  }

  getCategories() {
    return this.service.get().pipe(
        pluck('data'),
        map(value => {
          return value.map((category) => {
            return category.id === 73726678 ? {...category, site_url: 'set-constructor'} : category;
          });
        }
      )
    );
  }

  openSetsConstructor(content: any) {
    this.modalService.open(content, {size: 'xl', keyboard: false, backdrop: 'static', windowClass: 'custom-modal'});
  }

  public ecoPizzaNavigate() {
    window.open('https://ecopizza.com.ua/pica-3', '_blank')
  }

}
