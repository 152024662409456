import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {AppApiUrls} from "../../../app.api.urls";
import {Attribute, Attributes, DecorationData} from "../interface/decoration";
import {StorageLogicService} from "../../utils/services/storage-logic.service";
import {ThemeLogicService} from "../../theme/services/theme-logic.service";

@Injectable({
  providedIn: 'root'
})
export class DecorationRestService {
  attributes = new BehaviorSubject<Attribute[]>([]);

  constructor(private http: HttpClient,
              public storageService: StorageLogicService,
              private themeLogicService: ThemeLogicService
  ) {
    this.getDecoration().subscribe(({data}) => {
      if (data?.attributes) {
        this.attributes.next(data?.attributes)
      }
    })
  }

  getDecoration(): Observable<DecorationData> {
    return this.http.get<DecorationData>(AppApiUrls.decoration(), {
      params: {
        dark: this.themeLogicService.isDarkTheme? '1' : '0',
        city_id: this.storageService.getData('city')!,
      }
    });
  }
}
