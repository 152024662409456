  <div class="constructor-wrapper">
    <div [ngClass]="{'modal-background-blur': isOpenSaveSetModal}" class="modal-header border-0 px-4 position-relative justify-content-between">
      <ng-container *ngIf="!showProducts">
        <button class="close-btn" (click)="closeSetConstructor(saveSetModal)">X</button>
        <span style="font-size: 1.5rem" class="fw-bold constructor-text" translate="SET_CONSTRUCTOR.TITLE"></span>
        <button class="close-btn-all" (click)="deleteSet()">
          <img class="d-flex m-auto" style="width: 1.2rem" alt="trash" src="assets/trash.svg">
        </button>
      </ng-container>
      <ng-container *ngIf="showProducts">
        <app-filter [(filter)]="filter" [(filterIng)]="filterIng" [(productSearch)]="productSearch"
                    [(valueFilterIng)]="valueFilterIng" [(valueFilterMark)]="valueFilterMark"
                    [(filterByIng)]="filterByIng"
                    [(filterByMark)]="filterByMark" [showFilter]="false" class="product-filter-container"></app-filter>
      </ng-container>
      <button *ngIf="showProducts" class="close-btn close-products" (click)="returnFromProducts()">X</button>
    </div>

    <div [ngClass]="{'modal-background-blur': isOpenSaveSetModal}" class="modal-body-container row"
         style="box-sizing: border-box;">
      <div class="d-flex justify-content-center align-items-center my-5" *ngIf="loader">
        <div class="lds-spinner">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
      <div *ngIf="showProducts && !products.length">Didn't find anything</div>

      <div *ngIf="!showProducts && !loader" class="d-flex constructor pt-2 px-5">
        <div class="col-5 constructor__result" [ngClass]="{'default-product-image': !set.length}">
          <div class="product-image-container">
            <div class="product-image" #productImageView></div>
          </div>
          <div *ngIf="set.length" class="weight-set">
            <div>
              {{weight}}<span  translate="PRODUCT_CARD.G"></span>
            </div>
          </div>
          <div class="set-info" *ngIf="pieces">
            <div class="set-info__size">
              <img [src]="srcPieceImage()" class="piece-img" alt="piece">
              <span class="piece">{{pieces}}</span>
            </div>
          </div>
          <ng-container *ngIf="instagram.length">
            <div class="mt-2 d-flex align-mobile">
              <div class="me-2" *ngFor="let object of instagram" dimensions>
                <img *ngIf="object.img_link" (click)="openInst(object.inst_link)" class="instagram"
                [attr.data-src]="object.img_link" alt="">
                <img *ngIf="!object.img_link" class="instagram" src="assets/sushi-like.png" alt="">
              </div>
            </div>
          </ng-container>
        </div>
        <div class="text-center fw-bold constructor__variants container">
          <div *ngIf="!set.length" class="information">
            <p translate="SET_CONSTRUCTOR.INFORMATION1"></p>
            <p translate="SET_CONSTRUCTOR.INFORMATION2"></p>
            <p translate="SET_CONSTRUCTOR.INFORMATION3"></p>
            <p translate="SET_CONSTRUCTOR.INFORMATION4"></p>
            <p translate="SET_CONSTRUCTOR.INFORMATION5"></p>
            <p translate="SET_CONSTRUCTOR.INFORMATION6"></p>
          </div>
          <div class="row px-4 pb-3 card-padding-right-left scroll-2" [ngClass]="{'mobile-scroll': !set.length}">
            <ng-container *ngFor="let product of set; index as i">
              <div class="card-padding col-4">
                <div class="card product-card text-center mt-4" dimensions>
                  <ng-container>
                    <div class="weight">
                      <div>
                        {{product.weight}}
                      </div>
                    </div>
                  </ng-container>
                  <img *ngIf="product?.img"
                  [attr.data-src]="product?.img"
                       class="card-img-top d-block mx-auto product-img-set"
                       alt="..." appImgStyle>
                  <img *ngIf="!product?.img" style="cursor: pointer; height: 10rem; object-fit: contain"
                       src="assets/sushi-like.png"
                       class="card-img-top d-block mx-auto"
                       alt="..." appImgStyle>
                  <div class="card-body p-0">
                    <div class="name-set">
                      <h5 class="product-name">{{product?.name}}</h5>
                    </div>
                    <p
                      class="price d-flex justify-content-center">{{product.old_price | number: '1.2-2'}} {{countryCityService.selectedCountry.currency}}</p>
                    <button class="btn red-button product-button fw-bold" (click)="removeFromSet(product.id)" translate="SET_CONSTRUCTOR.DELETE_PRODUCT">
                    </button>
                  </div>
                </div>
              </div>
            </ng-container>
          </div>
          <button class="add-btn" (click)="goToProducts()">
            <span class="add-icon">+</span>
          </button>
        </div>
      </div>

      <div *ngIf="showProducts && !loader" class="container scroll px-4">
        <ng-container
          *ngIf="(formArrayElement.controls | homeSearch: productSearch | filterMark: filter | filterIng: filterIng) as FILTERED_ITEMS;">
          <ng-container *ngIf="FILTERED_ITEMS.length; else emptyBlock;">
            <form [formGroup]="form">
              <div class="row mt-4 card-padding-right-left">
                <ng-container formArrayName="weight" *ngFor="let object of FILTERED_ITEMS; index as i">
                  <div id="products" class="card-padding col-3" *ngIf="object.value as productForm">
                    <form [formGroupName]="i">
                      <app-product-item [productForm]="object.value" (addToCart)="addToSet($event)"></app-product-item>
                    </form>
                  </div>
                </ng-container>
                </div>
        </form>
        </ng-container>
        </ng-container>
      </div>
    </div>

    <ng-template #emptyBlock>
        <div class="empty-block">{{'SET_CONSTRUCTOR.NO_RESULTS' | translate}}</div>
    </ng-template>

    <div *ngIf="!showProducts && !loader" [ngClass]="{'modal-background-blur': isOpenSaveSetModal}"
         class="modal-footer border-0 px-4">
      <div *ngIf="price" class="prise">
        <span style="font-size: 1.7rem" class="old-prise fw-bold me-3">{{oldPrice}} {{countryCityService.selectedCountry.currency}}</span>
        <span style="font-size: 1.7rem" class="fw-bold">{{price}} {{countryCityService.selectedCountry.currency}}</span>
      </div>
      <button (click)="closeModal()" class="btn green-button save-btn fw-bold px-4" [disabled]="set.length < minProductsInSet"
              [ngClass]="{ 'save-btn': set.length < minProductsInSet, 'green-button': set.length >= minProductsInSet }" translate="PROFILE.SAVE">
      </button>
    </div>
  </div>

  <ng-template #saveSetModal class="custom-class">
    <div style="display: flex; flex-direction:column; align-items: center;justify-content: center">
      <span style="font-size: 1.5rem" class="fw-bold" translate="SET_CONSTRUCTOR.MODAL_TITLE"></span>
      <span style="font-size: 1rem" translate="SET_CONSTRUCTOR.SAVE"></span>
    </div>
    <div style="display: flex">
      <button style="width: 100%" class="btn button-confirmation"
              (click)="closeSetConstructor()" translate="SET_CONSTRUCTOR.ABOLITION"></button>
      <button style="width: 100%" class="btn button-confirmation" (click)="closeModal()" translate="SET_CONSTRUCTOR.CONFIRM"></button>
    </div>
  </ng-template>
