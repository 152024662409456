import {StorageLogicService} from "./services/storage-logic.service";

export function detectGTM(): string {
  const storageService = new StorageLogicService()
  if (storageService.getData('cityInfo')?.google_tag_key) {
    return storageService.getData('cityInfo').google_tag_key;
  } else {
    return 'GTM-PK3CXMG';
  }
}

export function changeKeyAnalyticsInHead(): void {
  const storageService = new StorageLogicService();
  const G_TAG = storageService.getData('cityInfo')?.google_analytics_key ? storageService.getData('cityInfo').google_analytics_key : 'G-YM8HVNQD4X';
  const PIXEL_ID = storageService.getData('pixel_key') ? storageService.getData('pixel_key') : '';
  document.head.innerHTML = document.head.innerHTML.replace(/GOOGLE-KEY/g, G_TAG);
  document.head.innerHTML = document.head.innerHTML.replace(/PIXEL-KEY/g, PIXEL_ID);
}

export function setSiteIndex(): void {
  const storageService = new StorageLogicService()
  const SITE_INDEX = storageService.getData('cityInfo')?.site_index || '';
  const indexText = document.getElementById('indexText');
  console.log(SITE_INDEX);
  if(indexText) {
    indexText.innerHTML = SITE_INDEX;
  }
}



