import {Injectable} from "@angular/core";
import {GoogleTagManagerService} from "angular-google-tag-manager";

@Injectable({
  providedIn: 'root'
})

export class GoogleAnalyticsLogicService {
  constructor(private gtmService: GoogleTagManagerService) {
  }

  public eventEmitter(eventName: string, ecommerceData: {}){
    this.gtmService.pushTag({ecommerce: null}).then();
    const gtmTag = {
      event: eventName,
      ecommerce: ecommerceData
    };
    this.gtmService.pushTag(gtmTag).then();
  }

}
