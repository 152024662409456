import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {Products} from "../../../pages/home/interfaces/home.interface";
import {AppApiUrls} from "../../../app.api.urls";
import {StorageLogicService} from "../../utils/services/storage-logic.service";

@Injectable()
export class ProductCardRestService {

  constructor(private http: HttpClient, public storageService: StorageLogicService) { }

  get(id: number): Observable<Products> {
    return this.http.get<Products>(AppApiUrls.product(), {
      params: {
        city_id: this.storageService.getData('city')!,
        id,
        lang: this.storageService.getData('lang')!
      }
    })
  }
}
