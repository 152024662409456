import {Component, OnInit} from '@angular/core';
import {merge, switchMap, takeUntil} from "rxjs";

import {BannersRestService} from "../services/banners-rest.service";
import {Unsubscribe} from "../../../../unsubscribe";
import {BannersInterface} from "../interfaces/banners.interface";
import { CountryCityNotificationService } from 'src/app/shared/utils/services/country-city-notification.service';

@Component({
    selector: 'app-banners',
    templateUrl: './banners.component.html',
    styleUrls: ['./banners.component.scss'],
})
export class BannersComponent extends Unsubscribe implements OnInit {

    banners!: BannersInterface;

    constructor(
        private service: BannersRestService,
        private countryCityService: CountryCityNotificationService
    ) {
        super();
    }

    ngOnInit(): void {
        this.get().pipe(takeUntil(this.$destroy)).subscribe({
            next: value => this.banners = value,
        });
        this.listenForCityChange();
    }

    listenForCityChange() {
        merge(this.countryCityService.cityChanged$, this.countryCityService.languageChanged$)
        .pipe(
            takeUntil(this.$destroy),
            switchMap(() => this.get()),
        ).subscribe(bannerData => this.banners = bannerData);
    }

    get() {
        return this.service.get();      
    }
}
