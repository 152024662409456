<div
  class="d-flex justify-content-center align-items-center my-5"
  *ngIf="!products"
>
  <div class="lds-spinner">
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </div>
</div>
<div class="container filter-container">
  <div *ngIf="filterVisible">
    <app-filter
      [(filter)]="filter"
      [(filterIng)]="filterIng"
      [(productSearch)]="productSearch"
      [(valueFilterIng)]="valueFilterIng"
      [(valueFilterMark)]="valueFilterMark"
      [(filterByIng)]="filterByIng"
      [(filterByMark)]="filterByMark"
      [selectedFilter]="selectedFilter"
      [hideFilterIng]="hideFilterIng"
      [hideFilterMark]="hideFilterMark"
      (sortProductsByPrice)="sortProductsByPrice($event)"
    >
    </app-filter>
  </div>
</div>
<div class="container">
  <h1 class="d-none" translate="HOME.SUSHI_3303"></h1>
  <form [formGroup]="form">
    <div class="row mt-4 card-padding-right-left" *ngIf="formArrayElement">
      <ng-container
        formArrayName="weight"
        *ngFor="
          let object of formArrayElement.controls
            | homeSearch : productSearch
            | filterMark : filter
            | filterIng : filterIng;
          index as i
        "
      >
        <div
          id="products"
          class="card-padding col-3"
          *ngIf="object.value as productForm"
        >
          <form [formGroupName]="i">
            <app-product-item
              [productForm]="object.value"
              (selectWeight)="selectWeight($event, i)"
              (selectDrink)="selectDrink($event, i)"
              (openProductModal)="openModal($event)"
              (addToCart)="addToCart($event, i)"
            ></app-product-item>
          </form>
        </div>
      </ng-container>
    </div>
  </form>
</div>

<ng-template #cardProduct>
  <div class="modal-header none-border">
    <h4 class="modal-title"></h4>
    <button type="button" class="modal__button-close" (click)="closeModal()">
        <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="#E12829"
        class="bi-x-lg"
        viewBox="0 0 16 16"
        >
          <path
            fill-rule="evenodd"
            d="M13.854 2.146a.5.5 0 0 1 0 .708l-11 11a.5.5 0 0 1-.708-.708l11-11a.5.5 0 0 1 .708 0Z"
          />
          <path
            fill-rule="evenodd"
            d="M2.146 2.146a.5.5 0 0 0 0 .708l11 11a.5.5 0 0 0 .708-.708l-11-11a.5.5 0 0 0-.708 0Z"
          />
        </svg>
     </button>

  </div>
  <div class="modal-body">
    <app-product-card [item]="productId" (navigationPath)="navigateToInfoPage($event)"></app-product-card>
  </div>
</ng-template>

<ng-template #alcoholWarning>
  <div class="modal-header none-border">
    <h4 class="modal-title"></h4>
    <button type="button" class="modal__button-close" (click)="closeModal()">
        <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="#E12829"
        class="bi-x-lg"
        viewBox="0 0 16 16"
        >
          <path
            fill-rule="evenodd"
            d="M13.854 2.146a.5.5 0 0 1 0 .708l-11 11a.5.5 0 0 1-.708-.708l11-11a.5.5 0 0 1 .708 0Z"
          />
          <path
            fill-rule="evenodd"
            d="M2.146 2.146a.5.5 0 0 0 0 .708l11 11a.5.5 0 0 0 .708-.708l-11-11a.5.5 0 0 0-.708 0Z"
          />
        </svg>
  </button>

  </div>
  <div class="modal-body text-center">
    <h5 class="grey-font mb-4" translate="ALCOHOL_WARNING.MASSAGE"></h5>
    <button
      class="warning-yes"
      (click)="warningSubmit('yes')"
      translate="ALCOHOL_WARNING.YES"
    ></button>
    <button
      class="warning-no"
      (click)="warningSubmit('no')"
      translate="ALCOHOL_WARNING.NO"
    ></button>
  </div>
</ng-template>

<ng-template #downloadApp let-modal>
  <div class="none-border mx-4 my-2 d-flex justify-content-between">
    <h4
      style="font-size: 16px"
      class="fw-bold accent-text"
      translate="NAVBAR.DOWNLOAD_APP"
    ></h4>
    <button type="button" class="modal__button-close" (click)="closeModal()">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="#E12829"
        class="bi-x-lg"
        viewBox="0 0 16 16"
      >
        <path
          fill-rule="evenodd"
          d="M13.854 2.146a.5.5 0 0 1 0 .708l-11 11a.5.5 0 0 1-.708-.708l11-11a.5.5 0 0 1 .708 0Z"
        />
        <path
          fill-rule="evenodd"
          d="M2.146 2.146a.5.5 0 0 0 0 .708l11 11a.5.5 0 0 0 .708-.708l-11-11a.5.5 0 0 0-.708 0Z"
        />
      </svg>
    </button>

  </div>
  <div class="modal-body row">
    <div
      class="col-6 d-flex justify-content-center align-items-center flex-column"
    >
      <img
        class="market-ios"
        [src]="'/assets/apps/apple/' + appModalLangApple + '.svg'"
        alt="App store"
        loading="lazy"
        (click)="openApp('https://apps.apple.com/app/sushi-3303/id1613546923')"
      />
      <img
        class="market-android"
        [src]="'/assets/apps/google/' + appModalLangGoogle + '.webp'"
        alt="Market android"
        loading="lazy"
        (click)="
          openApp(
            'https://play.google.com/store/apps/details?id=ua.s3303.sushi3303'
          )
        "
      />
    </div>
    <div class="col-6">
      <img
        class="phone me-4"
        [src]="'/assets/apps/' + appModalLangScreen + '.webp'"
        alt="Market android"
        loading="lazy"
      />
    </div>
  </div>
</ng-template>
