<div class="body">
<app-navbar></app-navbar>
<app-header></app-header>
<app-banners *ngIf="!hideBanner"></app-banners>
<app-menu [ngStyle]="{background: menuStyle?.value?.background}" [ngClass]="{'sticky-menu__shadow': isFixed}" id="menu" class="sticky-menu my-4"></app-menu>
<div class="flex-1">
<router-outlet></router-outlet>
</div>
<app-footer></app-footer>
</div>
