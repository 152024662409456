export interface Products {
  success: boolean;
  data: ProductData[];
}

export interface ProductData {
  id: number;
  name: string;
  description: string;
  img: string;
  prices: Prices[];
  ingredients: Ingredients[];
  instagram: Instagram[];
  marks: Marks[];
  allergens: Allergens[],
  saved: boolean;
  cat_id: number;
  valid_until?:number
}

export interface Prices {
  id: number;
  price: number;
  weight: number;
  img: string;
  pieces: number;
  regular_price: number;
  sale_percent: number;
  sale_left?: number|null;
  valid_until?: number;
  nutrition?: NutritionValue;
  nutrition100?: NutritionValue;
}

export interface Ingredients extends Marks {
  id?: number;
  type?: boolean;
}

export interface Instagram {
  img: string;
  inst_link: string;
  img_link: string;
}

export interface Marks {
  name: string;
  img: string;
}

export interface Allergens {
  id: number;
  name: string;
  description: string;
  img: string;
}

export enum Countries {
  ua = 'Україна'
}

export enum FilterType {
  cheap = 'CHEAP',
  expensive = 'EXPENSIVE',
  novelty = 'NOVELTY',
  random = 'RANDOM'
}

export interface FavoriteProductResp {
  site_user_id: number,
  product_id: number,
  id: number
}

export interface NutritionValue { 
  calories: number;
  carbohydrate: number;
  fat: number;
  protein: number;
}