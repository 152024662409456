export interface DecorationData {
  success?: boolean,
  data?: DecorationData
}

export interface DecorationData {
  id: number;
  country_id: number;
  date_from: string;
  date_to: string;
  created_at: string;
  updated_at: string;
  attributes: Attribute[];
}

export interface Attribute {
  id: number;
  decoration_id: number;
  attribute: string;
  value: any;
  created_at: string;
  updated_at: string;
}

export enum Attributes {
  logo = "logo",
  background = "background",
  primaryColor = "primary_color",
  secondaryColor = "secondary_color",
  citySelector = "city_selector",
  callback = "callback",
  bonus = "bonus",
  navigation = "navigation",
  deliveryInfo = "delivery_info",
  contacts = "contacts",
  headerBackgroundImage = "header_background_image",
  menu = "menu"
}
